import React, { useState } from 'react';
import styles from '../Components.module.scss';
import Accordion from '../../../../../../components/molecules/accordion/Accordion';
import appConstants from '../../../../../../app/constant/constants/appConstants';
import _endpoints from '../../../../../../app/constant/endpoints/_endpoints';
import { errorMessage } from '../../../../../../components/hoc/toast/Toast';
import { useFormik } from 'formik';
import Container from '../../../../../../components/hoc/container/Container';
import SimpleTable from '../../../../../../components/molecules/simple-table/SimpleTable';
import TextArea from '../../../../../../components/atoms/forms/text-area/TextArea';
import Button from '../../../../../../components/atoms/forms/button/Button';
import SuccessModal from '../../../../../../components/hoc/success-modal/SuccessModals';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { usePostMutation } from '../../../../../../services/apiCall';
import objectToFormData from '../../../../../../app/helpers/forms/objectToFormData';
import DocumentView from '../../../../../../components/atoms/document-view/DocumentView';

const ProvostForm = ({ onSubmit, data, statusName }) => {
  const navigate = useNavigate();
  const [post] = usePostMutation();
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const [actionType, setActionType] = useState('');

  const request = data?.request;

  const formik = useFormik({
    initialValues: {
      comment: '',
    },
    validationSchema: Yup.object({
      comment: Yup.string().required('Required'),
    }),
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  const handleApprove = async () => {
    setIsApproving(true);
    setActionType('approve');

    const payload = {
      RequestId: data?.request.id,
      Comments: formik.values.comment,
    };

    const formData = objectToFormData(payload);

    try {
      await post({
        apiUrl: _endpoints.approver.approveRequest,
        data: formData,
        type: 'formData',
      }).unwrap();
      setIsSuccessModalOpen(true);
      setTimeout(() => {
        navigate(-1);
      }, 1000);
    } catch (error) {
      errorMessage('Something went wrong!');
    } finally {
      setIsApproving(false);
    }
  };

  return (
    <>
      <Container>
        <div className={styles['requests-container']}>
          <Container>
            <form onSubmit={formik.handleSubmit} className={styles.form}>
              {/* <TextArea
                label="Instructor Comment"
                placeholder={appConstants.PLACEHOLDER.ENTER_COMMENT}
                type={appConstants.TYPE.NOTE}
                name="comment"
                value={request?.comments}
                disabled
              /> */}
              <TextArea
                label={appConstants.LABEL.COMMENT}
                placeholder={appConstants.PLACEHOLDER.ENTER_COMMENT}
                type={appConstants.TYPE.NOTE}
                name="comment"
                value={formik.values.comment}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.comment && formik.errors.comment}
              />

              <Button
                label="Process Request"
                type={appConstants.TYPE.CHECK}
                variant={appConstants.VARIANT.DEFAULT}
                onClick={handleApprove}
                isLoading={isApproving}
              />

              <SuccessModal
                isOpen={isSuccessModalOpen}
                isSuccess
                text={'Submitted Successfully'}
                timeout={1000}
              />
            </form>
          </Container>
        </div>
      </Container>
    </>
  );
};
export default ProvostForm;
