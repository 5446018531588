import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import styles from './SettingsNavbar.module.scss';
import doubleFlesh from '../../../assets/icons/vectors/sidebar/doubleFlesh.svg';
import { getSvgByType } from '../../../app/helpers/forms/GetSvgByType';
import appConstants from '../../../app/constant/constants/appConstants';
import { routes } from '../../../app/constant/routes/Routes';
import Tooltip from '../../molecules/tootltip/Tooltip';

const staticTypes = [
  {
    id: 1,
    name: 'reasons',
    path: routes.REGISTRAR.SETTINGS.REASONS,
    type: appConstants.TYPE.REASON,
  },
  {
    id: 2,
    name: 'E-signature',
    path: routes.REGISTRAR.SETTINGS.ESIGNATURE,
    type: appConstants.TYPE.SIGN,
  },
  {
    id: 3,
    name: 'term & Conditions',
    path: routes.REGISTRAR.SETTINGS.TERMSCONDITIONS,
    type: appConstants.TYPE.BOARD,
  },
  {
    id: 4,
    name: 'students group',
    path: routes.REGISTRAR.SETTINGS.STUDENT_GROUP,
    type: appConstants.TYPE.STUDENT_GROUP,
  },
];

const SettingsNavbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={`${styles.navbarContainer} ${isOpen ? styles.open : ''}`}>
      <button
        className={`${styles.attributes} ${
          isOpen ? styles.open : styles.centered
        }`}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <Tooltip content={isOpen ? 'Close' : 'Open'}>
          <img
            src={doubleFlesh}
            alt="Toggle menu"
            className={`${isOpen ? styles.fleish : styles.fleishRotate}`}
          />
        </Tooltip>

        {isOpen && <span>Close</span>}
      </button>
      <ul className={styles.ulCont}>
        {staticTypes.map((item, index) => (
          <Tooltip key={index} content={item.name} placement="right">
            <NavLink
              key={index}
              to={`/settings/${item.path}`}
              className={({ isActive }) =>
                `${styles.listItem} ${isActive ? styles.activeListItem : ''} ${
                  index % 2 == 0 ? styles.even : ''
                }`
              }
            >
              <img
                src={getSvgByType(item.type)}
                alt={item.name}
                className={styles.icon}
              />
              <p className={`${isOpen ? styles.visible : styles.invisible}`}>
                {item.name}
              </p>
            </NavLink>
          </Tooltip>
        ))}
      </ul>
    </div>
  );
};

export default SettingsNavbar;
