import React from 'react';
import StudentProfileWrapper from '../../../../../../components/hoc/students/student-profile-wrapper/StudentProfileWrapper';
import CommonApplication from '../commonApplication/CommonApplication';
import styles from '../gradeChange/GradeChange.module.scss';
const RemovalOfI = () => {
  return (
    <StudentProfileWrapper title="Apply for Removal of I Grade">
      <div className={styles.application}>
        <CommonApplication />
      </div>
    </StudentProfileWrapper>
  );
};

export default RemovalOfI;
