import React from 'react';
import Container from '../../../../components/hoc/container/Container';
import _endpoints from '../../../../app/constant/endpoints/_endpoints';
import {
  TabBox,
  TabsBoxs,
} from '../../../../components/molecules/tab-box/TabBox';
import AllRequests_Details from './tabs/all-request-tab/AllRequests_Details';
import appConstants from '../../../../app/constant/constants/appConstants';
import AssignedToMe from './tabs/assigned-to-me/AssignedToMe';

const AllRequests = () => {
  return (
    <>
      <TabsBoxs>
        <TabBox label="Assigned to me" type={appConstants.TYPE.ATTENTION}>
          <AllRequests_Details
            api={_endpoints.request.getMyRequests}
            type={0}
          />
        </TabBox>
        <TabBox label="all request" type={appConstants.TYPE.EXCLAMATION_MARK}>
          <AllRequests_Details
            api={_endpoints.request.getRequestByType}
            type={1}
          />
        </TabBox>
      </TabsBoxs>
    </>
  );
};

export default AllRequests;
