import { format } from 'date-fns';

export default function formatDate(dateString) {
  const date = new Date(dateString);

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

export const formatDateTime = (dateString) => {
  try {
    const date = new Date(dateString);
    return format(date, 'MM/dd/yyyy hh:mm a');
  } catch (e) {
    return '';
  }
};
