import React, { useEffect, useState } from 'react';
import styles from '../PrivilageSelector.module.scss';
import { getSvgByType } from '../../../../../app/helpers/forms/GetSvgByType';
import appConstants from '../../../../../app/constant/constants/appConstants';
import { useGetQuery } from '../../../../../services/apiCall';
import SmallToggle from '../../small-toggle/SmallToggle';
import Tooltip from '../../../../molecules/tootltip/Tooltip';

const PrivilageSelector = ({ data: updatedData, api, onUpdate, isLoading }) => {
  const { data } = useGetQuery({ api });
  const [initialData, setInitialData] = useState([]);
  const [mainDropState, setMainDropState] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  useEffect(() => {
    if (data && updatedData) {
      const selectedIds = new Set(
        updatedData?.roleRequests?.map((item) => item.requestTypeId)
      );

      const updatedInitialData = data.map((item) => ({
        ...item,
        isSelected: selectedIds.has(item.id),
      }));

      setInitialData(updatedInitialData);
      setSelectedData(
        updatedInitialData
          .filter((item) => item.isSelected)
          .map((item) => ({ requestTypeId: item.id }))
      );
    } else setInitialData(data);
  }, [data, updatedData]);

  useEffect(() => {
    handleChange(selectedData);
  }, [selectedData]);

  const toggleOpen = () => {
    setMainDropState(!mainDropState);
  };

  const handleToggleChange = (id, isChecked) => {
    const item = initialData.find((item) => item.id === id);
    if (!item) return;

    let updatedData;
    if (isChecked) {
      updatedData = [
        ...selectedData,
        {
          requestTypeId: item.id,
        },
      ];
    } else {
      updatedData = selectedData.filter((data) => data.requestTypeId !== id);
    }

    setSelectedData(updatedData);
  };

  const handleChange = (data) => {
    onUpdate(data);
  };
  if (isLoading) return;

  return (
    <div>
      <Tooltip content="View More">
        <div className={styles['privilage-container']} onClick={toggleOpen}>
          <div className={styles['privilage']}>
            <p className={styles['label']}>Applications</p>
          </div>
          <img src={getSvgByType(appConstants.TYPE.DOWN_ARROW)} alt="Toggle" />
        </div>
      </Tooltip>

      {mainDropState &&
        initialData.map((application) => (
          <div key={application.id} className={styles['privilages']}>
            <SmallToggle
              label={application.applicationName}
              onChange={(event) =>
                handleToggleChange(application.id, event.target.value)
              }
              value={application.isSelected}
            />
          </div>
        ))}
    </div>
  );
};

export default PrivilageSelector;
