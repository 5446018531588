import React, { useState } from 'react';
import styles from './GradeAppealForm.module.scss';
import Button from '../../../../../../../atoms/forms/button/Button';
import TextArea from '../../../../../../../atoms/forms/text-area/TextArea';
import UploadFile from '../../../../../../../atoms/forms/upload-file/UploadFile';
import Container from '../../../../../../../hoc/container/Container';
import useGradeAppealForm from './hook';
import RadioGroup from '../../../../../../../atoms/forms/radio-group/RadioGroup';
import Select from '../../../../../../../atoms/forms/select/select/Select';
import appConstants from '../../../../../../../../app/constant/constants/appConstants';
import MultipleFileUpload from '../../../../../../../atoms/forms/upload-file/MultipleFileUpload';
import SuccessModal from '../../../../../../../hoc/success-modal/SuccessModals';

const options = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
];
const reasonsOptions = [
  { label: 'instructor is not available', value: 1 },
  { label: 'instructor left AUD', value: 2 },
  { label: 'Did bit ask for a meeting', value: 3 },
];
const GradeAppealForm = ({ selectedCourse, termId }) => {
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const onSuccess = () => {
    setIsSuccessModalOpen(true);
  };
  const { formik, isLoading } = useGradeAppealForm({
    selectedCourse,
    termId,
    onSuccess: onSuccess,
  });

  return (
    <div className={styles['course-wrapper']}>
      <Container>
        <form className={styles['course-actions']}>
          <div className={styles['reason-container']}>
            <RadioGroup
              label="Have you met with your instructor?"
              name="MetInstructor"
              selectedValue={formik.values.MetInstructor}
              onChange={(e) =>
                formik.setFieldValue('MetInstructor', e.target.value)
              }
              error={formik.errors.MetInstructor}
              options={options}
            />
            {formik.values.MetInstructor === 'no' ? (
              <div>
                <Select
                  label="Reason"
                  placeholder="Select Reason"
                  name="Reason"
                  type={appConstants.TYPE.FILE}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.Reason}
                  error={formik.errors.Reason}
                  touched={formik.touched.Reason}
                  options={reasonsOptions}
                />
              </div>
            ) : null}
          </div>
          {formik.values.MetInstructor === 'no' &&
          formik.values.Reason !== '' ? (
            formik.values.Reason === 1 ? (
              <p className={styles['danger']}>
                This grade appeal process should start by meeting with the
                instructor; however, the request will be forwarded to the
                relevant Chair.
              </p>
            ) : (
              <p className={styles['success']}>
                This process should start with your instructor. Please schedule
                a meeting with him/her.
              </p>
            )
          ) : null}
          {formik.values.MetInstructor === 'yes' ||
          (formik.values.MetInstructor === 'no' &&
            formik.values.Reason !== '') ? (
            <>
              <TextArea
                label="Comment"
                placeholder="Enter your comment here"
                name="Comments"
                value={formik.values.Comments}
                onChange={formik.handleChange}
                error={formik.errors.Comments}
                touched={formik.touched.Comments}
              />
              <MultipleFileUpload
                title="Attach Supporting Documents"
                subTitle="Supported: pdf, jpg, tiff, doc & docx Max size 45MB"
                name="SupportingDocuments"
                value={formik.values.SupportingDocuments}
                onChange={(e) => {
                  formik.setFieldValue('SupportingDocuments', e.target.value);
                }}
                onBlur={formik.handleBlur}
                error={formik.errors.SupportingDocuments}
                touched={formik.touched.SupportingDocuments}
                maxSize="45000000"
              />
            </>
          ) : null}

          <Button
            label="Submit"
            variant="danger"
            buttonType="Submit"
            onClick={formik.handleSubmit}
            disabled={isLoading}
            type={appConstants.TYPE.CHECK}
            isLoading={isLoading}
          />
        </form>
      </Container>
      <SuccessModal
        isOpen={isSuccessModalOpen}
        isSuccess
        text={'Submitted Successfully'}
        timeout={2000}
        subText="Your request needs to be verify by registrar"
      />
    </div>
  );
};

export default GradeAppealForm;
