import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styles from './TabBox.module.scss';
import { getSvgByType } from '../../../app/helpers/forms/GetSvgByType';

const TabsBoxs = ({
  children,
  useRouting = false,
  tabClassname,
  variant,
  innerClassname,
  defaultActiveTab = 0,
  onTabChange,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(defaultActiveTab);

  const childArray = React.Children.toArray(children);

  useEffect(() => {
    if (useRouting) {
      const activeIndex = childArray.findIndex(
        (tab) => tab.props.path === location.pathname
      );
      setIsActive(activeIndex >= 0 ? activeIndex : defaultActiveTab);
    }
  }, [location, useRouting, childArray, defaultActiveTab]);

  const handleTabClick = (index, path, id) => {
    setIsActive(index);
    if (onTabChange) {
      onTabChange(id);
    }
    if (useRouting && path) {
      navigate(path);
    }
  };

  return (
    <div className={styles.tabs}>
      <div
        className={`${styles['tab-buttons']} ${styles[variant] ?? ''} ${
          innerClassname ?? ''
        }`}
      >
        {childArray.map((tab, index) => {
          const svgPath = getSvgByType(tab.props.type);
          return (
            <div
              key={index}
              className={
                index === isActive
                  ? `${styles.tabHead} ${styles.active} ${tabClassname ?? ''}`
                  : `${styles.tabHead} ${tabClassname ?? ''}`
              }
              onClick={() =>
                handleTabClick(index, tab.props.path, tab.props.item?.id)
              }
            >
              {tab.props.type ? (
                <img
                  src={svgPath}
                  className={styles.svg}
                  alt={tab.props.type}
                />
              ) : null}
              {useRouting ? (
                <Link className={styles.link} to={tab.props.path}>
                  {tab.props.label}
                </Link>
              ) : (
                <p>{tab.props.label}</p>
              )}
              {tab.props.notificationNumber ? (
                <span className={styles.enum}>
                  {tab.props.notificationNumber}
                </span>
              ) : null}
            </div>
          );
        })}
      </div>
      {!useRouting && (
        <div
          className={`${styles['tab-content']} ${
            variant === 'container' ? styles.removePadding : ''
          }`}
        >
          {childArray.map((tab, index) =>
            index === isActive ? (
              <div key={index} className={`${styles.tab} ${styles.active}`}>
                {tab.props.children}
              </div>
            ) : null
          )}
        </div>
      )}
    </div>
  );
};

const TabBox = ({ children }) => {
  return <div>{children}</div>;
};

export { TabsBoxs, TabBox };
