import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useMemo, useState } from 'react';
import { usePostMutation } from '../../../../../../../services/apiCall';
import _endpoints from '../../../../../../../app/constant/endpoints/_endpoints';
import { handleError } from '../../../../../../../components/molecules/table/Table';
import { successMessage } from '../../../../../../../components/hoc/toast/Toast';

const apiUrl = _endpoints.term.termsAndConditions;

const validationSchema = Yup.object().shape({
  id: Yup.string().required('application is required'),
  termsAndConditions: Yup.string().required(
    'terms and conditions content is required'
  ),
});

const useForm = ({ onSuccess, data, type }) => {
  const [key, setKey] = useState(0);
  const [post, { isLoading, isSuccess }] = usePostMutation();
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    if (type === 'create') delete values.id;
    let payload = {
      apiUrl,
      data: values,
    };
    try {
      await post(payload).unwrap();
      resetForm();
      setKey((prev) => prev + 1);
    successMessage('Saved Successfully');
      if (onSuccess) onSuccess();
    } catch (error) {
      handleError();
    } finally {
      setSubmitting(false);
    }
  };

  const initialValues = useMemo(
    () => ({
      id: type === 'edit' ? data.id : null,
      termsAndConditions: data?.termsAndConditions || '',
    }),
    [type, data]
  );

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  return { isLoading, isSuccess, handleSubmit, formik, key };
};

export default useForm;
