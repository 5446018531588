import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useState } from 'react';
import { usePostMutation } from '../../../../../../../services/apiCall';
import _endpoints from '../../../../../../../app/constant/endpoints/_endpoints';
import { handleError } from '../../../../../../molecules/table/Table';
import { successMessage } from '../../../../../../hoc/toast/Toast';

const apiUrl = _endpoints.users.changeRole;

const validationSchema = Yup.object({
  userId: Yup.array()
    .of(Yup.string().required('User ID is required'))
    .min(1, 'At least one User ID is required'),
});

const initialValues = {
  userId: [],
};

const useForm = ({ onSuccess, data }) => {
  const [key, setKey] = useState(0);

  const [post, { isLoading, isSuccess }] = usePostMutation();

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    values.roleId = data.roleId;
    let payload = {
      apiUrl,
      data: values,
    };
    try {
      await post(payload).unwrap();
      resetForm();
      setKey((prev) => prev + 1);

    successMessage('Saved Successfully');
      onSuccess();
    } catch (error) {
      handleError(error);
    } finally {
      setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  return { isLoading, isSuccess, handleSubmit, formik, key };
};

export default useForm;
