import React, { useEffect, useState, useCallback, useMemo } from 'react';
import Input from '../../../../../../../components/atoms/forms/input/input/Input.jsx';
import Button from '../../../../../../../components/atoms/forms/button/Button.jsx';
import Select from '../../../../../../../components/atoms/forms/select/select/Select.jsx';
import useForm from './hook.js';
import appConstants from '../../../../../../../app/constant/constants/appConstants.js';
import _endpoints from '../../../../../../../app/constant/endpoints/_endpoints.js';
import { useGetQuery } from '../../../../../../../services/apiCall.jsx';
import { getSvgByType } from '../../../../../../../app/helpers/forms/GetSvgByType.js';
import Loader from '../../../../../../../components/molecules/table/atoms/loader/Loader.jsx';
import debounce from 'lodash.debounce';
import Title from '../../../../../../../components/atoms/title/Title.jsx';
import styles from './GradeChangeForm.module.scss';

const studentApi = _endpoints.instructor.getEnrolledStudentById;
const courseApi = _endpoints.instructor.getEnrolledCourses;
const termsApi = _endpoints.dropdown.getTerms;
const GradeChangeForm = ({ handleClose, onSuccess }) => {
  const [id, setId] = useState(null);
  const [data, setData] = useState(null);

  const { data: termsData, isLoading: termsIsLoading } = useGetQuery({
    api: termsApi,
  });

  const {
    data: studentData,
    isLoading: studentIsLoading,
    refetch,
  } = useGetQuery(
    {
      api: studentApi,
      params: [`/${id}`],
    },
    { skip: !id }
  );

  const { formik, isLoading } = useForm({
    onSuccess: onSuccess,
    data: data,
  });

  const { data: courseData, isLoading: courseIsLoading } = useGetQuery(
    {
      api: courseApi,
      params: [`?TermId=${formik.values.term}&StudentId=${studentData?.id}`],
    },
    { skip: !formik.values.term || !studentData?.id }
  );

  const actionImg = <img src={getSvgByType('enterIcon')} alt="" />;

  // Debounce function to update the id state
  const debouncedSetId = useCallback(
    debounce((newId) => {
      setId(newId);
    }, 700),
    []
  );

  // Update the debounced id when studentId changes
  useEffect(() => {
    if (formik.values.studentId) {
      debouncedSetId(formik.values.studentId);
    }
  }, [formik.values.studentId, debouncedSetId]);

  useEffect(() => {
    if (id) {
      refetch();
    }
  }, [id, refetch]);

  const courseOptions = courseData?.map((val) => ({
    value: val.courseId,
    label: val.course.courseCode,
  }));

  useEffect(() => {
    setData({
      student: studentData,
      termId: formik.values.term,
      course: courseData?.[0],
    });
  }, [studentData, formik.values.term, courseData]);

  return (
    <form autoComplete="off" className="form" onSubmit={formik.handleSubmit}>
      <Input
        label={appConstants.LABEL.STUDENT_ID}
        type={appConstants.PLACEHOLDER.ENTER_ID}
        placeholder="Enter student id"
        name="studentId"
        onChange={(e) => {
          formik.setFieldValue('studentId', e.target.value);
        }}
        onBlur={formik.handleBlur}
        value={formik.values.studentId}
        error={formik.touched.studentId && formik.errors.studentId}
        touched={formik.touched.studentId}
        autoComplete="off"
        actionImg={actionImg}
        inputType="number"
      />
      {id ? (
        studentIsLoading ? (
          <Loader style={{ width: '20px', height: '20px' }} isComponent />
        ) : (
          <div className={styles.box}>
            {studentData ? (
              <div className={styles.inner}>
                <Title variant="sm" text="Student Name :" />{' '}
                <Title
                  variant="sm-black"
                  text={studentData?.firstName + ' ' + studentData?.lastName}
                />
              </div>
            ) : (
              'No student found'
            )}
          </div>
        )
      ) : (
        ''
      )}

      <Select
        label={appConstants.LABEL.TERM}
        placeholder={appConstants.PLACEHOLDER.SELECT_TERM}
        type={appConstants.TYPE.DATE}
        name="term"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.term}
        error={formik.touched.term && formik.errors.term}
        touched={formik.touched.term}
        options={termsData}
        isLoading={termsIsLoading}
      />
      <Select
        label={appConstants.LABEL.COURSE_CODE}
        placeholder={appConstants.PLACEHOLDER.ENTER_NAME}
        type={appConstants.TYPE.FILE}
        name="courseCode"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.courseCode}
        error={formik.touched.courseCode && formik.errors.courseCode}
        touched={formik.touched.courseCode}
        isLoading={courseIsLoading}
        options={courseOptions}
      />
      <div className="form-footer">
        <Button
          onClick={handleClose}
          label={appConstants.CANCEL}
          buttonType="button"
        />
        <Button
          label="Apply"
          variant={appConstants.VARIANT.DANGER}
          type={appConstants.TYPE.CHECK}
          isLoading={isLoading}
          buttonType="submit"
        />
      </div>
    </form>
  );
};

export default GradeChangeForm;
