import React from 'react';
import StudentProfileWrapper from '../../../../../components/hoc/students/student-profile-wrapper/StudentProfileWrapper';
import AcademyStandingApealTemplate from '../../../../../components/templates/students/applications/academy-standing-apeal-template/AcademyStandingApealTemplate';
import appConstants from '../../../../../app/constant/constants/appConstants';

const AcademyStandingApeal = () => {
  return (
    <StudentProfileWrapper isStudent title="Academic Standing Appeal">
      <AcademyStandingApealTemplate />
    </StudentProfileWrapper>
  );
};

export default AcademyStandingApeal;
