import React, { useEffect, useMemo, useState } from 'react';
import Modal from '../../../../../../hoc/modal/Modal';
import styles from './RoleForm.module.scss';
import appConstants from '../../../../../../../app/constant/constants/appConstants';
import Select from '../../../../../../atoms/forms/select/select/Select';
import { useGetQuery } from '../../../../../../../services/apiCall';
import _endpoints from '../../../../../../../app/constant/endpoints/_endpoints';
import Button from '../../../../../../atoms/forms/button/Button';
import useForm from './hook';
import MultiSelectList from '../../../../../../atoms/forms/select/multi-select-list/MultiSelectList';
const usersApi = _endpoints.dropdown.getUser;

const RoleForm = ({
  handleClose,
  onClose,
  userId,
  onSuccess,
  data: rowData,
}) => {
  const { data, isLoading: rolesIsLoading } = useGetQuery({
    api: _endpoints.roles.getUnPagedRoles,
  });
  const { data: usersData, isLoading: usersIsLoading } = useGetQuery({
    api: usersApi,
  });
  const { formik, isLoading, isSuccess, key } = useForm({
    userId,
    onClose,
    data: rowData,
    onSuccess,
  });

  const handleFormSubmit = (event) => {
    event.preventDefault();
    formik.handleSubmit();
  };
  return (
    <form className="form" onSubmit={formik.handleSubmit}>
      <MultiSelectList
        label="users"
        placeholder="select User (s)"
        type={appConstants.TYPE.PROFILE}
        name="userId"
        options={usersData}
        onChange={(selected) => {
          formik.setFieldValue('userId', selected.target.value);
        }}
        value={formik.values.userId}
        error={formik.touched.userId && formik.errors.userId}
        touched={formik.touched.userId}
        isLoading={rolesIsLoading}
      />
      <div className="form-footer">
        <Button
          onClick={handleClose}
          label={appConstants.CANCEL}
          buttonType="button"
        />
        <Button
          onClick={handleFormSubmit}
          label="Save"
          variant={appConstants.VARIANT.DANGER}
          type={appConstants.TYPE.SAVE}
          isLoading={isLoading}
          buttonType="submit"
        />
      </div>
    </form>
  );
};

export default RoleForm;
