import React from 'react';
import styles from './ProfileTag.module.scss';
import { getSvgByType } from '../../../app/helpers/forms/GetSvgByType';

const ProfileTag = ({ type }) => {
  return (
    <div className={styles['profile-tag']}>
      <div className={styles.profile}>
        <img src={getSvgByType(type)} alt="" />
      </div>
      <div>
        <p className={styles.title}>John Do</p>
        <p className={styles.role}>President</p>
      </div>
    </div>
  );
};

export default ProfileTag;
