import React, { useEffect, useState } from 'react';
import styles from './ProgramVersions_TEMP.module.scss';
import Container from '../../../../hoc/container/Container';
import _endpoints from '../../../../../app/constant/endpoints/_endpoints';
import NewTable from '../../../../molecules/new-table/NewTable';
import useForm from './form/hook';
import ProgramVersionFrom from './form/ProgramVersionForm';
import Modal from '../../../../hoc/modal/Modal';

const ProgramVersions_TEMP = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [formType, setFormType] = useState('create');
  const [refetch, setRefetch] = useState(false);

  const onSuccess = () => {
    setIsModalOpen(false);
    setRefetch(!refetch);
  };

  const fetchRowData = (item, type) => {
    setIsModalOpen(true);
    setRowData(item);
    setFormType(type);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setFormType('create');
  };

  const { formik, isLoading } = useForm({
    data: rowData,
    type: formType,
    onSuccess: onSuccess,
  });

  useEffect(() => {
    formik.resetForm();
  }, [isModalOpen]);

  return (
    <div className={styles['approved-container']}>
      <Container>
        <NewTable
          headerTitle
          title="Program version"
          api={_endpoints.program.programVersion}
          actions={{
            hasEdit: {
              func: (item) => fetchRowData(item, 'edit'),
            },
          }}
          refetch={refetch}
          modalConfig={{
            buttonLabel: 'create program',
            modalTitle: formType === 'edit' ? 'edit' : 'create',
            classname: styles.modal,
          }}
        />
        <Modal
          title="edit program verision"
          onClose={() => setIsModalOpen(false)}
          isOpen={isModalOpen}
        >
          <ProgramVersionFrom
            formik={formik}
            data={rowData}
            type={formType}
            handleClose={handleCloseModal}
            isLoading={isLoading}
          />
        </Modal>
      </Container>
    </div>
  );
};

export default ProgramVersions_TEMP;
