import React from 'react';
import { Route, Routes } from 'react-router-dom';
// STUDENTS
import StudentsBodyLayout from '../../components/hoc/students/students-body-layout/StudentsBodyLayout';
import { routes } from '../../app/constant/routes/Routes';
import { commonRoutes } from '../common/CommonRoutes';
import PageNotFound from '../../features/authentication/page-not-found/PageNotFound';
import PendingRequests from '../../features/authenticated/students/pending-requests/PendingRequests';
import RequestHistory from '../../features/authenticated/students/request-history/RequestHistory';
import Reappeal from '../../features/authenticated/students/applications/reappeal/Reappeal';
import StudentsAllApplications from '../../features/authenticated/students/all-Applications/StudentsAllApplications';

const StudentRoutes = () => (
  <Routes>
    <Route element={<StudentsBodyLayout />}>
      <Route
        path={routes.STUDENTS.ALL_APPLICATIONS}
        element={<StudentsAllApplications />}
      />
      <Route
        path={routes.ALL_APPLICATIONS}
        element={<StudentsAllApplications />}
      />
      <Route
        path={routes.STUDENTS.PENDING_REQUESTS}
        element={<PendingRequests />}
      />
      <Route
        path={routes.STUDENTS.REQUEST_HISTORY}
        element={<RequestHistory />}
      />
      <Route
        path={`${routes.STUDENTS.APPEAL + routes.ID}`}
        element={<Reappeal />}
      />
      {commonRoutes}
    </Route>
    <Route path={routes.PAGE_NOT_FOUND} element={<PageNotFound />} />
  </Routes>
);

export default StudentRoutes;
