import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useState } from 'react';
import { usePostMutation } from '../../../../../../../services/apiCall';
import _endpoints from '../../../../../../../app/constant/endpoints/_endpoints';
import { handleError } from '../../../../../../molecules/table/Table';
import { successMessage } from '../../../../../../hoc/toast/Toast';

const apiUrl = _endpoints.users.changeRole;

const validationSchema = Yup.object({
  roleId: Yup.string().required('Approver Role is required'),
});

const initialValues = {
  userId: [],
  roleId: '',
};

const useForm = ({ onSuccess, data, userId }) => {
  const [key, setKey] = useState(0);

  const [post, { isLoading, isSuccess }] = usePostMutation();
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    values.userId = [userId.userId];
    let payload = {
      apiUrl,
      data: values,
    };

    try {
      await post(payload).unwrap();
      resetForm();
      setKey((prev) => prev + 1);

      successMessage('Saved Successfully');
      onSuccess();
    } catch (error) {
      handleError(error);
    } finally {
      setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  return { isLoading, isSuccess, handleSubmit, formik, key };
};

export default useForm;
