import React from 'react';
import CommonApplication from '../commonComponents/CommonApplication/CommonApplication';
import _endpoints from '../../../../../app/constant/endpoints/_endpoints';

const api = _endpoints.terms.getIncompleteTerms;
const CourseIncomplete_TEMP = () => {
  return <CommonApplication requestType="2" api={api} />;
};

export default CourseIncomplete_TEMP;
