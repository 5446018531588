import React, { useEffect, useState } from 'react';
import styles from './SmallCheckBox.module.scss';

const SmallCheckBox = ({ label, ischecked = false, onChange }) => {
  const [checked, setChecked] = useState(ischecked);

  const handleCheckboxChange = () => {
    const newChecked = !checked;
    setChecked(newChecked);
    if (onChange) {
      onChange(newChecked);
    }
  };
  useEffect(() => {
    setChecked(ischecked);
  }, [ischecked]);
  return (
    <label className={styles.checkbox}>
      <input
        type="checkbox"
        checked={checked}
        onChange={handleCheckboxChange}
        className={styles['checkbox-input']}
      />
      <span className={styles['checkbox-checkmark']}></span>
      {label && <span className={styles.label}>{label}</span>}
    </label>
  );
};

export default SmallCheckBox;
