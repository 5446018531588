export const routes = {
  BASE: '/',
  LOGIN: '/login',
  HOME: '/home',
  ABOUT: '/about',
  ID: '/:id',
  OFFLINE: '/offline',
  ALL_APPLICATIONS: '/all-applications',
  PROFILE: '/profile',
  STUDENTS: {
    ALL_REQUESTS: '/all-requests',
    ALL_APPLICATIONS: '/all-applications',
    REQUEST_HISTORY: '/request-history',
    COURSE_WITHDRAWAL: '/course-withdrawal',
    ACADEMY_STANDING_APPEAL: '/academy-standing-appeal',
    COURSE_INCOMPLETE: '/course-incomplete',
    COURSE_OVERLOAD: '/course-overload',
    WAITING_LIST: '/waiting-list',
    GRADE_APPEAL: '/grade-appeal',
    PENDING_REQUESTS: '/pending-requests',
    APPEAL: '/appeal',
  },
  REGISTRAR: {
    ALL_REQUESTS: '/all-requests',
    USERS: '/users',
    APPROVER_FLOW: '/approver-flow',
    SPECIAL_APPROVAL: '/special-approval',
    TERMS: '/terms',
    PROGRAM_VERSIONS: '/program-versions',
    COURSES: '/courses',
    REPLACE_INSTRUCTOR: '/replace-instructor',
    EMAIL: '/email',
    EMAIL_TEMPLATE_ID: '/email-template/:id',
    EMAIL_TEMPLATE: '/email-template',
    REQUEST_HISTORY: '/request-history',
    ALL_APPLICATIONS: '/all-applications',
    ALL_APPLICATIONS_FORM: {
      GRADE_CHANGE: '/grade-change',
      REMOVAL_OF_I_GRADE: '/removal-of-I-grade',
    },
    REQUEST_DETAILS: '/request-details/:id',
    RESTRICTIONS: '/Restrictions',
    RESTRICTIONS_DETAILS: '/restrictions-details/:id',
    SEND_EMAIL: '/send-email',
    DISABLE_REQUEST_USER: '/disable-user-request',
    SETTINGS: {
      SETTINGS: '/settings',
      REASONS: 'reasons',
      ESIGNATURE: 'E-signature',
      TERMSCONDITIONS: 'terms-conditions',
      STUDENT_GROUP: 'student-group',
    },
  },
  PAGE_NOT_FOUND: '*',
};
