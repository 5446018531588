import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Application.module.scss';
import Button from '../../../../../../atoms/forms/button/Button';
import TextArea from '../../../../../../atoms/forms/text-area/TextArea';
import Container from '../../../../../../hoc/container/Container';
import Table from '../../../../../../molecules/table/Table';
import SingleRowTable from '../../../../../../molecules/single-row-table/SingleRowTable';
import appConstants from '../../../../../../../app/constant/constants/appConstants';
import { routes } from '../../../../../../../app/constant/routes/Routes';
import {
  tableData,
  tableHeader,
} from '../../../../../../../app/constant/dummy-data/table';
import gloablStyle from '../../../../../../../globals.module.scss';

const Application = ({ title }) => {
  const navigate = useNavigate();
  const [selectedCourse, setSelectedCourse] = useState(null);

  const handleRowClick = (item) => setSelectedCourse(item);
  const handleBackClick = () => setSelectedCourse(null);
  const handleSubmitApplication = () =>
    navigate(routes.STUDENTS.ALL_APPLICATIONS);

  const renderCourseTable = () => (
    <Container>
      <div className={styles.application}>
        <Table
          title={appConstants.CHOOSE_COURSE}
          tableHeaderData={tableHeader}
          tableData={tableData}
          onRowClick={handleRowClick}
          hasCourseCode
          hasTerm
        />
      </div>
    </Container>
  );

  const renderCourseDetails = () => (
    <div className={styles['course-wrapper']}>
      <Container>
        <SingleRowTable
          title={appConstants.COURSE_SELECTION}
          tableHeaderData={tableHeader}
          tableData={selectedCourse}
          onBackClick={handleBackClick}
        />
      </Container>
      <Container>
        <div className={gloablStyle['course-actions']}>
          <TextArea
            label={`${appConstants.REASON_FOR} ${title}`}
            placeholder={appConstants.PLACEHOLDER.ENTER_REASON}
            type={appConstants.TYPE.NOTE}
          />
          <Button
            label={appConstants.LABEL.SUBMIT}
            variant={appConstants.VARIANT.DANGER}
            type={appConstants.TYPE.CHECK}
            onClick={handleSubmitApplication}
          />
        </div>
      </Container>
    </div>
  );

  return (
    <div className={styles['application-container']}>
      {selectedCourse ? renderCourseDetails() : renderCourseTable()}
    </div>
  );
};

export default Application;
