import React, { useEffect, useState } from 'react';
import styles from './AllRequests_Details.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import ApplicationSelector from '../../../../../../components/atoms/forms/applicationSelector/ApplicationSelector';
import Modal from '../../../../../../components/hoc/modal/Modal';
import NewTable from '../../../../../../components/molecules/new-table/NewTable';
import CancelRequestForm from '../../factory/cancelRequest/CancelRequestForm';
import ReverseRequestForm from '../../factory/reverseRequest/REverseRequestForm_';
import _endpoints from '../../../../../../app/constant/endpoints/_endpoints';
const defaultQuery = { pageSize: 10, pageNumber: 0 };

const AllRequests_Details = ({ api, type }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeApplication, setActiveApplication] = useState(false);
  const [query, setQuery] = useState(
    location.state?.query?.query || defaultQuery
  );
  const [activeModal, setActiveModal] = useState(null);
  const [rowData, setRowData] = useState({});
  const [refetch, setRefetch] = useState(false);

  const handleRowClick = (row) => {
    navigate(`/request-details/${row.id}`, {
      state: {
        query,
        activeApplication,
        type,
      },
    });
  };

  const handleOpenModal = (modalType, item) => {
    setActiveModal(modalType);
    setRowData(item);
  };

  const handleClose = () => {
    setActiveModal(null);
    setRowData({});
  };

  const onSuccess = () => {
    handleClose();
    setRefetch(!refetch);
  };

  useEffect(() => {
    if (location?.state?.query?.activeApplication?.id) {
      setActiveApplication({
        id: location.state.query.activeApplication.id,
        applicationName:
          location?.state?.query?.activeApplication?.applicationName,
      });
      return;
    } else if (!activeApplication) setActiveApplication({ id: -1 });
  }, []);

  const handleResetApplications = () => {
    setActiveApplication({ id: -1 });
  };

  const getQuery = (queryState) => {
    setQuery(queryState);
  };
  return (
    <div className={styles['all-requests']}>
      {activeApplication?.id === -1 ? null : (
        <ApplicationSelector
          title={activeApplication?.applicationName}
          onClose={handleResetApplications}
        />
      )}

      <NewTable
        key={query}
        api={api}
        filters={{
          hasSearch: true,
        }}
        getQuery={getQuery}
        actions={{
          hasCancel: {
            func: (item) => handleOpenModal('cancel', item),
            permissionKey: 'CANCEL_REQUEST',
          },
          hasReverse: {
            func: (item) => handleOpenModal('reverse', item),
            permissionKey: 'REVERT_REQUEST',
          },
        }}
        extraQuery={
          activeApplication
            ? {
                id: activeApplication?.id,
                query: 'RequestType',
              }
            : null
        }
        // refetch={refetch}
        initialQuery={query}
        refetch={refetch}
        onRowClick={handleRowClick}
        hasCategoriesSlide
        activeApplication={activeApplication}
        isRequest
        getApplication={(app) => {
          setQuery(defaultQuery);
          setActiveApplication(app);
        }}
      />

      <Modal
        isOpen={activeModal === 'cancel'}
        onClose={handleClose}
        title="Cancel Request"
      >
        <CancelRequestForm
          data={rowData}
          handleClose={handleClose}
          onSuccess={onSuccess}
        />
      </Modal>

      <Modal
        isOpen={activeModal === 'reverse'}
        onClose={handleClose}
        title="Reverse Request"
      >
        <ReverseRequestForm
          data={rowData}
          handleClose={handleClose}
          onSuccess={onSuccess}
        />
      </Modal>
    </div>
  );
};

export default AllRequests_Details;
