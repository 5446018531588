import applicationsContants from '../../../../app/constant/applications/applicationsContants';
import academicStandingAppealSvg from '../../../../assets/images/applications/academicStandingAppeal.png';
import booksSvg from '../../../../assets/images/applications/books.png';
import clockSvg from '../../../../assets/icons/applications/clock.svg';
import gradeApealSvg from '../../../../assets/images/applications/gradeApeal.png';
import gradePaperSvg from '../../../../assets/images/applications/gradePaper.png';
import hourglassSvg from '../../../../assets/images/applications/hourglass.png';
import paperSvg from '../../../../assets/images/applications/paper.png';
import gradeChangeI from '../../../../assets/images/applications/grade-change-i.png';
const getSvgByType = (type) => {
  let svg;
  switch (type) {
    case applicationsContants.academicStandingAppeal.type:
      svg = academicStandingAppealSvg;
      break;
    case applicationsContants.courseWithdrawal.type:
      svg = clockSvg;
      break;
    case applicationsContants.courseIncomplete.type:
      svg = paperSvg;
      break;
    case applicationsContants.gradeChange.type:
      svg = gradePaperSvg;
      break;
    case applicationsContants.gradeAppeal.type:
      svg = gradeApealSvg;
      break;
    case applicationsContants.courseOverload.type:
      svg = booksSvg;
      break;
    case applicationsContants.courseWaitlist.type:
      svg = hourglassSvg;
      break;
    case applicationsContants.removalOfIGrade.type:
      svg = gradeChangeI;
      break;
    default:
      svg = '';
      break;
  }
  return svg;
};

export { getSvgByType };
