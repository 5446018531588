import React from 'react';
import styles from './Paginator.module.scss';
import { getSvgByType } from '../../../app/helpers/forms/GetSvgByType';
import appConstants from '../../../app/constant/constants/appConstants';
import Tooltip from '../tootltip/Tooltip';

const Paginator = ({ currentPage, totalPages, onPageChange }) => {
  const handlePageClick = (page) => {
    if (page >= 0 && page < totalPages) {
      onPageChange(page);
    }
  };

  return (
    <div className={styles['paginator-container']}>
      <Tooltip content="Previous">
        <button
          className={`${styles['page-arrow']} ${
            currentPage === 0 ? styles['disabled'] : ''
          }`}
          disabled={currentPage === 0}
          onClick={() => handlePageClick(currentPage - 1)}
        >
          <img
            className={styles['arrow-left']}
            src={getSvgByType(appConstants.TYPE.RIGHT_LEFT_ARROW)}
            alt="Left Arrow"
          />
        </button>
      </Tooltip>

      <div className={styles.paginator}>
        <div
          className={`${styles['page-item']} ${
            currentPage === 0 ? styles['active'] : ''
          }`}
          onClick={() => handlePageClick(0)}
        >
          1
        </div>
        {currentPage > 2 && (
          <div className={styles.ellipsis}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        )}

        {Array.from({ length: 3 }, (_, i) => {
          const page = currentPage - 1 + i;
          if (page > 0 && page < totalPages - 1) {
            return (
              <div
                key={page}
                className={`${styles['page-item']} ${
                  page === currentPage ? styles['active'] : ''
                }`}
                onClick={() => handlePageClick(page)}
              >
                {page + 1}
              </div>
            );
          }
          return null;
        })}

        {currentPage < totalPages - 3 && (
          <div className={styles.ellipsis}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        )}

        {totalPages > 1 && (
          <div
            className={`${styles['page-item']} ${
              currentPage === totalPages - 1 ? styles['active'] : ''
            }`}
            onClick={() => handlePageClick(totalPages - 1)}
          >
            {totalPages}
          </div>
        )}
      </div>
      <Tooltip content="Next">
        <button
          className={`${styles['page-arrow']} ${
            currentPage === totalPages - 1 ? styles['disabled'] : ''
          }`}
          disabled={currentPage === totalPages - 1}
          onClick={() => handlePageClick(currentPage + 1)}
        >
          <img
            className={styles.arrows}
            src={getSvgByType(appConstants.TYPE.RIGHT_LEFT_ARROW)}
            alt="Right Arrow"
          />
        </button>
      </Tooltip>
    </div>
  );
};

export default Paginator;
