import React from 'react';
import Modal from '../../../../../hoc/modal/Modal';
import Select from '../../../../../atoms/forms/select/select/Select';
import appConstants from '../../../../../../app/constant/constants/appConstants';
import Button from '../../../../../atoms/forms/button/Button';
import useForm from './hook';

const AssignUserForm = ({ handleSubmit, handleClose }) => {
  const { formik, isLoading } = useForm({});
  return (
    <form autoComplete="off" className="form" onSubmit={formik.handleSubmit}>
      <Select
        label="User Name"
        placeholder="Select user"
        type={appConstants.TYPE.PROFILE}
        name="userId"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.userId}
        error={formik.touched.userId && formik.errors.userId}
        touched={formik.touched.userId}
      />
      <div className="form-footer">
        <Button
          onClick={handleClose}
          label={appConstants.CANCEL}
          buttonType="button"
        />
        <Button
          label="Save"
          variant={appConstants.VARIANT.DANGER}
          type={appConstants.TYPE.SAVE}
          isLoading={isLoading}
          buttonType="submit"
        />
      </div>
    </form>
  );
};

export default AssignUserForm;
