import React, { useState } from 'react';
import styles from './ApplicationTag.module.scss';
import { getSvgByType } from '../../molecules/applicationCard/factory/Factory';

const ApplicationTag = ({ data, isActive, onClick, variant }) => {
  return (
    <div
      className={`${styles['card-tag']} ${isActive ? styles['active'] : ''}`}
      onClick={onClick}
    >
      <div className={`${styles['image']} ${styles[variant] ?? ''}`}>
        <img src={getSvgByType(data.applicationImage)} alt="Icon" />
      </div>
      <p>{data.applicationName}</p>
    </div>
  );
};

export default ApplicationTag;
