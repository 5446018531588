import React, { useEffect, useState } from 'react';
import styles from './Components.module.scss';
import appConstants from '../../../../../app/constant/constants/appConstants';
import Accordion from '../../../../../components/molecules/accordion/Accordion';
import Container from '../../../../../components/hoc/container/Container';
import Tag from '../../../../../components/atoms/tag/Tag';
import SuccessModal from '../../../../../components/hoc/success-modal/SuccessModals';
import { getSvgByType } from '../../../../../app/helpers/forms/GetSvgByType';
import Title from '../../../../../components/atoms/title/Title';
import WIthdrawalFormsWrapper from './WithdrawalForms/WIthdrawalFormsWrapper';
import CourseIncompleteWrapper from './courseIncompleteForms/CourseIncompleteWrapper';
import SimpleTable from '../../../../../components/molecules/simple-table/SimpleTable';
import GradeAppealWrapper from './gradeAppealForms/GradeAppealWrapper';
import { format } from 'date-fns';
import GradeChangeWrapper from './gradeChangeForms/GradeChangeWrapper';
import RemovalOfIWrapper from './removalOfIGrade/RemovalOfIWrapper';
import TextArea from '../../../../../components/atoms/forms/text-area/TextArea';

// const AppealTable = ({ application }) => (
//   <div className={styles.table}>
//     <p className={styles.title}>{application?.applicationType}</p>
//     <p className={styles.text}>{application?.termName}</p>
//     <p className={styles.text}>
//       {new Date(application?.dateCreated).toLocaleDateString()}
//     </p>
//     <Tag
//       label={application.statusName}
//       variant={application.statusName.toLowerCase()}
//     />
//   </div>
// );

const formatDate = (dateString) => {
  try {
    const date = new Date(dateString);
    return format(date, 'MM/dd/yyyy hh:mm a');
  } catch (e) {
    return '';
  }
};

const WarningModal = ({ isOpen, onClose, onSubmit }) => (
  <SuccessModal isOpen={isOpen} onClose={onClose} />
);
const defaultTableHeaderData = [
  'Course Code',
  'Section',
  'Term',
  'Instructor',
  'Room',
  'Building',
];

const PendingRequestsTab = ({ data }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [tableData, setTableData] = useState([]);

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleRejectRequest = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    const newEntry = [
      data?.request?.course?.courseCode,
      data?.request?.section?.sectionName,
      data?.request?.termName,
      data?.request?.section?.instructorName,
      data?.request?.section?.room,
      data?.request?.section?.building,
    ];
    setTableData((prevData) => [...prevData, newEntry]);
  }, [data]);

  const FormWrapper = (type) => {
    switch (type) {
      case 3:
        return <WIthdrawalFormsWrapper data={data} />;
      case 2:
        return <CourseIncompleteWrapper data={data} />;
      case 5:
        return <GradeAppealWrapper data={data} />;
      case 6:
        return <GradeChangeWrapper data={data} />;
      case 7:
        return <RemovalOfIWrapper data={data} />;
      default:
        return null;
    }
  };

  return (
    <div className={styles.requests}>
      <div className={styles['requests-container']}>
        <Container>
          <div className={styles['requests-container']}>
            <SimpleTable
              tableHeaderData={defaultTableHeaderData}
              tableData={tableData}
            />
            <TextArea
              label="Student Comment"
              placeholder={appConstants.PLACEHOLDER.ENTER_COMMENT}
              type={appConstants.TYPE.NOTE}
              value={data?.request?.otherReasons || data?.request?.comments}
              disabled
            />
          </div>
        </Container>
        {FormWrapper(data?.request?.requestType)}
      </div>
      <div className={styles['requests-container']}>
        {data?.approvalRequest?.length > 0 ? (
          <Container>
            <div className={styles.logs}>
              <p className={styles['text']}>{appConstants.REQUEST_LOG}</p>
            </div>
          </Container>
        ) : null}

        {data?.approvalRequest?.map((details) => {
          return (
            <Accordion
              title={details.message}
              type={details.approved ? 'approved' : 'notApproved'}
              date={formatDate(details?.dateApproved)}
            >
              <div>
                <div className={styles.detailsCont}>
                  <img
                    src={getSvgByType(appConstants.TYPE.PROFILE_MALE)}
                    alt="profile"
                  />
                  <div className={styles.detailsSubCont}>
                    <Title text={details.role} />
                    {details?.notes && (
                      <Title text={details?.notes} variant="sm-blue" />
                    )}
                  </div>
                </div>
              </div>
            </Accordion>
          );
        })}
      </div>
      <WarningModal
        isOpen={isModalOpen}
        onClose={closeModal}
        onSubmit={handleRejectRequest}
      />
    </div>
  );
};

export default PendingRequestsTab;
