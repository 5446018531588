import React, { useEffect, useState } from 'react';
import styles from './AllApplications.module.scss';
import ApplicationCard from '../../../../components/molecules/applicationCard/ApplicationCard';
import routes from './factory/data';
import _endpoints from '../../../../app/constant/endpoints/_endpoints';
import Loader from '../../../../components/molecules/table/atoms/loader/Loader';
import applicationConfigurations from './factory/academic-standing-appeal/academicStandingAppeal';

import { useGetQuery } from '../../../../services/apiCall';
const api = _endpoints.request.getRequestTypes;

const StudentsAllApplications = () => {
  const { data, isLoading } = useGetQuery(
    { api },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const applicationsData = data?.map((application) => {
    let route;
    let onModalSubmitroute;
    let FormComponent;
    const config = applicationConfigurations?.find(
      (app) => app.id == application?.id
    );
    if (config) {
      onModalSubmitroute = config.onModalSubmitroute;
      FormComponent = config.FormComponent;
    } else {
      const routeConfig = routes[application.id];
      route = routeConfig?.route || undefined;
    }
    const shouldDisable =
      application.id !== 2 &&
      application.id !== 3 &&
      application.id !== 5 &&
      application.id !== 0;

    return {
      ...application,
      route,
      onModalSubmitroute,
      FormComponent,
      id: application.id,
      ...(shouldDisable && { disable: true }),
      isAllowedToApply: true,
    };
  });
  return (
    <div className={styles['all_applications_cont']}>
      <div className={styles['all-applications']}>
        {isLoading ? (
          <div className={styles['loader-container']}>
            <Loader />
          </div>
        ) : (
          applicationsData?.map((application, index) => (
            <ApplicationCard key={index} {...application} />
          ))
        )}
      </div>
    </div>
  );
};

export default StudentsAllApplications;
