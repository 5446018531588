import React from 'react';
import styles from './NewEsignatureForm.module.scss';
import Button from '../../../../../../../components/atoms/forms/button/Button';
import Input from '../../../../../../../components/atoms/forms/input/input/Input';
import appConstants from '../../../../../../../app/constant/constants/appConstants';
import TextEditor from '../../../../../../../components/atoms/forms/text-editor/TextEditor';

const NewEsignatureForm = ({ formik, onClose, isLoading }) => {
  const handleFormSubmit = (event) => {
    event.preventDefault();
    formik.handleSubmit();
  };

  return (
    <form
      className={styles['form']}
      autoComplete="off"
      onSubmit={handleFormSubmit}
    >
      <Input
        label="E-signature name"
        type={appConstants.TYPE.PROFILE}
        placeholder={appConstants.PLACEHOLDER.ENTER_NAME}
        name="signatureName"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.signatureName}
        error={formik.touched.signatureName && formik.errors.signatureName}
        touched={formik.touched.signatureName}
      />
      <TextEditor
        placeholder="Create E-signature"
        name="signature"
        onChange={(value) => formik.setFieldValue('signature', value)}
        onBlur={formik.handleBlur}
        value={formik.values.signature}
        error={formik.touched.signature && formik.errors.signature}
        touched={formik.touched.signature}
      />
      <div className="form-footer">
        <Button
          onClick={onClose}
          label={appConstants.CANCEL}
          buttonType="button"
        />
        <Button
          onClick={handleFormSubmit}
          label="Save"
          variant={appConstants.VARIANT.DANGER}
          type={appConstants.TYPE.CHECK}
          isLoading={isLoading}
          buttonType="button"
        />
      </div>
    </form>
  );
};

export default NewEsignatureForm;
