import React, { useState } from 'react';
import styles from './Reasons.module.scss';
import NewReasonForm from './factory/NewReasonForm';
import _endpoints from '../../../../../../app/constant/endpoints/_endpoints';
import Container from '../../../../../../components/hoc/container/Container';
import NewTable from '../../../../../../components/molecules/new-table/NewTable';
import Modal from '../../../../../../components/hoc/modal/Modal';

const Reasons = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [formType, setFormType] = useState('create');
  const [refetch, setRefetch] = useState(false);

  const onSuccess = () => {
    setIsModalOpen(false);
    setRefetch(!refetch);
  };

  const fetchRowData = (item, type) => {
    setRowData(item);
    setFormType(type);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setFormType('create');
    setRowData(null);
  };

  const handleModal = (type) => {
    setFormType(type);
    setIsModalOpen(!isModalOpen);
    setRowData(null);
  };

  return (
    <Container className={styles['reasons-container']}>
      <NewTable
        headerTitle
        title="reasons"
        form={
          <NewReasonForm
            data={rowData}
            type={formType}
            handleClose={handleCloseModal}
            onSuccess={onSuccess}
          />
        }
        api={_endpoints.reason.getReasonsTable}
        refetch={refetch}
        actions={{
          hasEdit: {
            func: (item) => {
              fetchRowData(item, 'edit');
            },
          },
          hasDelete: true,
          hasCreate: {},
        }}
        deleteApi={_endpoints.reason.deleteReason}
        isModalOpen={isModalOpen}
        setIsModalOpen={() => handleModal('create')}
        modalConfig={{
          buttonLabel: 'new reason',
          modalTitle: formType === 'edit' ? 'edit reason' : 'add reason',
        }}
      />
      <Modal
        title={formType === 'edit' ? 'Edit Reason' : 'Add Reason'}
        onClose={handleCloseModal}
        isOpen={isModalOpen}
      >
        <NewReasonForm
          data={rowData}
          type={formType}
          handleClose={handleCloseModal}
          onSuccess={onSuccess}
        />
      </Modal>
    </Container>
  );
};

export default Reasons;
