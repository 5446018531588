import React from 'react';
import loader_gif from './uniportal-loading.gif';
import styles from './Loader.module.scss';

const Loader = ({ backdrop, style, isComponent = false }) => {
  const containerClassName = backdrop
    ? styles.backdropContainer
    : styles.loaderContainer;
  return (
    <div className={containerClassName}>
      <div className={styles.loaderWrapper} style={style}>
        {isComponent ? (
          <div className={styles.loader}>
            <div style={style}></div>
            <div style={style}></div>
            <div style={style}></div>
          </div>
        ) : (
          <div className={styles.loader}>
            <img className={styles.gif} src={loader_gif} alt="laoder" />
          </div>
        )}

        {/* <p>Please wait ...</p> */}
      </div>
    </div>
  );
};

export default Loader;
