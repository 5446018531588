import React, { useState } from 'react';
import styles from './Courses_TEMP.module.scss';
import Container from '../../../../hoc/container/Container';
import _endpoints from '../../../../../app/constant/endpoints/_endpoints';
import NewTable from '../../../../molecules/new-table/NewTable';
import CoursesForm from './factory/new-user-form/CoursesForm';

const Courses_TEMP = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [refetch, setRefetch] = useState(false);

  const fetchRowData = (item) => {
    setRowData(item);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const onSuccess = () => {
    setIsModalOpen(false);
    setRefetch(!refetch);
  };

  const handleModal = () => {
    setIsModalOpen(!isModalOpen);
    setRowData(null);
  };

  return (
    <div className={styles['user-container']}>
      <Container>
        <NewTable
          filters={{
            hasSearch: true,
          }}
          form={
            <CoursesForm
              data={rowData}
              handleClose={handleCloseModal}
              onSuccess={onSuccess}
            />
          }
          api={_endpoints.courses.getCourses}
          actions={{
            hasEdit: {
              func: (item) => {
                fetchRowData(item);
              },
            },
            hasView: true,
            hasCreate: {},
          }}
          isModalOpen={isModalOpen}
          setIsModalOpen={handleModal}
          refetch={refetch}
          hasAdd={false}
          modalConfig={{
            modalTitle: 'Edit Course',
          }}
        />
      </Container>
    </div>
  );
};

export default Courses_TEMP;
