import React, { useState } from 'react';
import Container from '../../../../components/hoc/container/Container';
import NewTable from '../../../../components/molecules/new-table/NewTable';
import _endpoints from '../../../../app/constant/endpoints/_endpoints';
import PendingRequestPopup from './factory/PendingRequestPopup';
import Modal from '../../../../components/hoc/modal/Modal';

const PendingRequests = () => {
  const [rowData, setRowData] = useState({});
  const [isOpenModal, setIsOpenModal] = useState(false);

  const handleRowClick = (row) => {
    setIsOpenModal(true);
    setRowData(row);
  };

  const handleCloseModal = () => {
    setIsOpenModal(false);
    setRowData({});
  };
  return (
    <>
      <Container>
        {/* <Table tableHeaderData={tableHeader} tableData={tableData} /> */}
        <NewTable
          api={_endpoints.student.getPendingRequests}
          rowKey="id"
          onRowClick={handleRowClick}
          hasCount
          isAppeal={true}
        />
      </Container>
      <Modal
        onClose={() => setIsOpenModal(false)}
        isOpen={isOpenModal}
        title="Request Status"
        withoutLine
      >
        <PendingRequestPopup data={rowData} />
      </Modal>
    </>
  );
};

export default PendingRequests;
