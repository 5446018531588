import React from 'react';
import styles from './SingleRowTable.module.scss';
import backArrowSvg from '../../../assets/icons/vectors/backArrow.svg';

const SingleRowTable = ({ title, tableHeaderData, tableData, onBackClick }) => {
  return (
    <div className={styles['table-wrapper']}>
      <div className={styles['table-container']}>
        <div className={styles['back-btn']} onClick={onBackClick}>
          <img src={backArrowSvg} alt={title} />
          <p>{title}</p>
        </div>
        <table className={styles['table']}>
          <thead>
            <tr>
              {tableHeaderData?.map((header, index) => (
                <th key={index} className={styles['header-cell']}>
                  {header?.title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr className={styles['clickable-row']}>
              {tableHeaderData?.map((header, index) => (
                <td key={index}>{tableData[header?.key]}</td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SingleRowTable;
