import React, { useId } from 'react';
import styles from './Checkbox.module.scss';

const Checkbox = ({ label, ischecked, onChange }) => {
  const id = useId();

  const handleCheckboxChange = (e) => {
    if (onChange) {
      onChange(e.target.checked);
    }
  };

  return (
    <div className={styles.checkbox}>
      <label htmlFor={id} className={styles['checkbox-label']}>
        <input
          id={id}
          type="checkbox"
          checked={ischecked}
          onChange={handleCheckboxChange}
          className={styles['checkbox-input']}
        />
        <span className={styles['checkbox-square']} />
        <p className={styles.label}>{label}</p>
      </label>
    </div>
  );
};

export default Checkbox;
