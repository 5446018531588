import React, { useState } from 'react';
import styles from '../Components.module.scss';
import Accordion from '../../../../../../components/molecules/accordion/Accordion';
import appConstants from '../../../../../../app/constant/constants/appConstants';
import _endpoints from '../../../../../../app/constant/endpoints/_endpoints';
import { errorMessage } from '../../../../../../components/hoc/toast/Toast';
import { useFormik } from 'formik';
import Button from '../../../../../../components/atoms/forms/button/Button';
import TextArea from '../../../../../../components/atoms/forms/text-area/TextArea';
import SimpleTable from '../../../../../../components/molecules/simple-table/SimpleTable';
import * as Yup from 'yup';
import Container from '../../../../../../components/hoc/container/Container';
import SuccessModal from '../../../../../../components/hoc/success-modal/SuccessModals';
import { useNavigate } from 'react-router-dom';
import { usePostMutation } from '../../../../../../services/apiCall';
import objectToFormData from '../../../../../../app/helpers/forms/objectToFormData';
import Input from '../../../../../../components/atoms/forms/input/input/Input';
import Title from '../../../../../../components/atoms/title/Title';
import { getSvgByType } from '../../../../../../app/helpers/forms/GetSvgByType';

const InstructorForm = ({ onSubmit, data }) => {
  const navigate = useNavigate();
  const [post] = usePostMutation();
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const [actionType, setActionType] = useState('');

  const request = data?.request;

  const formik = useFormik({
    initialValues: {
      comment: '',
    },
    validationSchema: Yup.object({
      comment: Yup.string().required('Required'),
    }),
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  const handleApprove = async () => {
    setIsApproving(true);
    setActionType('approve');

    const payload = {
      RequestId: data?.request.id,
      Comments: formik.values.comment,
    };

    const formData = objectToFormData(payload);

    try {
      await post({
        apiUrl: _endpoints.approver.approveRequest,
        data: formData,
        type: 'formData',
      }).unwrap();
      setIsSuccessModalOpen(true);
      setTimeout(() => {
        navigate(-1);
      }, 1000);
    } catch (error) {
      errorMessage('Something went wrong!');
    } finally {
      setIsApproving(false);
    }
  };

  return (
    <Container>
      <form onSubmit={formik.handleSubmit} className={styles.form}>
        <div className={styles.decision}>
          <Input
            label="Enter New Numeric Grade"
            placeholder="Enter Grade"
            type={appConstants.TYPE.NOTE}
            name="comment"
            value={request?.otherReasons}
            disabled
          />
          <Title text="or" variant="sm-blue" />
          <Input
            label="Enter New Letter Grade"
            placeholder="Enter Grade"
            type={appConstants.TYPE.NOTE}
            name="comment"
            value={formik.values.comment}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.comment && formik.errors.comment}
          />
        </div>

        <TextArea
          label="Reason for Authorizing a grade change"
          placeholder={appConstants.PLACEHOLDER.ENTER_COMMENT}
          type={appConstants.TYPE.NOTE}
          name="comment"
          value={formik.values.comment}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.comment && formik.errors.comment}
        />
        <div className={styles['attention']}>
          <img src={getSvgByType('bell')} alt="" />
          <p>This request is beyond the time limit allowed for grade change.</p>
        </div>
        <div className={styles['button-container']}>
          <Button
            label="Submit"
            type={appConstants.TYPE.CHECK}
            variant={appConstants.VARIANT.DANGER}
            onClick={handleApprove}
            isLoading={isApproving}
          />
        </div>
        <SuccessModal
          isOpen={isSuccessModalOpen}
          isSuccess
          text="Submitted Successfully"
          timeout={1000}
        />
      </form>
    </Container>
  );
};

export default InstructorForm;
