const objectToFormData = (obj, formData = new FormData(), parentKey = '') => {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];
      const formKey = parentKey ? `${parentKey}[${key}]` : key;

      if (Array.isArray(value)) {
        value.forEach((item, index) => {
          const arrayKey = `${formKey}[${index}]`;
          if (item instanceof File) {
            formData.append(arrayKey, item);
          } else if (typeof item === 'object' && item !== null) {
            objectToFormData(item, formData, arrayKey);
          } else if (item !== '' && typeof item !== 'undefined') {
            formData.append(arrayKey, item);
          }
        });
      } else if (value instanceof File) {
        formData.append(formKey, value);
      } else if (typeof value === 'object' && value !== null) {
        objectToFormData(value, formData, formKey);
      } else if (value !== '' && typeof value !== 'undefined') {
        formData.append(formKey, value);
      }
    }
  }
  return formData;
};

export default objectToFormData;
