import React from 'react';
import StudentProfileWrapper from '../../../../../components/hoc/students/student-profile-wrapper/StudentProfileWrapper';
import appConstants from '../../../../../app/constant/constants/appConstants';
import GradeAppeal_TEMP from '../../../../../components/templates/students/applications/drade-appeal/GradeAppeal_TEMP';

const GradeApeal = () => {
  return (
    <StudentProfileWrapper title={appConstants.GRADE_APPEAL} isStudent>
      <GradeAppeal_TEMP filters={{ hasTerm: true }} />
    </StudentProfileWrapper>
  );
};

export default GradeApeal;
