import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useState } from 'react';
import { usePostMutation } from '../../../../../../../services/apiCall';
import _endpoints from '../../../../../../../app/constant/endpoints/_endpoints';
import { successMessage } from '../../../../../../hoc/toast/Toast';
import { handleError } from '../../../../../../molecules/table/Table';

const apiUrl = _endpoints.approver.post;

const validationSchema = Yup.object({
  seq: Yup.number(),
  roleId: Yup.string().required('Approver Role is required'),
  approvedMessage: Yup.string().required('Approved Request Log is required'),
  rejectedMessage: Yup.string().required('Rejected Request Log is required'),
  // onApproval_StudentTemplate: Yup.string().required(
  //   'Approval Student Template is required'
  // ),
  // onApproval_NextStepTemplate: Yup.string().required(
  //   'Approval Next Step Template is required'
  // ),
  // onRejection_StudentTemplate: Yup.string().required(
  //   'Rejection Student Template is required'
  // ),
  // onRejection_NotifyTemplate: Yup.string().required(
  //   'Rejection Notify Template is required'
  // ),
  activeStatus: Yup.number().required('status  is required'),
});

const useForm = ({ onSuccess, data, type, activeApplication, subflow }) => {
  const [key, setKey] = useState(0);
  const [post, { isLoading, isSuccess }] = usePostMutation();

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    if (type === 'create') delete values.id;
    if (subflow === '' || !subflow) delete values.subflow;
    values.RequestType = activeApplication.id;

    if (values.onApproval_StudentTemplate === '')
      delete values.onApproval_StudentTemplate;
    if (values.onApproval_NextStepTemplate === '')
      delete values.onApproval_NextStepTemplate;
    if (values.onRejection_StudentTemplate === '')
      delete values.onRejection_StudentTemplate;
    if (values.onRejection_NotifyTemplate === '')
      delete values.onRejection_NotifyTemplate;

    delete values.seq;
    try {
      await post({ apiUrl, data: values }).unwrap();
      resetForm();
      setKey((prev) => prev + 1);
      successMessage('Saved Successfully!');
      if (onSuccess) onSuccess();
    } catch (error) {
      handleError();
    } finally {
      setSubmitting(false);
    }
  };
  const formik = useFormik({
    initialValues: {
      subflow: subflow ?? '',
      id: type === 'edit' ? data?.id || '' : '',
      roleId: data?.roleId || '',
      seq: data?.seq || '',
      approvedMessage: data?.approvedMessage || '',
      rejectedMessage: data?.rejectedMessage || '',
      onApproval_StudentTemplate: data?.onApproval_StudentTemplate || '',
      onApproval_NextStepTemplate: data?.onApproval_NextStepTemplate || '',
      onRejection_StudentTemplate: data?.onRejection_StudentTemplate || '',
      onRejection_NotifyTemplate: data?.onRejection_NotifyTemplate || '',
      activeStatus: data?.activeStatus,
    },
    validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  return { isLoading, isSuccess, formik, key };
};

export default useForm;
