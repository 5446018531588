import React from 'react';
import noFoundSrc from './notFound.svg';
import styles from './PageNotFound.module.scss';
import Button from '../../../components/atoms/forms/button/Button';
import { useNavigate } from 'react-router-dom';

const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <div className={styles['page-container']}>
      <img src={noFoundSrc} alt="" />
      <Button onClick={() => navigate(-1)} label="Back Home" variant="danger" />
    </div>
  );
};

export default PageNotFound;
