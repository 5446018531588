import React from 'react';
import styles from './EducationItem.module.scss';
import getSvgByType from './factory/Factory';

const EducationItem = (props) => {
  const { title, text, type, isLabeled, isActive } = props;

  return (
    <div className={styles['edu-item']}>
      {type ? <img src={getSvgByType(type)} alt={type} /> : null}
      {title ? <p className={styles.title}>{title} </p> : null}
      {title ? <p className={styles.title}>: </p> : null}
      {isLabeled ? (
        <p className={`${isActive ? styles['active'] : styles['in-active']}`}>
          {text}
        </p>
      ) : null}
      {!isLabeled && <p className={styles.text}>{text}</p>}
    </div>
  );
};

export default EducationItem;
