import React from 'react';
import { Provider } from 'react-redux';
import { store, persistor } from './app/store';
import { PersistGate } from 'redux-persist/integration/react';
import MainRouter from './routes/MainRouter';
import { BrowserRouter } from 'react-router-dom';
import { ModalProvider } from './app/hooks/toast';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-toastify/dist/ReactToastify.css';
import './globals.module.scss';
import ToastContainer from './components/hoc/toast/Toast';

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <ModalProvider>
            <MainRouter />
          </ModalProvider>
        </BrowserRouter>
      </PersistGate>
      <ToastContainer />
    </Provider>
  );
};

export default App;
