import React from 'react';
import logo from '../../../../assets/icons/logo/audLogo.svg';
import globalStyle from '../../../../globals.module.scss';
import styles from './StudentsHeader.module.scss';
import RouteTab from './factory/Factory';
import ProfileDropdown from '../../../atoms/profile-dropdown/ProfileDropdown';
import appConstants from '../../../../app/constant/constants/appConstants';
import MobileHeader from '../../mobile/mobile-header/MobileHeader';
import studentsNavigation from '../../../../app/constant/navigations/students';
import { useSelector } from 'react-redux';

const StudentsHeader = () => {
  const user = useSelector((state) => state.user);
  return (
    <>
      <div className={styles['students-header']}>
        <div className={globalStyle['body-layout-width']}>
          <div className={styles['top-side']}>
            <div className={styles['left-side']}>
              <div className={styles['logo']}>
                <img
                  className={styles['aud-logo']}
                  src={logo}
                  alt={appConstants.AUD_LOGO}
                />
                {/* <div className={styles['searchCont']}>
                  <SearchInput placeholder={appConstants.SEARCH_APPLICATION} />
                </div> */}
                <p className={styles.title}>
                  {appConstants.WELCOME_TO_UNIPORTAL}
                </p>
              </div>
            </div>
        
              <ProfileDropdown role={user.roleName} name={user.fullName} />
         
          </div>
          <RouteTab />
        </div>
      </div>

      <MobileHeader data={studentsNavigation} isStudent />
    </>
  );
};

export default StudentsHeader;
