import React from 'react';
import styles from './UnAuthorized.module.scss';
import Button from '../../../components/atoms/forms/button/Button';
import { useNavigate } from 'react-router-dom';

const UnAuthorized = () => {
  const navigate = useNavigate();
  return (
    <div className={styles['un-auth-container']}>
      <div className={styles['auth']}>
        You are not authorized to enter this page
        <Button label="Previous page" onClick={() => navigate(-2)} />
      </div>
    </div>
  );
};

export default UnAuthorized;
