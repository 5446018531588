const tableHeader = [
  { title: 'Course Code', key: 'courseCode', isSorted: true },
  { title: 'Course Description', key: 'courseDescription', isSorted: true },
  { title: 'Section', key: 'section', isSorted: true },
  { title: 'Instructor', key: 'instructor' },
  { title: 'Status', key: 'status', isSorted: true },
];

const tableData = [
  {
    courseCode: 'COMP101',
    courseDescription: 'IT and Innovation in Today’s World',
    section: 'A',
    instructor: 'Hicham Nicolas',
    status: 'approved',
    isAppeal: true,
  },
  {
    courseCode: 'ENGL100',
    courseDescription: 'Developmental English',
    section: 'B',
    instructor: 'Sally Kondos',
    status: 'approved',
  },
  {
    courseCode: 'BUSI101',
    courseDescription: 'Introduction to Business',
    section: 'A',
    instructor: 'Lena Kurban',
    status: 'approved',
  },
  {
    courseCode: 'UNIV100',
    courseDescription: 'The University Experience',
    section: 'B',
    instructor: 'Nadine Ghalayini',
    status: 'rejected',
    isAppeal: true,
  },
  {
    courseCode: 'MATH095',
    courseDescription: 'Beginning of Intermediate Algebra',
    section: 'A',
    instructor: 'Randa Harake',
    status: 'pending',
  },
];

export { tableData, tableHeader };
