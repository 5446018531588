import React from 'react';
import Select from '../../../../atoms/forms/select/select/Select';
import styles from './FilterEmailForm.module.scss';
import _endpoints from '../../../../../app/constant/endpoints/_endpoints';
import { useGetQuery } from '../../../../../services/apiCall';
import appConstants from '../../../../../app/constant/constants/appConstants';
import Button from '../../../../atoms/forms/button/Button';
const termsApi = _endpoints.dropdown.getTerms;
const applicationsApi = _endpoints.request.getRequestTypes;
const FilterEmailForm = ({ setFilter, filter, onClose }) => {
  const { data: termsData, isLoading: termsIsLoading } = useGetQuery({
    api: termsApi,
  });
  const { data: applicationsData, isLoading: appLoading } = useGetQuery({
    api: applicationsApi,
  });

  const applicationOptions = applicationsData?.map((val) => ({
    value: val.id,
    label: val.applicationName,
  }));

  const handleChange = (name, value) => {
    setFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div className="form">
      <div className={styles['inner-section']}>
        <Select
          label="Term"
          placeholder="Select Term"
          value={filter.term}
          onChange={(e) => handleChange('term', e.target.value)}
          type={appConstants.TYPE.DATE}
          options={termsData}
          isLoading={termsIsLoading}
        />
        <Select
          label="Email Type"
          placeholder="Select Email Type"
          value={filter.type}
          onChange={(e) => handleChange('type', e.target.value)}
        />
      </div>
      <Select
        label="Application"
        placeholder="Select Application"
        value={filter.application}
        onChange={(e) => handleChange('application', e.target.value)}
        options={applicationOptions}
        isLoading={appLoading}
      />

      <div className="form-footer">
        <Button onClick={onClose} label={appConstants.CANCEL} />
        <Button
          onClick={() => setFilter((prev) => ({ ...prev, isFilter: false }))}
          label="Show Result"
          variant={appConstants.VARIANT.DANGER}
          type={appConstants.TYPE.CHECK}
          buttonType="button"
        />
      </div>
    </div>
  );
};

export default FilterEmailForm;
