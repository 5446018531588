import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { setUser } from '../../../../app/globals/storeSlices/userSlice';
import { useNavigate } from 'react-router-dom';
import { usePostMutation } from '../../../../services/apiCall';
import _endpoints from '../../../../app/constant/endpoints/_endpoints';
import {
  errorMessage,
  successMessage,
} from '../../../../components/hoc/toast/Toast';

const apiUrl = _endpoints.auth.login;

//note the navigation the the first element in the side bar is in the main route once we have a user data
const useForm = () => {
  const dispatch = useDispatch();
  const [post, { isLoading, isSuccess }] = usePostMutation();

  const handleSubmit = async (values, { setSubmitting }) => {
    let payload = {
      apiUrl,
      data: values,
    };

    try {
      const response = await post(payload).unwrap();
      const token = response.token;
      if (token) {
        localStorage.setItem('token', token);
      }
      dispatch(setUser({ ...response }));

      // successMessage('Login successful!');
    } catch (error) {
      errorMessage('Login failed. Please check your credentials.');
    } finally {
      setSubmitting(false);
    }
  };
  const emailRegex =
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(_[a-zA-Z0-9]+)?$/;

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .matches(emailRegex, 'Invalid email address')
        .required('Email is required'),
      password: Yup.string()
        .min(2, 'Password must be at least 8 characters')
        .required('Password is required'),
    }),
    onSubmit: handleSubmit,
  });

  return { formik, isLoading, isSuccess };
};

export default useForm;
