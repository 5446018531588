import React from 'react';
import styles from './PendingRequestsForm.module.scss';
import appConstants from '../../../../../../../app/constant/constants/appConstants';
import Container from '../../../../../../hoc/container/Container';
import RadioGroup from '../../../../../../atoms/forms/radio-group/RadioGroup';
import TextArea from '../../../../../../atoms/forms/text-area/TextArea';
import Button from '../../../../../../atoms/forms/button/Button';

import SimpleTable from '../../../../../../molecules/simple-table/SimpleTable';
import Accordion from '../../../../../../molecules/accordion/Accordion';
import ProfileTag from '../../../../../../atoms/profile-tag/ProfileTag';

const PendingRequestsForm = () => {
  const defaultTableHeaderData = [
    'Course Code',
    'Term',
    'Instructor',
    'Request Date',
    'Have you met the student?',
  ];
  const defaultTableData = [
    ['EECE350', 'Fall2023', 'Aicja Beya Far', '23/12/2023', 'Yes'],
  ];
  const courseGradeHeader = [
    'current grade (numeric)',
    'current grade (Letter)',
  ];
  const courseGradeData = [['58.25', 'F']];
  return (
    <div className={styles['requests-container']}>
      <div className={styles['forms-layout']}>
        <form className="form">
          <Container>
            <div className="form">
              <p className="title">{appConstants.APPEAL_EXPORT_FORM.TITLE}</p>
              <RadioGroup
                label={appConstants.APPEAL_RESOLVED}
                placeholder={appConstants.PLACEHOLDER.NAME}
              />
              <Button
                label={appConstants.LABEL.SUBMIT}
                placeholder={appConstants.PLACEHOLDER.NAME}
                type={appConstants.TYPE.CHECK}
                variant={appConstants.VARIANT.DANGER}
              />
            </div>
          </Container>
          <Container>
            <div className="form">
              <SimpleTable
                tableHeaderData={defaultTableHeaderData}
                tableData={defaultTableData}
              />
              <SimpleTable
                tableHeaderData={courseGradeHeader}
                tableData={courseGradeData}
                variant="red"
                className={styles['nth-table']}
              />
              <TextArea
                label={appConstants.LABEL.REASON_FOR_GRADE_APPEAL}
                placeholder={appConstants.PLACEHOLDER.REASON_FOR_GRADE_APPEAL}
                type={appConstants.TYPE.NOTE}
              />
            </div>
          </Container>
        </form>
      </div>
      <div>
        <Container>
          <p className={styles.text}>{appConstants.APPEAL_STATUS}</p>
        </Container>
        <div className={styles['accordions-container']}>
          <Accordion
            title="Accordion 1"
            content="This is the content of the first accordion."
          >
            <div className={styles['accordion-content']}>
              <ProfileTag type={appConstants.TYPE.PROFILE_MALE} />
              <p className={styles['title']}>Comments</p>
              <p className={styles['desc']}>
                Repeat MATH 200 in Summer 1 and earn minimum C as a last chance.
                If she achieves the required grade she will be allowed to
                register SOCI 201 in Summer II and earn minimum C to raise her
                CGPA to 2.0. She cannot appeal if she doesn’t achieve the C
                grade in MATH 200 Summer I.
              </p>
              <p className={styles['date']}>08/05/2024 at 8:39AM</p>
            </div>
          </Accordion>{' '}
          <Accordion
            title="Accordion 1"
            content="This is the content of the first accordion."
          >
            <div className={styles['accordion-content']}>
              <ProfileTag type={appConstants.TYPE.PROFILE_MALE} />
              <p className={styles['title']}>Comments</p>
              <p className={styles['desc']}>
                Repeat MATH 200 in Summer 1 and earn minimum C as a last chance.
                If she achieves the required grade she will be allowed to
                register SOCI 201 in Summer II and earn minimum C to raise her
                CGPA to 2.0. She cannot appeal if she doesn’t achieve the C
                grade in MATH 200 Summer I.
              </p>
              <p className={styles['date']}>08/05/2024 at 8:39AM</p>
            </div>
          </Accordion>
          <Accordion
            title="Accordion 1"
            content="This is the content of the first accordion."
          >
            <div className={styles['accordion-content']}>
              <ProfileTag type={appConstants.TYPE.PROFILE_FEMALE} />
              <p className={styles['title']}>Comments</p>
              <p className={styles['desc']}>
                Repeat MATH 200 in Summer 1 and earn minimum C as a last chance.
                If she achieves the required grade she will be allowed to
                register SOCI 201 in Summer II and earn minimum C to raise her
                CGPA to 2.0. She cannot appeal if she doesn’t achieve the C
                grade in MATH 200 Summer I.
              </p>
              <p className={styles['date']}>08/05/2024 at 8:39AM</p>
            </div>
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default PendingRequestsForm;
