import React from 'react';
import appConstants from '../../../../../app/constant/constants/appConstants';
import { Tab, Tabs } from '../../../../../components/molecules/tab/Tab';
import PendingRequestsForm from './components/pending-requests/PendingRequestsForm';

const Reappeal_TEMP = () => {
  return (
    <Tabs variant="container">
      <Tab
        label={appConstants.PENDING_REQUEST}
        type={appConstants.TYPE.EXCLAMATION_MARK}
      >
        <PendingRequestsForm />
      </Tab>
      <Tab
        label={appConstants.ATTACHMENTS}
        type={appConstants.TYPE.ATTACHMENTS_PAPER}
      />
    </Tabs>
  );
};

export default Reappeal_TEMP;
