import React, { useState, useEffect, useRef, useCallback } from 'react';
import styles from './ProfileDropdown.module.scss';
import arrowSvg from '../../../assets/icons/forms/arrow.svg';
import appConstants from '../../../app/constant/constants/appConstants';
import { getSvgByType } from '../../../app/helpers/forms/GetSvgByType';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { clearUser } from '../../../app/globals/storeSlices/userSlice';
import Tooltip from '../../molecules/tootltip/Tooltip';

const ProfileDropdown = ({ name = 'no name', role = 'no role' }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [animationClass, setAnimationClass] = useState('');
  const dropdownRef = useRef(null);
  const profileRef = useRef(null);

  const toggleDropdown = useCallback(() => {
    if (!isOpen) {
      setIsOpen(true);
      setAnimationClass('entering');
    } else {
      closeDropdown();
    }
  }, [isOpen]);

  const closeDropdown = useCallback(() => {
    setAnimationClass('exiting');
    setTimeout(() => {
      setIsOpen(false);
      setAnimationClass('');
    }, 300);
  }, []);

  const handleClickOutside = useCallback(
    (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !profileRef.current.contains(event.target)
      ) {
        closeDropdown();
      }
    },
    [closeDropdown]
  );

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, handleClickOutside]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        closeDropdown();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, closeDropdown]);
  const onLogout = () => {
    dispatch(clearUser());
    localStorage.clear();
  };
  const handleMoveToProfile = () => {
    navigate('/profile');
  };
  return (
    <>
      <Tooltip content="Profile / Logout" placement="left">
        <div
          className={styles['profile-dropdown']}
          ref={profileRef}
          onClick={toggleDropdown}
        >
          <div className={styles['profile-image-container']}>
            <img
              src={getSvgByType(appConstants.TYPE.PROFILE_MALE)}
              alt="Profile"
            />
          </div>
          <div
            className={styles['info']}
            aria-expanded={isOpen}
            aria-haspopup="menu"
          >
            <div>
              <p className={styles['name']}>
                {/* {name.length > 10 ? `${name.substring(0, 10)}...` : name} */}
                {name}
              </p>
              <p className={styles['type']}>{role}</p>
            </div>
            <img
              className={styles['arrow']}
              src={arrowSvg}
              alt="Toggle dropdown"
            />
          </div>

          {isOpen && (
            <div
              className={`${styles['dropdown-menu']} ${styles[animationClass]}`}
              ref={dropdownRef}
              style={{
                position: 'absolute',
                top: '100%',

                zIndex: 1000,
              }}
            >
              <button
                onClick={handleMoveToProfile}
                className={styles['button']}
                role="menuitem"
              >
                <img
                  src={getSvgByType(appConstants.TYPE.PROFILE)}
                  alt="logout"
                />
                My Profile
              </button>
              <button
                onClick={onLogout}
                className={styles['button']}
                role="menuitem"
              >
                <img
                  src={getSvgByType(appConstants.TYPE.SIGNOUT)}
                  alt="logout"
                />
                Logout
              </button>
            </div>
          )}
        </div>
      </Tooltip>
    </>
  );
};

export default ProfileDropdown;
