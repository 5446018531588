import React from 'react';
import { useSelector } from 'react-redux';
import RegistrarForm from './RegistrarForm';
import Loader from '../../../../../../components/molecules/table/atoms/loader/Loader';
import AppealForm from '../AppealForm';
import CommonForm from './CommonForm';
const WIthdrawalFormsWrapper = ({ data }) => {
  const { permissions } = useSelector((state) => state.user);

  if (!permissions) {
    return <Loader />;
  }
  const renderForm = () => {
    // Map of forms based on permission keys
    const formKeysMap = {
      RE_APPEAL: <AppealForm data={data} />,
      WITHDRAWAL_REGISTAR_VERIFICATION: (
        <RegistrarForm data={data} statusName="verification" />
      ),
      WITHDRAWAL_REGISTRAR_PROCESS: (
        <RegistrarForm data={data} statusName="process" />
      ),
      WITHDRAWAL_INSTRUCTOR_VERIFCIATION: <CommonForm data={data} />,
      WITHDRAWAL_PROVOST_VERIFICATION: (
        <CommonForm data={data} statusName="verification" />
      ),
      WITHDRAWAL_PROVOST_PROCESS: (
        <CommonForm data={data} statusName="process" />
      ),
      WITHDRAWAL_CHAIR_VERIFICATION: (
        <CommonForm data={data} statusName="verification" />
      ),
      WITHDRAWAL_CHAIR_PROCESS: <CommonForm data={data} statusName="process" />,
    };

    const permissionKeys =
      permissions?.permissions?.map((perm) => perm.actionTextKey) || [];

    const permission = Object.keys(formKeysMap).find((key) =>
      permissionKeys.includes(key)
    );
    if (data.canAct === 1 && permission) {
      return formKeysMap[permission];
    }

    return <p></p>;
  };

  return renderForm();
};

export default WIthdrawalFormsWrapper;
