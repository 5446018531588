import appConstants from '../constants/appConstants';
import { routes } from '../routes/Routes';

const adminLinks = [
  {
    id: 1,
    path: routes.REGISTRAR.ALL_REQUESTS,
    label: appConstants.LABEL.ALL_REQUESTS,
    type: appConstants.TYPE.EXCLAMATION_MARK,
    permissionKey: 'all-request',
  },
  {
    id: 2,
    path: routes.REGISTRAR.ALL_APPLICATIONS,
    label: 'all applications',
    type: appConstants.TYPE.ALL_APPLICATIONS,
    permissionKey: 'all_applications',
  },
  {
    id: 3,
    label: appConstants.LABEL.ADMIN_PANEL,
    type: appConstants.TYPE.ADMIN_PANEL,
    isLocal: true,
    permissionKey: 'admin_panel',
    childrens: [
      {
        id: 1,
        path: routes.REGISTRAR.USERS,
        label: 'users',
        type: appConstants.TYPE.USERS,
        permissionKey: 'users',
      },
      {
        id: 2,
        path: routes.REGISTRAR.SPECIAL_APPROVAL,
        label: appConstants.LABEL.SPECIAL_APPROVAL,
        type: appConstants.TYPE.USER_CHECK,
        permissionKey: 'special-approval',
      },
      {
        id: 3,
        path: routes.REGISTRAR.APPROVER_FLOW,
        label: appConstants.LABEL.APPROVER_FLOW,
        type: appConstants.TYPE.APPROVER_FLOW,
        permissionKey: 'approver_flow',
      },
      {
        id: 4,
        path: routes.REGISTRAR.TERMS,
        label: appConstants.LABEL.TERMS,
        type: appConstants.TYPE.TERMS,
        permissionKey: 'terms',
      },
      {
        id: 5,
        path: routes.REGISTRAR.PROGRAM_VERSIONS,
        label: appConstants.LABEL.PROGRAM_VERSIONS,
        type: appConstants.TYPE.PROGRAM_VERSIONS,
        permissionKey: 'program_versions',
      },
      {
        id: 6,
        path: routes.REGISTRAR.COURSES,
        label: 'courses',
        type: appConstants.TYPE.COURSES,
        permissionKey: 'courses',
      },
      {
        id: 11,
        path: routes.REGISTRAR.RESTRICTIONS,
        label: appConstants.LABEL.RESTRICTIONS,
        type: appConstants.TYPE.RESTRICTIONS,
        permissionKey: 'restrictions',
      },
      // {
      //   id: 7,
      //   path: routes.REGISTRAR.STUDENT_GROUP,
      //   label: appConstants.LABEL.STUDENT_GROUP,
      //   type: appConstants.TYPE.STUDENT_GROUP,
      //   permissionKey: 'student_group',
      // },
      // {
      //   id: 8,
      //   path: routes.REGISTRAR.REPLACE_INSTRUCTOR,
      //   label: appConstants.LABEL.REPLACE_INSTRUCTOR,
      //   type: appConstants.TYPE.REPLACE_INSTRUCTOR,
      //   permissionKey: 'replace_instructor',
      // },
      {
        id: 9,
        path: routes.REGISTRAR.EMAIL,
        label: appConstants.LABEL.TIME_EMAIL,
        type: appConstants.TYPE.TIME_EMAIL,
        permissionKey: 'email',
      },
      {
        id: 10,
        path: routes.REGISTRAR.SETTINGS.SETTINGS,
        label: appConstants.LABEL.SETTINGS,
        type: appConstants.TYPE.SETTINGS,
        permissionKey: 'settings',
      },
    ],
  },
  // {
  //   id: 4,
  //   path: routes.REGISTRAR.REQUEST_HISTORY,
  //   label: appConstants.LABEL.REQUEST_HISTORY,
  //   type: appConstants.TYPE.FOLDER,
  //   permissionKey: 'request_history',
  // },
  {
    id: 5,
    hidden: true,
  },
];

export default adminLinks;
