import React, { useEffect, useMemo, useState } from 'react';
import Input from '../../../../../../atoms/forms/input/input/Input';
import styles from './NewUserForm.module.scss';
import appConstants from '../../../../../../../app/constant/constants/appConstants';
import useForm from './hook';
import Button from '../../../../../../atoms/forms/button/Button';
import Select from '../../../../../../atoms/forms/select/select/Select';
import _endpoints from '../../../../../../../app/constant/endpoints/_endpoints';
import { useGetQuery } from '../../../../../../../services/apiCall';
import { getSvgByType } from '../../../../../../../app/helpers/forms/GetSvgByType';
const api = _endpoints.roles.getUnPagedRoles;

const NewUserForm = ({ data, type, handleClose, onSuccess }) => {
  const [isAdditionalInput, setAdditionalInput] = useState(false);
  const { data: roles } = useGetQuery({ api });
  const { formik, isLoading } = useForm({
    data: data,
    type: type,
    onSuccess: onSuccess,
  });

  // Generate roles options
  const roleOptions = useMemo(
    () =>
      roles?.map((val) => ({
        value: val.roleId,
        label: val.roleName,
      })),
    [roles]
  );

  const actionImg = (
    <img
      src={getSvgByType('enterIcon')}
      alt=""
      onClick={() => setAdditionalInput((prev) => !prev)}
    />
  );
  return (
    <form
      className={styles['form']}
      autoComplete="off"
      onSubmit={formik.handleSubmit}
    >
      <Input
        label="user id"
        type={appConstants.TYPE.PROFILE}
        placeholder="enter user id"
        name="otherId"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.otherId}
        error={formik.touched.otherId && formik.errors.otherId}
        touched={formik.touched.otherId}
        autoComplete="off"
        actionImg={actionImg}
      />
      <Input
        label={appConstants.LABEL.FIRST_NAME}
        placeholder={appConstants.PLACEHOLDER.FIRST_NAME}
        type={appConstants.TYPE.PROFILE}
        name="firstName"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.firstName}
        error={formik.touched.firstName && formik.errors.firstName}
        touched={formik.touched.firstName}
        // actionImg={actionImg}
      />
      <Input
        label={appConstants.LABEL.LAST_NAME}
        placeholder={appConstants.PLACEHOLDER.LAST_NAME}
        type={appConstants.TYPE.PROFILE}
        name="lastName"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.lastName}
        error={formik.touched.lastName && formik.errors.lastName}
        touched={formik.touched.lastName}
      />
      <Input
        label={appConstants.LABEL.EMAIL}
        type={appConstants.TYPE.EMAIL}
        placeholder={appConstants.PLACEHOLDER.EMAIL}
        name="email"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.email}
        error={formik.touched.email && formik.errors.email}
        touched={formik.touched.email}
        autoComplete="off"
      />
      {/* {isAdditionalInput && (
        <>
          <Input
            label="Department"
            type="file"
            placeholder="Enter Department"
            name="department"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.department}
            error={formik.touched.department && formik.errors.department}
            touched={formik.touched.department}
            autoComplete="off"
          />
          <Input
            label="Program"
            type="file"
            placeholder="Enter Program"
            name="program"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.program}
            error={formik.touched.program && formik.errors.program}
            touched={formik.touched.program}
            autoComplete="off"
          />
        </>
      )} */}
      <Select
        label="role"
        type={appConstants.TYPE.ROLE}
        placeholder="select role"
        name="roleId"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.roleId}
        error={formik.touched.roleId && formik.errors.roleId}
        touched={formik.touched.roleId}
        options={roleOptions}
      />
      {type === 'create' ? (
        <Input
          label={appConstants.LABEL.PASSWORD}
          type={appConstants.TYPE.PASSWORD}
          inputType="password"
          placeholder={appConstants.PLACEHOLDER.PASSWORD}
          name="password"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.password}
          error={formik.touched.password && formik.errors.password}
          touched={formik.touched.password}
          autoComplete="new-password"
        />
      ) : null}
      <div className="form-footer">
        <Button
          onClick={handleClose}
          label={appConstants.CANCEL}
          buttonType="button"
        />
        <Button
          // onClick={handleFormSubmit}
          label="Save"
          variant={appConstants.VARIANT.DANGER}
          type={appConstants.TYPE.CHECK}
          isLoading={isLoading}
          buttonType="submit"
        />
      </div>
    </form>
  );
};

export default NewUserForm;
