import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useMemo, useState } from 'react';
import { usePostMutation } from '../../../../../../../services/apiCall';
import _endpoints from '../../../../../../../app/constant/endpoints/_endpoints';
import { handleError } from '../../../../../../../components/molecules/new-table/NewTable';
import { successMessage } from '../../../../../../../components/hoc/toast/Toast';

const apiUrl = _endpoints.reason.postNewReason;

const getValidationSchema = () =>
  Yup.object().shape({
    reason: Yup.string().required('Reason Name is required'),
    requestType: Yup.string().required('Role is required'),
  });

const useForm = ({ data, type, onSuccess }) => {
  const [key, setKey] = useState(0);
  const [post, { isLoading, isSuccess }] = usePostMutation();
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    if (type === 'create') delete values?.id;

    const payload = {
      apiUrl,
      data: values,
    };

    try {
      await post(payload).unwrap();
      resetForm();
      setKey((prev) => prev + 1);
      onSuccess();
      successMessage('Reason saved successfully!');
      return { isSuccess: true };
    } catch (error) {
      handleError();
    } finally {
      setSubmitting(false);
    }
  };

  const initialValues = useMemo(
    () => ({
      id: type === 'edit' ? data?.id : null,
      reason: data?.reason || '',
      requestType: data?.requestTypeId || '',
    }),
    [type, data]
  );

  const formik = useFormik({
    initialValues,
    validationSchema: getValidationSchema(),
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  return { isLoading, isSuccess, formik, key };
};

export default useForm;
