import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './RestrictionsDetails.module.scss';
import Container from '../../../../../hoc/container/Container';
import appConstants from '../../../../../../app/constant/constants/appConstants';
import { getSvgByType } from '../../../../../../app/helpers/forms/GetSvgByType';
import { TabBox, TabsBoxs } from '../../../../../molecules/tab-box/TabBox';
import { useGetQuery } from '../../../../../../services/apiCall';
import _endpoints from '../../../../../../app/constant/endpoints/_endpoints';
import Restrictions from './tabs/restriction-detail/Restrictions';
import Loader from '../../../../../molecules/table/atoms/loader/Loader';

const BackHeader = ({ data }) => {
  const navigate = useNavigate();

  return (
    <Container>
      <div className={styles['back-header']}>
        <div className={styles.items} onClick={() => navigate(-1)}>
          <img
            className={styles.back}
            src={getSvgByType(appConstants.TYPE.BACKBUTTON)}
            alt="Back"
          />
          <h2>{data?.request?.restrictionName}</h2>
        </div>
        {/* <div className={styles.items}>
          <img src={getSvgByType(appConstants.TYPE.EDIT)} alt="Edit" />
          <img src={getSvgByType(appConstants.TYPE.DELETE)} alt="Delete" />
        </div> */}
      </div>
    </Container>
  );
};

const getRestrictionByid = _endpoints.restrictions.getRestrictionsById;

const RestrictionsDetails = () => {
  const { id } = useParams();
  const { data, isLoading, refetch } = useGetQuery({
    api: getRestrictionByid,
    params: [id],
  });

  const refetchData = () => {
    refetch();
  };

  return (
    <div className={styles['restrictions-details']}>
      {isLoading ? (
        <Loader backdrop />
      ) : (
        <>
          <BackHeader data={data} />
          <div className={styles['tabs-container']}>
            <TabsBoxs defaultActiveTab={0}>
              {data?.restrictionDetails?.map((val, index) => (
                <TabBox key={index} label={val?.restrictionType || 'Tab'}>
                  <Restrictions
                    items={val}
                    data={{
                      ...data,
                      items: val?.details || [],
                    }}
                    label={val?.restrictionType ?? ''}
                    isLoading={isLoading}
                    refetchData={refetchData}
                  />
                </TabBox>
              ))}
            </TabsBoxs>
          </div>
        </>
      )}
    </div>
  );
};

export default RestrictionsDetails;
