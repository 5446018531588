import React, { useEffect, useState } from 'react';
import styles from './New_EmailForm.module.scss';
import useNewEmailForm from './hook';
import Button from '../../../../atoms/forms/button/Button';
import Select from '../../../../atoms/forms/select/select/Select';
import appConstants from '../../../../../app/constant/constants/appConstants';
import Modal from '../../../../hoc/modal/Modal';
import { getSvgByType } from '../../../../../app/helpers/forms/GetSvgByType';
import TextEditor from '../../../../atoms/forms/text-editor/TextEditor';
import { useGetQuery } from '../../../../../services/apiCall';
import _endpoints from '../../../../../app/constant/endpoints/_endpoints';
import Input from '../../../../atoms/forms/input/input/Input';

const applicationsApi = _endpoints.request.getRequestTypes;
const usersApi = _endpoints.dropdown.getUser;
const emailTempApi = _endpoints.dropdown.getEmailTemplates;
const eSignatureAPi = _endpoints.settings.getSignature;
const termsApi = _endpoints.dropdown.getTerms;

const notificationApi = _endpoints.notification.getNotificationsById;

const Form = ({ handleClose, onSuccess }) => {
  const { formik, isLoading, isSuccess } = useNewEmailForm({
    onSuccess,
    handleClose,
  });
  const { data: applicationsData, isLoading: appLoading } = useGetQuery({
    api: applicationsApi,
  });

  const { data: usersData, isLoading: usersIsLoading } = useGetQuery({
    api: usersApi,
  });

  const { data: emailTempData, isLoading: emailTempIsloading } = useGetQuery({
    api: emailTempApi,
  });

  const { data: eSignatureData, isLoading: isEsignatureLoading } = useGetQuery({
    api: eSignatureAPi,
  });

  const { data: notifivationData, isLoading: notificationIsLoading } =
    useGetQuery(
      {
        api: notificationApi,
        params: [formik.values.emailTemplateId],
      },
      { skip: !formik.values.emailTemplateId }
    );
  const { data: termsData, isLoading: termsIsLoading } = useGetQuery({
    api: termsApi,
  });

  // generate application options
  const applicationOptions = applicationsData?.map((val) => ({
    value: val.id,
    label: val.applicationName,
  }));

  let textEditorContent =
    notifivationData?.length > 0 ? notifivationData[0].notificationContent : '';

  useEffect(() => {
    formik.setFieldValue('emailContent', textEditorContent);
  }, [notifivationData]);
  return (
    <form className={styles.form} autoComplete="off">
      <div className={styles['input-container']}>
        <Select
          label="To"
          name="userId"
          placeholder="Select recipient"
          type={appConstants.TYPE.PROFILE}
          options={usersData}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.userId}
          error={formik.touched.userId && formik.errors.userId}
          touched={formik.touched.userId}
          isLoading={usersIsLoading}
        />
        <Select
          label="Application"
          name="requestType"
          type={appConstants.TYPE.FILE}
          placeholder="Select application"
          options={applicationOptions}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.requestType}
          error={formik.touched.requestType && formik.errors.requestType}
          touched={formik.touched.requestType}
          isLoading={appLoading}
        />
      </div>
      <div className={styles['inputs-wrapper']}>
        <div className={styles['input-container']}>
          <Select
            label="Term"
            name="termId"
            placeholder="Select term"
            type={appConstants.TYPE.DATE}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.termId}
            error={formik.touched.termId && formik.errors.termId}
            touched={formik.touched.termId}
            options={termsData}
          />
          <Select
            label="Type"
            name="emailType"
            placeholder="Select type"
            type={appConstants.TYPE.EMAIL}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.emailType}
            error={formik.touched.emailType && formik.errors.emailType}
            touched={formik.touched.emailType}
          />
        </div>
        <Select
          label="Email Template"
          name="emailTemplateId"
          placeholder="Select template"
          type={appConstants.TYPE.EMAIL}
          options={emailTempData}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.emailTemplateId}
          error={
            formik.touched.emailTemplateId && formik.errors.emailTemplateId
          }
          touched={formik.touched.emailTemplateId}
          isLoading={emailTempIsloading}
        />
        <Input
          label="Subject"
          name="subject"
          type={appConstants.TYPE.SUBJECT}
          placeholder="Enter subject"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.subject}
          error={formik.touched.subject && formik.errors.subject}
          touched={formik.touched.subject}
        />
      </div>
      <TextEditor
        name="emailContent"
        placeholder="Enter email content"
        onChange={(value) => formik.setFieldValue('emailContent', value)}
        onBlur={formik.handleBlur}
        value={textEditorContent}
        error={formik.touched.emailContent && formik.errors.emailContent}
        touched={formik.touched.emailContent}
        isLoading={notificationIsLoading}
      />
      <Select
        label={appConstants.LABEL.EMAIL_SIGNATURE}
        name="emailSignature"
        type={appConstants.TYPE.SIGNITURE}
        placeholder={appConstants.PLACEHOLDER.SELECT_SIGNATURE}
        options={eSignatureData}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.emailSignature}
        error={formik.touched.emailSignature && formik.errors.emailSignature}
        touched={formik.touched.emailSignature}
        isLoading={isEsignatureLoading}
      />
      <div className="form-footer">
        <Button
          label={appConstants.CANCEL}
          buttonType="button"
          onClick={handleClose}
        />
        <Button
          label="Save"
          variant={appConstants.VARIANT.DANGER}
          type={appConstants.TYPE.CHECK}
          buttonType="button"
          onClick={() => formik.handleSubmit()}
          isLoading={isLoading}
        />
      </div>
    </form>
  );
};

const New_EmailForm = () => {
  const [isModalOpen, setIsModalOpen] = useState();
  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <div
        className={styles['round-button']}
        onClick={() => setIsModalOpen(true)}
      >
        <img src={getSvgByType(appConstants.TYPE.PLUS_SIGN)} />
      </div>
      <Modal
        title="new email"
        isOpen={isModalOpen}
        onClose={handleClose}
        classname={styles.modal}
      >
        <Form handleClose={() => setIsModalOpen(false)} />
      </Modal>
    </div>
  );
};

export default New_EmailForm;
