import React, { useId, useState } from 'react';
import styles from './TextArea.module.scss';
import Label from '../label/Label';
import { getSvgByType } from '../../../../app/helpers/forms/GetSvgByType';
import appConstants from '../../../../app/constant/constants/appConstants';

const TextArea = ({
  label,
  placeholder,
  name,
  value,
  onChange,
  onBlur,
  style,
  props,
  type,
  disabled,
  error,
  touched,
}) => {
  const id = useId();

  const [initialValue, setInitialValue] = useState(value);

  const handleChange = (event) => {
    const newValue = event.target.value;
    setInitialValue(newValue);
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <div className={`${styles['textarea-container']}`} style={style}>
      {label ? (
        <Label
          text={label}
          htmlFor={id}
          classname={disabled ? styles['label-disable'] : ''}
        />
      ) : null}
      <div
        className={`${styles['textarea-wrapper']} ${
          disabled ? styles.disabled : ''
        }`}
      >
        <div className={styles['text-svg']}>
          <img src={getSvgByType(type)} alt={appConstants.TYPE.TEXT_AREA} />
        </div>

        <textarea
          id={id}
          name={name}
          className={styles.textarea}
          placeholder={placeholder ?? appConstants.INPUT.DEFAULT_PLACEHOLDER}
          onChange={handleChange}
          onBlur={onBlur}
          value={initialValue}
          disabled={disabled}
          {...props}
        />
      </div>
      {error && touched && <p className={styles['error-message']}>{error}</p>}
    </div>
  );
};

export default TextArea;
