import React, { useEffect, useMemo, useState } from 'react';
import Modal from '../../../../../../hoc/modal/Modal';
import styles from './UserRoleForm.module.scss';
import appConstants from '../../../../../../../app/constant/constants/appConstants';
import Select from '../../../../../../atoms/forms/select/select/Select';
import { useGetQuery } from '../../../../../../../services/apiCall';
import _endpoints from '../../../../../../../app/constant/endpoints/_endpoints';
import Button from '../../../../../../atoms/forms/button/Button';
import useForm from './hook';
const api = _endpoints.roles.getUnPagedRoles;

const UserRoleForm = ({
  handleClose,
  onClose,
  userId,
  onSuccess,
  data: rowData,
}) => {
  const { data: roles } = useGetQuery({ api });

  const { formik, isLoading, isSuccess, key } = useForm({
    userId,
    onClose,
    data: rowData,
    onSuccess,
  });
  // Generate roles options
  const roleOptions = useMemo(
    () =>
      roles?.map((val) => ({
        value: val.roleId,
        label: val.roleName,
      })),
    [roles]
  );

  const handleFormSubmit = (event) => {
    event.preventDefault();
    formik.handleSubmit();
  };
  return (
    <form className="form" onSubmit={formik.handleSubmit}>
      <Select
        label="role"
        type={appConstants.TYPE.ROLE}
        placeholder="select role"
        name="roleId"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.roleId}
        error={formik.touched.roleId && formik.errors.roleId}
        touched={formik.touched.roleId}
        options={roleOptions}
      />
      <div className="form-footer">
        <Button onClick={handleClose} label={appConstants.CANCEL} />
        <Button
          onClick={handleFormSubmit}
          label="Save"
          variant={appConstants.VARIANT.DANGER}
          type={appConstants.TYPE.SAVE}
          isLoading={isLoading}
          buttonType="submit"
        />
      </div>
    </form>
  );
};

export default UserRoleForm;
