import React from 'react';
import { TabBox, TabsBoxs } from '../../../../../molecules/tab-box/TabBox';
import Loader from '../../../../../molecules/table/atoms/loader/Loader';

const ApprovalTab = ({
  getSubFlowData,
  isSubFlowLoading,
  onTabChange,
  children,
}) => {
  if (isSubFlowLoading) {
    return <Loader />;
  }

  return (
    <TabsBoxs
      defaultActiveTab={0}
      onTabChange={(id) => {
        onTabChange(id);
      }}
    >
      {getSubFlowData?.map((item) => (
        <TabBox key={item.id} label={item.flowName} item={item}>
          {children}
        </TabBox>
      ))}
    </TabsBoxs>
  );
};

export default ApprovalTab;
