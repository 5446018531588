import React from 'react';
import { TabBox, TabsBoxs } from '../../../../molecules/tab-box/TabBox';
import appConstants from '../../../../../app/constant/constants/appConstants';
import _endpoints from '../../../../../app/constant/endpoints/_endpoints';
import UsersTab from './components/userTab';
import RolesTab from './components/roleTab';

const Users_TEMP = () => {
  return (
    <TabsBoxs defaultActiveTab={0}>
      <TabBox label="users" type={appConstants.TYPE.USER_TAB}>
        <UsersTab />
      </TabBox>
      <TabBox
        label={appConstants.LABEL.ROLES}
        type={appConstants.TYPE.ROLE_TAB}
      >
        <RolesTab />
      </TabBox>
    </TabsBoxs>
  );
};

export default Users_TEMP;
