import React, { useEffect, useId, useState } from 'react';
import styles from './SmallToggle.module.scss';
import Label from '../label/Label';

const SmallToggle = ({
  label,
  required,
  name,
  value,
  onChange,
  onBlur,
  error,
  touched,
  style,
  hasInOut,
  ...props
}) => {
  const id = useId();
  const [toggleValue, setToggleValue] = useState(value);

  useEffect(() => {
    setToggleValue(value);
  }, [value]);

  const handleToggleChange = () => {
    const newValue = !toggleValue;
    setToggleValue(newValue);

    if (onChange) {
      onChange({
        target: {
          name,
          value: newValue,
        },
      });
    }
  };

  return (
    <div className={styles['toggle-container']} style={style}>
      <div
        className={`${styles['toggle-wrapper']} ${
          error && touched ? styles['toggle-error'] : ''
        }`}
      >
        <div
          className={`${styles.toggle} ${
            toggleValue ? styles['toggle-on'] : styles['toggle-off']
          }`}
          onClick={handleToggleChange}
          onBlur={onBlur}
          {...props}
        >
          <div className={styles['toggle-handle']}>
            {hasInOut ? toggleValue ? <span>in</span> : <span>out</span> : null}
          </div>
        </div>
      </div>
      {label && (
        <Label
          text={label}
          required={required}
          htmlFor={id}
          classname={styles.label}
        />
      )}
      {error && touched && (
        <div className={styles['error-message']}>{error}</div>
      )}
    </div>
  );
};

export default SmallToggle;
