import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useMemo, useState } from 'react';
import { successMessage } from '../../../../hoc/toast/Toast';
import _endpoints from '../../../../../app/constant/endpoints/_endpoints';
import { usePostMutation } from '../../../../../services/apiCall';
import { handleError } from '../../../../molecules/table/Table';

const apiUrl = _endpoints.email.postEmail;

const getValidationSchema = () =>
  Yup.object().shape({
    to: Yup.string().required('Recipient is required'),
    application: Yup.string().required('Application is required'),
    // term: Yup.string().required('Term is required'),
    emailTemplate: Yup.string().required('Email Template is required'),
    subject: Yup.string().required('Subject is required'),
  });

const useNewEmailForm = ({ onSuccess, handleClose }) => {
  const [key, setKey] = useState(0);
  const [post, { isLoading, isSuccess }] = usePostMutation();

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    let payload = {
      apiUrl,
      data: {
        userId: values.to,
        requestType: 0,
        termId: values.term,
        emailTemplateId: values.emailTemplate,
        subject: values.subject,
        emailContent: values.emailContent,
        emailType: values.emailType,
        emailSignature: values.EmailSignatureId,
      },
    };

    try {
      await post(payload).unwrap();
      resetForm();
      setKey((prev) => prev + 1);
      handleClose();
      successMessage('Email sent successfully!');
      if (onSuccess) onSuccess();
    } catch (error) {
      handleError(error);
    } finally {
      setSubmitting(false);
    }
  };

  const initialValues = useMemo(
    () => ({
      to: '',
      application: '',
      term: '',
      emailTemplate: '',
      subject: '',
      emailContent: '',
      emailType: '',
      EmailSignatureId: '',
    }),
    []
  );

  const formik = useFormik({
    initialValues,
    validationSchema: getValidationSchema(),
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  return { isLoading, isSuccess, formik, key };
};

export default useNewEmailForm;
