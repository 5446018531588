import React from 'react';
import Select from '../../../../atoms/forms/select/select/Select';
import Button from '../../../../atoms/forms/button/Button';
import appConstants from '../../../../../app/constant/constants/appConstants';
import { useGetQuery } from '../../../../../services/apiCall';
import useForm from './hook';
import styles from '../EmailReminder.module.scss';
import _endpoints from '../../../../../app/constant/endpoints/_endpoints';
import Input from '../../../../atoms/forms/input/input/Input';

const emailTempApi = _endpoints.dropdown.getEmailTemplates;
const termsApi = _endpoints.dropdown.getTerms;
const applicationsApi = _endpoints.request.getRequestTypes;

const EmailReminderForm = ({ data, handleClose, onSuccess }) => {
  const { data: applicationsData, isLoading: appLoading } = useGetQuery({
    api: applicationsApi,
  });
  const { data: emailTempData, isLoading: emailTempIsloading } = useGetQuery({
    api: emailTempApi,
  });
  const { data: termsData, isLoading: termsIsLoading } = useGetQuery({
    api: termsApi,
  });
  // generate application options
  const applicationOptions = applicationsData?.map((val) => ({
    value: val.id,
    label: val.applicationName,
  }));
  const { formik, isLoading, isSuccess } = useForm({
    onSuccess,
    handleClose,
    data
  });
  return (
    <div>
      <form className="form" autoComplete="off">
        <Select
          label="Application"
          name="requestType"
          type={appConstants.TYPE.FILE}
          placeholder="Select application"
          options={applicationOptions}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.requestType}
          error={formik.touched.requestType && formik.errors.requestType}
          touched={formik.touched.requestType}
          isLoading={appLoading}
        />

        <Select
          label="Term"
          name="termId"
          placeholder="Select term"
          type={appConstants.TYPE.DATE}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.termId}
          error={formik.touched.termId && formik.errors.termId}
          touched={formik.touched.termId}
          options={termsData}
        />

        <Select
          label="Email Template"
          name="emailTemplateId"
          placeholder="Select template"
          type={appConstants.TYPE.EMAIL}
          options={emailTempData}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.emailTemplateId}
          error={
            formik.touched.emailTemplateId && formik.errors.emailTemplateId
          }
          touched={formik.touched.emailTemplateId}
          isLoading={emailTempIsloading}
        />
        <Input
          label="Schedule"
          name="schedule"
          placeholder="Select Day"
          type={appConstants.TYPE.DATE}
          options={emailTempData}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.schedule}
          error={formik.touched.schedule && formik.errors.schedule}
          touched={formik.touched.schedule}
          inputType="number"
        />

        <div className="form-footer">
          <Button
            label={appConstants.CANCEL}
            buttonType="button"
            onClick={handleClose}
          />
          <Button
            label="Confirm"
            variant={appConstants.VARIANT.DANGER}
            type={appConstants.TYPE.CHECK}
            buttonType="button"
            onClick={() => formik.handleSubmit()}
            isLoading={isLoading}
          />
        </div>
      </form>
    </div>
  );
};

export default EmailReminderForm;
