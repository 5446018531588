import React from 'react';
import styles from './SimpleTable.module.scss';

const SimpleTable = ({
  title,
  tableHeaderData = [],
  tableData = [],
  variant = 'default',
  className,
}) => {
  const tableVariantClass = styles[variant] || styles['default'];

  return (
    <div className={styles['table-layout']}>
      <div className={styles['table-wrapper']}>
        <div className={`${styles['table-container']} ${tableVariantClass}`}>
          <table className={`${styles['table']} ${className ?? ''}`}>
            <thead>
              <tr>
                {tableHeaderData.map((header, index) => (
                  <th key={index}>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableData.length > 0 ? (
                <tr>
                  {tableData[0].map((data, index) => (
                    <td key={index}>{data}</td>
                  ))}
                </tr>
              ) : (
                <tr>
                  <td colSpan={tableHeaderData.length}>No data available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SimpleTable;
