import { useEffect, useCallback } from 'react';

const useFetchOnFocus = (fetchData, query, api) => {
  const handleVisibilityChange = useCallback(() => {
    if (document.visibilityState === 'visible') {
      if (api) fetchData(query);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [handleVisibilityChange]);
};

export default useFetchOnFocus;
