import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import StudentRoutes from '../../../routes/student/StudentsRoutes';
import RegistrarRoutes from '../../../routes/registrar/RegistrarRoutes';

const getRoutes = () => {
  const user = useSelector((state) => state.user);

  if (!user.isAuthenticated) {
    return <Route path="/*" element={<Navigate to="/login" />} />;
  }

  if (user.isAuthenticated && user.userType === 'STAFF') {
    return <Route path="/*" element={<RegistrarRoutes />} />;
  }

  if (user.isAuthenticated && user.userType === 'STUDENT') {
    return <Route path="/*" element={<StudentRoutes />} />;
  }

  return <Route path="/*" element={<Navigate to="/login" />} />;
};

export default getRoutes;
