import { useEffect, useCallback } from 'react';

const useFetchOnReconnect = (fetchData, query, api) => {
  const handleReconnect = useCallback(() => {
    if (navigator.onLine) {
      if (api) fetchData(query);
    }
  }, [fetchData, query]);

  useEffect(() => {
    window.addEventListener('online', handleReconnect);

    return () => {
      window.removeEventListener('online', handleReconnect);
    };
  }, [handleReconnect]);
};

export default useFetchOnReconnect;
