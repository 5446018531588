import React from 'react';
import Select from '../../../atoms/forms/select/select/Select';
import Button from '../../../atoms/forms/button/Button';
import appConstants from '../../../../app/constant/constants/appConstants';
import styles from './Factory.module.scss';
import SearchInput from '../../../atoms/forms/input/searchInput/SearchInput';
import { getSvgByType } from '../../../../app/helpers/forms/GetSvgByType';
import DropdownButton from '../../../atoms/dropdown-button/DropdownButton';
const portalItems = [
  {
    label: appConstants.LABEL.EXPORT,
    type: appConstants.TYPE.EXPORT,
  },
  {
    label: appConstants.LABEL.PRINT,
    type: appConstants.TYPE.PRINT,
  },
];
const TableFilters = ({
  filters = {
    hasCourseCode: false,
    hasTerm: false,
    hasSearch: true,
    hasFilter: false,
    hasColumnVisibility: false,
    hasExport: false,
  },
  HasSearchButton,
  rightContainer,
  handleFilterClick,
  isFiltering,
  visibleColumns,
  onColumnVisibilityChange,
  tableHeaderData,
  isLoading,
  handleSearchChange,
  query,
  hasData,
}) => {
  const {
    hasCourseCode,
    hasTerm,
    hasSearch,
    hasFilter,
    hasColumnVisibility,
    hasExport,
  } = filters;

  const handleSearch = (name, value) => {
    handleSearchChange({ ...query, searchBy: value });
  };

  const headerWidth =
    hasTerm && hasCourseCode ? styles['width-50vw'] : styles['width-20vw'];
  const filterClick = () => handleFilterClick();
  const columnVisibilityItems = tableHeaderData.map((header) => ({
    label: header.title,
    value: header.key,
    checked: visibleColumns.includes(header.key),
  }));

  return (
    <div className={styles.header}>
      <div className={`${styles.left} ${headerWidth}`}>
        {hasTerm && (
          <Select
            placeholder={appConstants.PLACEHOLDER.SELECT_TERM}
            label={appConstants.LABEL.TERM}
            type={appConstants.TYPE.DATE}
          />
        )}
        {hasCourseCode && (
          <Select
            placeholder={appConstants.PLACEHOLDER.ENTER_CODE}
            label={appConstants.LABEL.COURSE_CODE}
            type={appConstants.TYPE.DOCS}
          />
        )}
        {HasSearchButton && (
          <Button
            label={appConstants.LABEL.SEARCH}
            variant={appConstants.VARIANT.DANGER}
            type={appConstants.TYPE.SEARCH}
            color="white"
          />
        )}
        {hasSearch && (
          <SearchInput onChange={handleSearch} value={query?.searchBy} />
        )}
      </div>

      <div className={styles.right}>
        {hasColumnVisibility && (
          <DropdownButton
            items={columnVisibilityItems}
            type={appConstants.TYPE.COL_VISIBILITY}
            isMulty
            handleCheckboxChange={(key) => {
              onColumnVisibilityChange(key);
            }}
            disabled={isLoading}
            tooltipContent="Column Visibility"
          />
        )}
        {hasFilter && (
          <button
            onClick={filterClick}
            className={styles['filter-button']}
            disabled={isLoading}
          >
            {isFiltering ? (
              <img
                className={styles['filter-image']}
                src={getSvgByType(appConstants.TYPE.redX)}
                alt=""
              />
            ) : (
              <img
                className={styles['filter-image']}
                src={getSvgByType(appConstants.TYPE.FILTER)}
                alt=""
              />
            )}
          </button>
        )}
        {hasExport && (
          <DropdownButton
            items={portalItems}
            type={appConstants.TYPE.THREE_DOTS}
            disabled={isLoading}
            tooltipContent="More"
          />
        )}
        {rightContainer ? rightContainer : null}
      </div>
    </div>
  );
};

export { TableFilters };
