import React from 'react';
import styles from '../NewSetupTermForm.module.scss';
import DateSelect from '../../../../../../../atoms/forms/select/date/DateSelect';
import * as Yup from 'yup';

export const form2ValidationSchema = Yup.object().shape({
  makeUp_StartDate: Yup.date().required('Makeup start date is required'),
  makeUp_EndDate: Yup.date().required('Makeup end date is required'),
  incomplete_StartDate: Yup.date().required(
    'Incomplete start date is required'
  ),
  incomplete_EndDate: Yup.date().required('Incomplete end date is required'),
});

export const form2InitialValues = {
  makeUp_StartDate: '',
  makeUp_EndDate: '',
  incomplete_StartDate: '',
  incomplete_EndDate: '',
};
export const renderFieldsForId2 = (formik) => {
  const handleDateChange = (name, value) => {
    formik.setFieldValue(name, value);
  };
  return (
    <div className={styles['date-grid']}>
      <DateSelect
        label="Makeup start date"
        type="date"
        placeholder="Select date"
        name="makeUp_StartDate"
        onChange={handleDateChange}
        onBlur={formik.handleBlur}
        value={formik.values.makeUp_StartDate}
        error={
          formik.touched.makeUp_StartDate && formik.errors.makeUp_StartDate
        }
        touched={formik.touched.makeUp_StartDate}
        classname={styles.date}
      />
      <DateSelect
        label="Makeup end date"
        type="date"
        placeholder="Select date"
        name="makeUp_EndDate"
        onChange={handleDateChange}
        onBlur={formik.handleBlur}
        value={formik.values.makeUp_EndDate}
        error={formik.touched.makeUp_EndDate && formik.errors.makeUp_EndDate}
        touched={formik.touched.makeUp_EndDate}
        classname={styles.date}
      />
      <DateSelect
        label="Incomplete start date"
        type="date"
        placeholder="Select date"
        name="incomplete_StartDate"
        onChange={handleDateChange}
        onBlur={formik.handleBlur}
        value={formik.values.incomplete_StartDate}
        error={
          formik.touched.incomplete_StartDate &&
          formik.errors.incomplete_StartDate
        }
        touched={formik.touched.incomplete_StartDate}
        classname={styles.date}
      />
      <DateSelect
        label="Incomplete end date"
        type="date"
        placeholder="Select date"
        name="incomplete_EndDate"
        onChange={handleDateChange}
        onBlur={formik.handleBlur}
        value={formik.values.incomplete_EndDate}
        error={
          formik.touched.incomplete_EndDate && formik.errors.incomplete_EndDate
        }
        touched={formik.touched.incomplete_EndDate}
        classname={styles.date}
      />
    </div>
  );
};
