import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useState } from 'react';
import { usePostMutation } from '../../../../../../../services/apiCall';
import _endpoints from '../../../../../../../app/constant/endpoints/_endpoints';
import objectToFormData from '../../../../../../../app/helpers/forms/objectToFormData';
import { handleError } from '../../../../../../../components/molecules/table/Table';

import { useNavigate } from 'react-router-dom';
import { routes } from '../../../../../../../app/constant/routes/Routes';
import { successMessage } from '../../../../../../../components/hoc/toast/Toast';

const apiUrl = _endpoints.email.postNotifications;

const validationSchema = Yup.object({
  subject: Yup.string().required('Subject is required'),
  NotificationName: Yup.string().required('Notification name is required'),
  // NotificationType: Yup.number().required('Notification type is required'),
  NotificationContent: Yup.string().required(
    'Notification content is required'
  ),
  applicationType: Yup.string().required('email type signature is required'),
  Attachments: Yup.array().of(Yup.mixed()),
  EditAttachments: Yup.boolean().required(),
});

const useEmailForm = ({ data, hasEditedAttachments }) => {
  const navigate = useNavigate();
  const [key, setKey] = useState(0);

  let initial = data?.length > 0 ? data[0] : {};
  const initialAttachments = initial?.attachments || [];

  const [post, { isLoading, isSuccess }] = usePostMutation();

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    if (!data) delete values.NotificationId;
    // const formData = objectToFormData(values);

    const formData = new FormData();
    if (values.NotificationId)
      formData.append('NotificationId', values.NotificationId || '');
    formData.append('NotificationName', values.NotificationName || '');
    // formData.append('NotificationType', values.NotificationType || '');
    formData.append('NotificationContent', values.NotificationContent || '');
    formData.append('Subject', values.subject || '');
    formData.append('EmailSignatureId', values.EmailSignatureId || '');
    formData.append('EditAttachments', values.EditAttachments || '');

    // Append files
    values.Attachments.forEach((file) => {
      formData.append('Attachments', file);
    });
    if (!hasEditedAttachments) {
      delete values.Attachments;
      values.EditAttachments = false;
    }

    delete values.applicationType;
    let payload = {
      apiUrl,
      data: formData,
      type: 'formData',
    };

    try {
      await post(payload).unwrap();
      resetForm();
      setKey((prev) => prev + 1);
     successMessage('Saved Successfully');
      navigate(routes.REGISTRAR.EMAIL);
    } catch (error) {
      handleError();
    } finally {
      setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      NotificationId: initial?.notificationId || '',
      subject: initial?.subject || '',
      NotificationName: initial?.notificationName || '',
      // NotificationType: initial?.notificationType || null,
      applicationType: initial?.applicationType || 1,
      NotificationContent: initial?.notificationContent || '',
      EmailSignatureId: initial?.emailSignatureId || '',
      Attachments: initial?.attachments || [],
      EditAttachments: true,
    },
    validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  return { isLoading, isSuccess, formik, key, initialAttachments };
};

export default useEmailForm;
