import appConstants from '../constants/appConstants';
import { routes } from '../routes/Routes';

const studentsNavigation = [
  {
    pageName: appConstants.LABEL.ALL_APPLICATIONS,
    icon: appConstants.TYPE.ALL_APPLICATIONS,
    path: routes.STUDENTS.ALL_APPLICATIONS,
    notificationNumber: 2,
  },
  {
    pageName: appConstants.LABEL.PENDING_REQUESTS,
    icon: appConstants.TYPE.EXCLAMATION_MARK,
    path: routes.STUDENTS.PENDING_REQUESTS,
  },
  {
    pageName: appConstants.LABEL.REQUEST_HISTORY,
    icon: appConstants.TYPE.FOLDER,
    path: routes.STUDENTS.REQUEST_HISTORY,
  },
];

export default studentsNavigation;
