import React, { useState } from 'react';
import NewTable from '../../../../molecules/new-table/NewTable';
import NewUserForm from '../users/factory/new-user-form/NewUserForm';
import Container from '../../../../hoc/container/Container';
import AssignUserForm from './components/AssignUserForm';
import Modal from '../../../../hoc/modal/Modal';

const DisableUser_TEMP = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const data = {
    items: [
      {
        id: '0bfe3447-b86e-45ed-937a-3b7ea78a5197',
        applicationType: 'Academic Standing Appeal',
        requesterName: 'Mariam Al-Zahabi',
      },
      {
        id: '0bfe3447-b86e-45ed-937a-3b7ea78a5157',
        applicationType: 'Course Incomplete',
        requesterName: 'Mariam Al-Zahabi',
      },
    ],
    totalCount: 2,
    pageNumber: 0,
    pageSize: 10,
    tableView: [
      {
        id: 'd334fd8c-8f20-468f-98fe-aa0dd4390571',
        tableName: 'DisableUser',
        hasExport: false,
        hasColumnVisibility: false,
        hasSearch: true,
        hasFilter: false,
        deleteApi: null,
        cancelApi: null,
        hasPaging: true,
        key: null,
        fields: [
          {
            id: '58498f34-8ba5-48f8-b435-8589b5843166',
            tableDefinitionId: 'd334fd8c-8f20-468f-98fe-aa0dd4390571',
            fieldName: 'requesterName',
            headerName: 'Requester Name',
            hasSort: false,
            hasFilter: false,
            isDatetime: false,
            isColoredLabel: false,
            isBold: false,
            orderIndex: 0,
            isVisible: true,
            relatedType: null,
          },
          {
            id: '19f5c680-bf99-4a16-878c-56edcf13aed2',
            tableDefinitionId: 'd334fd8c-8f20-468f-98fe-aa0dd4390571',
            fieldName: 'applicationType',
            headerName: 'Application Type',
            hasSort: false,
            hasFilter: false,
            isDatetime: false,
            isColoredLabel: false,
            isBold: false,
            orderIndex: 1,
            isVisible: true,
            relatedType: null,
          },
        ],
      },
    ],
  };
  return (
    <>
      <Container>
        <NewTable
          actions={{ hasCheckbox: true }}
          data={data}
          modalConfig={{
            buttonLabel: 'Bulk Move To',
            modalTitle: 'Move Pending Request to',
          }}
          rowKey="id"
          form={
            <AssignUserForm
              isOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
            />
          }
          isModalOpen={isModalOpen}
          setIsModalOpen={() => setIsModalOpen((prev) => !prev)}
        />
      </Container>
    </>
  );
};

export default DisableUser_TEMP;
