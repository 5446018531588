import React from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import'./Tooltip.css';

const Tooltip = ({ content, children, red, placement }) => {
  const tooltipClass = red ? 'red-tooltip' : '';

  return (
    <Tippy
      content={content}
      className={tooltipClass}
      arrow={false}
      placement={placement}
    >
      <span>{children}</span>
    </Tippy>
  );
};

export default Tooltip;
