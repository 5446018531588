import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useState } from 'react';
import { usePostMutation } from '../../../../../../services/apiCall';
import { handleError } from '../../../../../molecules/new-table/NewTable';

// const apiUrl = _endpoints.users.changeRole;

const validationSchema = Yup.object({
  userId: Yup.string().required('userId is required'),
});

const initialValues = {
  userId: '',
};

const useForm = ({ onSuccess, data, userId }) => {
  const [key, setKey] = useState(0);

  const [post, { isLoading, isSuccess }] = usePostMutation();
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    // values.userId = [userId.userId];
    // let payload = {
    //   //   apiUrl,
    //   data: values,
    // };

    try {
      // await post(payload).unwrap();
      // resetForm();
      // setKey((prev) => prev + 1);

      // successMessage('user role updated !');
      onSuccess();
    } catch (error) {
      handleError(error);
    } finally {
      setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  return { isLoading, isSuccess, handleSubmit, formik, key };
};

export default useForm;
