import React from 'react';
import styles from './RequestHistoryPopup.module.scss';
import Tag from '../../../../../atoms/tag/Tag';
import Title from '../../../../../atoms/title/Title';
import Accordion from '../../../../../molecules/accordion/Accordion';
import { formatDateTime } from '../../../../../../app/helpers/forms/formatDate';
import appConstants from '../../../../../../app/constant/constants/appConstants';
import { getSvgByType } from '../../../../../../app/helpers/forms/GetSvgByType';
import _endpoints from '../../../../../../app/constant/endpoints/_endpoints';
import { useGetQuery } from '../../../../../../services/apiCall';
import Loader from '../../../../../molecules/table/atoms/loader/Loader';
const requestApi = _endpoints.student.getRequestById;

const RequestHistoryPopup = ({ data }) => {
  const { data: requestData, isLoading: requestLoading } = useGetQuery(
    {
      api: requestApi,
      params: [data?.id],
    },
    { skip: !data?.id }
  );
  if (requestLoading) {
    return <Loader />;
  }

  return (
    <div className={styles['request-cont']}>
      <div>
        <Tag label={data?.statusName} variant={data?.statusName} />
      </div>
      <div className={styles['request-cont']}>
        <Title variant="sm-bold-black" text="Request Log" />
        {requestData?.approvalRequest?.map((details) => {
          return (
            <Accordion
              title={details.message}
              type={details.approved ? 'approved' : 'notApproved'}
              className={styles['accordion_color']}
            >
              <div>
                <div className={styles.detailsCont}>
                  <img
                    src={getSvgByType(appConstants.TYPE.PROFILE_MALE)}
                    alt="profile"
                  />

                  <Title text={details.role} variant="sm-bold-black" />
                </div>
                <Title
                  text={formatDateTime(details?.dateApproved)}
                  variant="sm-blue"
                />
              </div>
            </Accordion>
          );
        })}
      </div>
    </div>
  );
};

export default RequestHistoryPopup;
