import React, { useEffect, useState } from 'react';
import styles from './Esignature.module.scss';
import Container from '../../../../../../components/hoc/container/Container';
import _endpoints from '../../../../../../app/constant/endpoints/_endpoints';
import NewEsignatureForm from './new-eSignature-form/NewEsignatureForm';
import useForm from './new-eSignature-form/hook';
import NewTable from '../../../../../../components/molecules/new-table/NewTable';

const Esignature = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [formType, setFormType] = useState('create');
  const [refetch, setRefetch] = useState(false);
  const onSuccess = () => {
    setIsModalOpen(false);
    setRefetch(!refetch);
  };

  const fetchRowData = (item, type) => {
    setRowData(item);
    setFormType(type);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setFormType('create');
    setRowData(null);
  };

  const handleModal = (type) => {
    setFormType(type);
    setIsModalOpen(!isModalOpen);
    setRowData(null);
  };
  const { formik, isLoading, isSuccess } = useForm({
    data: rowData,
    type: formType,
    onSuccess: onSuccess,
  });
  useEffect(() => {
    formik.resetForm();
  }, [isModalOpen]);
  return (
    <Container className={styles['Esignature-container']}>
      <NewTable
        headerTitle
        title="E-Signature"
        form={
          <NewEsignatureForm
            formik={formik}
            data={rowData}
            type={formType}
            onClose={handleCloseModal}
            isLoading={isLoading}
          />
        }
        deleteApi={_endpoints.settings.deleteSignature}
        api={_endpoints.settings.getSignature}
        actions={{
          hasEdit: {
            func: (item) => fetchRowData(item, 'edit'),
          },
          hasDelete: true,
          hasCreate: {},
        }}
        rowKey="emailSignatureId"
        refetch={refetch}
        isModalOpen={isModalOpen}
        setIsModalOpen={() => handleModal('create')}
        modalConfig={{
          buttonLabel: 'new e-signature',
          modalTitle: formType === 'edit' ? 'edit user' : 'add User',
          classname: styles.modal,
        }}
      />
    </Container>
  );
};

export default Esignature;
