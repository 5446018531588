import { routes } from '../../../../../app/constant/routes/Routes';
import ApplyForAcademicAppealFrom from './Forms/academicStandingAppeal/ApplyForAcademicAppealFrom';
import GradeChangeForm from './Forms/gradeChange/GradeChangeForm';
import RemovalOfIGRadeForm from './Forms/removalofI/RemovalOfIGRadeForm';

const applicationConfigurations = [
  {
    id: 6,
    onModalSubmitroute: routes.REGISTRAR.ALL_APPLICATIONS_FORM.GRADE_CHANGE,
    FormComponent: GradeChangeForm,
  },
  {
    id: 7,
    onModalSubmitroute: routes.REGISTRAR.ALL_APPLICATIONS_FORM.GRADE_CHANGE,
    FormComponent: GradeChangeForm,
  },
];
export default applicationConfigurations;
