import React, { useCallback, useEffect, useState } from 'react';
import styles from './TextEditor.module.scss';
import './styles.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import appConstants from '../../../../app/constant/constants/appConstants';
import Label from '../label/Label';
import { ToolBar } from './tool-bar/ToolBar';
import { modules, formats } from './tool-bar/ToolBar';
import debounce from 'lodash.debounce';

const TextEditor = ({
  label,
  placeholder,
  name,
  value,
  onChange,
  error,
  touched,
  isCustom,
  isLoading,
}) => {
  const [editorContent, setEditorContent] = useState(value || '');

  useEffect(() => {
    setEditorContent(value || '');
  }, [value]);

  const debouncedOnChange = useCallback(
    debounce((content) => {
      onChange(content);
    }, 300),
    [onChange]
  );

  const handleEditorChange = (content) => {
    setEditorContent(content);
    debouncedOnChange(content);
  };

  return (
    <div>
      <Label text={label} />
      <div className="textEditorCont">
        <ToolBar
          isCustom={isCustom}
          setEditorContent={setEditorContent}
          editorContent={editorContent}
        />
        <ReactQuill
          value={isLoading ? '...' : editorContent}
          onChange={handleEditorChange}
          modules={modules}
          formats={formats}
          placeholder={placeholder ?? appConstants.PLACEHOLDER.ENTER_CONTENT}
          className={styles['text-editor']}
        />
      </div>

      {error && touched && (
        <div className={styles['error-message']}>{error}</div>
      )}
    </div>
  );
};

export default TextEditor;
