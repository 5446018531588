import React, { useEffect, useState, useCallback } from 'react';
import { debounce } from 'lodash';
import styles from './Restrictions.module.scss';
import { getSvgByType } from '../../../../../../../../app/helpers/forms/GetSvgByType';
import appConstants from '../../../../../../../../app/constant/constants/appConstants';
import Toggle from '../../../../../../../atoms/forms/toggle/Toggle';
import RestrictionsDetailsForm from './restrictions-details-form/RestrictionsDetailsForm';
import NewTable from '../../../../../../../molecules/new-table/NewTable';
import _endpoints from '../../../../../../../../app/constant/endpoints/_endpoints';
import { usePostMutation } from '../../../../../../../../services/apiCall';
import {
  errorMessage,
  successMessage,
} from '../../../../../../../hoc/toast/Toast';

const postApi = _endpoints.restrictions.inOutRequest;

const Restrictions = ({ data, items, refetchData, isLoading, label }) => {
  const [post] = usePostMutation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formType, setFormType] = useState(null);
  const [rowData, setRowData] = useState(null);
  const [refetch, setRefetch] = useState(false);
  const [isAllowed, setIsAllowed] = useState(items?.in_Out ?? false);

  useEffect(() => {
    if (items?.in_Out) setIsAllowed(items?.in_Out);
  }, [items]);

  const handleModalOpen = (item = null, type = 'create') => {
    setRowData(item);
    setFormType(type);
    setIsModalOpen(true);
  };

  const onSuccess = () => {
    setRefetch(!refetch);
    setIsModalOpen(false);

    if (refetchData) {
      refetchData();
    }
  };

  const fetchRowData = (item, type) => {
    setRowData(item);
    setFormType(type);
  };

  const handleModal = (type) => {
    setFormType(type);
    setIsModalOpen(!isModalOpen);
    setRowData(null);
  };

  const debouncedHandleToggleChange = useCallback(
    debounce(async (toggleState) => {
      try {
        const result = await post({
          apiUrl: `${postApi}?DefinitionId=${items?.id}&inout=${toggleState}`,
        });

        if (result.error) {
          errorMessage('Error while updating restriction');
          refetchData();
        } else {
          successMessage('Restriction updated successfully');
          refetchData();
        }
      } catch (error) {
        refetchData();
        errorMessage('Error while updating restriction');
      }
    }, 500),
    [post, items?.id, refetchData]
  );

  const handleToggleChange = (toggleState) => {
    setIsAllowed(toggleState);
    debouncedHandleToggleChange(toggleState);
  };

  return (
    <div className={styles['restrictions']}>
      <div className={styles['attention-container']}>
        <Toggle
          key={items}
          hasInOut
          onToggleChange={handleToggleChange}
          value={isAllowed}
        />
        <div className={styles['attention']}>
          <img src={getSvgByType(appConstants.TYPE.ATTENTION)} alt="" />
          {isAllowed ? (
            <p>
              Allow Students only if their {label} meets the following
              conditions
            </p>
          ) : (
            <p>
              Restrict Students only if their {label} meets the following
              conditions
            </p>
          )}
        </div>
      </div>
      <NewTable
        data={data}
        filters={{ hasSearch: true }}
        form={
          <RestrictionsDetailsForm
            isModalOpen={isModalOpen}
            setIsModalOpen={handleModalOpen}
            handleClose={() => setIsModalOpen(false)}
            onSuccess={onSuccess}
            data={rowData}
            type={formType}
            items={items}
          />
        }
        isLoading={isLoading}
        isModalOpen={isModalOpen}
        refetch={refetch}
        setIsModalOpen={() => handleModal('create')}
        actions={{
          hasCreate: {},
          hasDelete: {},
        }}
        deleteApi={_endpoints.restrictions.deleteRestricationDetail}
        onDeleteSuccess={refetchData}
        modalConfig={{
          buttonLabel: 'add new',
          modalTitle:
            formType === 'edit'
              ? `${items?.restrictionType}`
              : `add ${items?.restrictionType}`,
        }}
      />
    </div>
  );
};

export default Restrictions;
