import React from 'react';
import Container from '../../../../hoc/container/Container';
import styles from './UserProfileDetails.module.scss';
import useForm from './hook';
import appConstants from '../../../../../app/constant/constants/appConstants';
import Input from '../../../../atoms/forms/input/input/Input';
const UserProfileDetails = () => {
  const { formik, isLoading } = useForm({
    // data: data,
    // type: type,
    // onSuccess: onSuccess,
  });
  return (
    <Container className={styles.formCont}>
      <form autoComplete="off" className="form">
        <Input
          label="First Name"
          placeholder="Enter First Name"
          type={appConstants.TYPE.PROFILE}
          name="firstName"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.firstName}
          error={formik.touched.firstName && formik.errors.firstName}
          touched={formik.touched.firstName}
        />
        <Input
          label="Last Name"
          placeholder="Enter Last Name"
          type={appConstants.TYPE.PROFILE}
          name="lastName"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.lastName}
          error={formik.touched.lastName && formik.errors.lastName}
          touched={formik.touched.lastName}
        />
        <Input
          label="Email"
          placeholder="Enter Email"
          type={appConstants.TYPE.EMAIL}
          name="email"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          error={formik.touched.email && formik.errors.email}
          touched={formik.touched.email}
        />{' '}
        <Input
          label="Mobile"
          placeholder="Enter Mobile"
          type={appConstants.TYPE.PROFILE}
          name="mobile"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.mobile}
          error={formik.touched.mobile && formik.errors.mobile}
          touched={formik.touched.mobile}
        />
      </form>
    </Container>
  );
};

export default UserProfileDetails;
