import React, { useEffect, useState } from 'react';
import _endpoints from '../../../../../app/constant/endpoints/_endpoints';
import NewApproverForm from './factory/new-approval-form/NewApproverForm';
import { useGetQuery } from '../../../../../services/apiCall';
import styles from './Approval_TEMP.module.scss';
import NewTable from '../../../../molecules/new-table/NewTable';
import Carousel from '../../../../molecules/carousel/Carousel';
import { useSelector } from 'react-redux';
import ApprovalTab from './components/ApprovalTab';
import { sub } from 'date-fns';
import Container from '../../../../hoc/container/Container';

const getApproverByIdApi = _endpoints.approver.getApproverById;
const getSubFlowApi = _endpoints.approver.getSubFlow;

const Approval_TEMP = () => {
  const requestTypes = useSelector((state) => state?.user?.permissions?.types);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rowData, setRowData] = useState(0);
  const [formType, setFormType] = useState('create');
  const [refetch, setRefetch] = useState(false);
  const [subflow, setSubFlow] = useState(null);
  const [activeApplication, setActiveApplication] = useState({
    id: requestTypes[0]?.id,
    applicationName: 'academic standing appeal',
  });
  const {
    data: getSubFlowData,
    isLoading: isSubFlowLoading,
    refetch: refetchSubFlows,
  } = useGetQuery({
    api: getSubFlowApi,
    params: [`?RequestTypeId=${activeApplication?.id}`],
  });

  useEffect(() => {
    if (getSubFlowData) setSubFlow(getSubFlowData[0]?.id);
  }, [getSubFlowData, activeApplication]);
  const { data: approvalData } = useGetQuery(
    { api: getApproverByIdApi, params: [`?Id=${rowData?.id}`] },
    { skip: !rowData?.id }
  );

  useEffect(() => {
    setActiveApplication({
      id: requestTypes[0]?.id ?? 0,
      applicationName:
        requestTypes[0]?.applicationName ?? 'academic standing appeal',
    });
  }, [requestTypes]);

  const onSuccess = () => {
    setIsModalOpen(false);
    setRefetch(!refetch);
  };

  const fetchRowData = (item, type) => {
    setRowData(item);
    setFormType(type);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setFormType('create');
  };

  const handleModal = (type) => {
    setFormType(type);
    setIsModalOpen(!isModalOpen);
    setRowData(null);
  };

  const handleSubFlowChange = (id) => {
    setSubFlow(id);
  };
  useEffect(() => {
    refetchSubFlows();
  }, [subflow]);

  return (
    <div className={styles['approved-container']}>
      <div className={styles['header']}>
        <Carousel
          key={requestTypes[0]?.id}
          data={requestTypes}
          activeApplication={activeApplication}
          setActiveApplication={setActiveApplication}
        />
      </div>
      {getSubFlowData?.length > 0 && !isSubFlowLoading ? (
        <ApprovalTab
          getSubFlowData={getSubFlowData}
          isSubFlowLoading={isSubFlowLoading}
          onTabChange={handleSubFlowChange}
        >
          <NewTable
            key={subflow}
            extraQuery={{
              id: activeApplication?.id,
              query: 'RequestType',
            }}
            isSubFLow={subflow}
            hasSub
            form={
              <NewApproverForm
                data={formType === 'edit' ? approvalData : null}
                type={formType}
                handleClose={handleCloseModal}
                onSuccess={onSuccess}
                subflow={subflow}
                activeApplication={activeApplication}
              />
            }
            api={_endpoints.approver.get}
            refetch={refetch || subflow}
            actions={{
              hasEdit: {
                func: (item) => fetchRowData(item, 'edit'),
              },
              hasDelete: true,
              hasCreate: {},
              hasSub: {
                label: 'select',
              },
            }}
            isModalOpen={isModalOpen}
            setIsModalOpen={() => handleModal('create')}
            modalConfig={{
              buttonLabel: 'new approver',
              modalTitle: formType === 'edit' ? 'edit step' : 'Add step',
              classname: styles.modal,
            }}
            deleteApi={_endpoints.approver.deleteApprovalFlow}
            deleteString
            classname={styles.table}
          />
        </ApprovalTab>
      ) : (
        <Container>
          <NewTable
            extraQuery={{
              id: activeApplication?.id,
              query: 'RequestType',
            }}
            form={
              <NewApproverForm
                data={formType === 'edit' ? approvalData : null}
                type={formType}
                handleClose={handleCloseModal}
                onSuccess={onSuccess}
                subflow={subflow}
                activeApplication={activeApplication}
              />
            }
            api={_endpoints.approver.get}
            refetch={refetch}
            actions={{
              hasEdit: {
                func: (item) => fetchRowData(item, 'edit'),
              },
              hasDelete: true,
              hasCreate: {},
              hasSub: {
                label: 'select',
              },
            }}
            isModalOpen={isModalOpen}
            setIsModalOpen={() => handleModal('create')}
            modalConfig={{
              buttonLabel: 'new approver',
              modalTitle: formType === 'edit' ? 'edit step' : 'Add step',
              classname: styles.modal,
            }}
            deleteApi={_endpoints.approver.deleteApprovalFlow}
            deleteString
            classname={styles.table}
          />
        </Container>
      )}
    </div>
  );
};

export default Approval_TEMP;
