import React, { useState } from 'react';
import appConstants from '../../../../app/constant/constants/appConstants';
import Button from '../../../atoms/forms/button/Button';
import Modal from '../../../hoc/modal/Modal';
import styles from './Factory.module.scss';
const TableModal = ({
  isLoading,
  form,
  isModalOpen,
  setIsModalOpen,
  modalConfig,
  hasAdd,
}) => {
  
  return (
    <div>
      {hasAdd ? (
        <Button
          variant={appConstants.VARIANT.DANGER}
          label={modalConfig?.buttonLabel}
          type={appConstants.TYPE.ADD}
          onClick={() => setIsModalOpen(true)}
        />
      ) : null}

      <Modal
        title={modalConfig?.modalTitle}
        onClose={() => setIsModalOpen(false)}
        isOpen={isModalOpen}
        isLoading={isLoading}
        classname={modalConfig?.classname ?? ''}
      >
        {form}
      </Modal>
    </div>
  );
};

export default TableModal;
