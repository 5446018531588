import { configureStore, combineReducers } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import { apiSlice, rtkQueryErrorLogger } from '../http/HttpRequest';
import userReducer from './globals/storeSlices/userSlice';
import pageTitleSlice from './globals/pageTitleSlice';

// Configure the Redux store persistence
const persistConfig = {
  key: 'root', // Key for the persisted state in storage
  storage, // Storage engine for persisting state
  whitelist: ['user', 'pageTitle'],
};

// Combine reducers, including the one generated by apiSlice and the user slice
const rootReducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  user: userReducer,
  pageTitle: pageTitleSlice,
});

// Create a persisted reducer based on the root reducer and persistConfig
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure the Redux store
export const store = configureStore({
  reducer: persistedReducer, // Use the persisted reducer
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat([
      apiSlice.middleware, // Add middleware for apiSlice (RTK Query)
      rtkQueryErrorLogger, // Add an error logger for RTK Query
    ]),
});

// Create a persistor for managing state persistence
export const persistor = persistStore(store);

// Setup an action listener for handling request enqueue
export const setupListeners = (dispatch) => {
  apiSlice.internalActions.enqueueRequest.subscribe(() => {
    dispatch(apiSlice.internalActions.updateQueryDataTimestamp());
  });
};
