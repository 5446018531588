import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useState } from 'react';
import { usePostMutation } from '../../../../../../../services/apiCall';
import { handleError } from '../../../../../../../components/molecules/table/Table';
import { useNavigate } from 'react-router-dom';

const validationSchema = Yup.object({
  studentId: Yup.string().required('StudentId is required'),
  courseCode: Yup.string().required('courseCode is required'),
  term: Yup.string().required('term is required'),
});

const initialValues = {
  studentId: '',
  courseCode: '',
  term: '',
};

const useForm = ({ onSuccess, data }) => {
  const [key, setKey] = useState(0);
  const [post, { isLoading, isSuccess }] = usePostMutation();


  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      onSuccess(data);
    } catch (error) {
      handleError(error);
    } finally {
      setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  return { isLoading, isSuccess, handleSubmit, formik, key };
};

export default useForm;
