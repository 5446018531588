import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SuccessModal from '../../../../../../components/hoc/success-modal/SuccessModals';
import Button from '../../../../../../components/atoms/forms/button/Button';
import TextArea from '../../../../../../components/atoms/forms/text-area/TextArea';
import Input from '../../../../../../components/atoms/forms/input/input/Input';
import styles from './CommonApplication.module.scss';
import appConstants from '../../../../../../app/constant/constants/appConstants';
import _endpoints from '../../../../../../app/constant/endpoints/_endpoints';
import { usePostMutation } from '../../../../../../services/apiCall';
import * as Yup from 'yup';
import Container from '../../../../../../components/hoc/container/Container';
import objectToFormData from '../../../../../../app/helpers/forms/objectToFormData';
import { errorMessage } from '../../../../../../components/hoc/toast/Toast';
import SimpleTable from '../../../../../../components/molecules/simple-table/SimpleTable';
const defaultTableHeaderData = [
  'Course Code',
  'Section',
  'Term',
  'Instructor',
  'room',
  'building',
];
const CommonApplication = ({ onSubmit, data }) => {
  const navigate = useNavigate();
  const [post] = usePostMutation();
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isApproving, setIsApproving] = useState(false);

  const formik = useFormik({
    initialValues: {
      comment: '',
    },
    validationSchema: Yup.object({
      comment: Yup.string().required('Required'),
    }),
    onSubmit: async (values) => {
      setIsApproving(true);
      const payload = {
        StudentId: data?.student?.id,
        Comments: values.values.comment,
        TermId: data?.termId,
        SectionId: data?.course?.section?.id,
        CourseId: data?.course?.courseId,
      };
      const formData = objectToFormData(payload);
      try {
        await post({
          apiUrl: _endpoints.instructor.postGradeChange,
          data: formData,
          type: 'formData',
        }).unwrap();
        setIsSuccessModalOpen(true);
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      } catch (error) {
        errorMessage('Something went wrong!');
      } finally {
        setIsApproving(false);
      }
    },
  });
  console.log('vddsnjkvnsd', data);

  const gradeData = [
    {
      title: 'Current Grade (Numeric)',
      value: '52',
    },
    {
      title: 'Current Grade (Letter)',
      value: 'F',
    },
  ];
  const defaultTableData = [
    [
      data?.course?.course?.courseCode,
      data?.course?.section?.sectionName,
      data?.course?.termName,
      data?.course?.section?.instructorName,
      data?.course?.section?.room,
      data?.course?.section?.building,
    ],
  ];
  return (
    <div className={styles.form}>
      <Container>
        <SimpleTable
          tableHeaderData={defaultTableHeaderData}
          tableData={defaultTableData}
        />
      </Container>

      <Container>
        {/* <div className={styles.gradeCont}>
        {gradeData?.map((i) => {
          return (
            <div className={styles.gradeSubCont}>
              <h4> {i.title}</h4>
              <p>{i.value}</p>
            </div>
          );
        })}
      </div> */}
        <form onSubmit={formik.handleSubmit} className={styles.form}>
          <div className={styles.decision}>
            <Input
              label="Enter New Numeric Grade"
              placeholder="Enter Grade"
              type={'hashtag'}
              value={data?.request?.otherReasons}
              disabled
            />

            <p>OR</p>
            <Input
              label="Enter New Letter Grade"
              placeholder="Enter Grade"
              type={'hashtag'}
            />
          </div>

          <TextArea
            label="Reason for Authorizing a grade change"
            placeholder={appConstants.PLACEHOLDER.ENTER_COMMENT}
            type={appConstants.TYPE.NOTE}
            name="comment"
            value={formik.values.comment}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.errors.comment}
            touched={formik.touched.comment}
          />

          <div className={styles['button-container']}>
            <Button
              label="Submit"
              type={appConstants.TYPE.CHECK}
              variant="blue"
              isLoading={isApproving}
              buttonType="submit"
            />
          </div>
          <SuccessModal
            isOpen={isSuccessModalOpen}
            isSuccess
            text="Submitted Successfully"
            timeout={1000}
          />
        </form>
      </Container>
    </div>
  );
};

export default CommonApplication;
