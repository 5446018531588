import React, { useState, useEffect, useRef } from 'react';
import styles from './SlideMenuRoles.module.scss';
import { getSvgByType } from '../../../../app/helpers/forms/GetSvgByType';
import appConstants from '../../../../app/constant/constants/appConstants';
import { useDispatch, useSelector } from 'react-redux';
import { setTitle } from '../../../../app/globals/pageTitleSlice';
import Input from '../../../atoms/forms/input/input/Input';
import PrivilageSelector from '../../../atoms/forms/privilage-selector/for-pages/PrivilageSelector';
import ApplicationsPrevilages from '../../../atoms/forms/privilage-selector/for-applications/PrivilageSelector';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import _endpoints from '../../../../app/constant/endpoints/_endpoints';
import { useGetQuery, usePostMutation } from '../../../../services/apiCall';
import { handleError } from '../../../molecules/table/Table';
import { successMessage } from '../../toast/Toast';
import Button from '../../../atoms/forms/button/Button';
import { getSideBarItems } from '../../../../app/helpers/permissions/getSideBarItems';
import Tooltip from '../../../molecules/tootltip/Tooltip';

function areRoleDetailsEqual(details1, details2) {
  if (details1.length !== details2.length) return false;

  for (let i = 0; i < details1.length; i++) {
    if (
      details1[i].pageId !== details2[i].pageId ||
      details1[i].pageActionId !== details2[i].pageActionId
    ) {
      return false;
    }
  }

  return true;
}

function transformData(data) {
  const result = [];
  data?.forEach((parent) => {
    if (parent.isSelected) {
      result.push({
        pageId: parent.pageId,
      });
    }
    parent?.childActions?.forEach((child) => {
      if (!child.isSelected) return;

      result.push({
        pageId: parent.pageId,
        pageActionId: child.pageActionId,
      });
    });

    parent?.childPages?.forEach((child) => {
      if (!child.isSelected) return;

      result.push({
        pageId: child.pageId,
      });

      child?.childActions?.forEach((action) => {
        if (action.isSelected) {
          result.push({
            pageId: child.pageId,
            pageActionId: action.pageActionId,
          });
        }
      });
    });
  });

  return result;
}

const SlideMenuRoles = ({ onSuccess, id, setIsOpen, isOpen }) => {
  const { data: updateData, isLoading: onUpdateLoad } = useGetQuery(
    { api: _endpoints.roles.getRolebyId, params: [`/${id}`] },
    { skip: !id, refetchOnMountOrArgChange: true }
  );
  const { data: createData, isLoading: onCreateLoad } = useGetQuery(
    { api: _endpoints.dropdown.getPages },
    { skip: !!id, refetchOnMountOrArgChange: true }
  );

  const [post, { isLoading: isPostLoading }] = usePostMutation();
  const [initialValues, setInitialValues] = useState(null);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const menuRef = useRef(null);

  const filteredLinks = getSideBarItems(user.sideBarPages);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (id && updateData) {
      const transformedRoleDetails = transformData(
        updateData[0]?.pageActions || []
      );
      setInitialValues({
        roleId: updateData[0]?.roleId || '',
        roleName: updateData[0]?.roleName || '',
        roleDetails: transformedRoleDetails,
        roleRequests: updateData[0]?.roleRequests || [],
      });
    } else if (!id && createData) {
      setInitialValues({
        roleId: '',
        roleName: '',
        roleDetails: transformData(createData?.pageActions || []),
        roleRequests: [],
      });
    }
  }, [updateData, createData, id]);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('no-scroll');
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.body.classList.remove('no-scroll');
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.body.classList.remove('no-scroll');
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  useEffect(() => {
    if (filteredLinks.length > 0) {
      const firstLink = filteredLinks[0];
      const firstChildLink = firstLink.childrens && firstLink.childrens[0];
      dispatch(
        setTitle(firstChildLink ? firstChildLink.label : firstLink.label)
      );
    }
  }, [filteredLinks, dispatch]);

  useEffect(() => {
    if (initialValues) {
      formik.setValues(initialValues);
    }
  }, [initialValues]);

  const formik = useFormik({
    initialValues: initialValues || {
      roleId: '',
      roleName: '',
      roleDetails: [],
      roleRequests: [],
    },
    validationSchema: Yup.object({
      roleName: Yup.string().required('Role name is required'),
    }),
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        if (!id) {
          delete values.roleId;
        }
        let formattedRoleDetails = values.roleDetails;

        if (
          !areRoleDetailsEqual(
            values.roleDetails,
            initialValues?.roleDetails || []
          )
        ) {
          formattedRoleDetails = transformData(values.roleDetails);
        }

        const formattedData = {
          ...values,
          roleDetails: formattedRoleDetails,
        };
        await post({
          apiUrl: _endpoints.roles.postRole,
          data: formattedData,
        }).unwrap();
        successMessage('Saved Successfully!');
        onSuccess();
        setIsOpen(false);
        formik.resetForm();
        setInitialValues(null);
      } catch (error) {
        handleError(error);
      }
    },
  });

  useEffect(() => {
    if (!isOpen) {
      formik.resetForm();
      setInitialValues(null);
    }
  }, [isOpen]);

  return (
    <>
      <div
        className={`${styles['menu-container']} ${isOpen ? styles.open : ''}`}
        ref={menuRef}
      >
        <div className={styles.header}>
          <h3>{id ? 'Edit Role' : 'New Role'}</h3>
          <Tooltip content="Close">
            <img
              src={getSvgByType(appConstants.TYPE.EXIT)}
              alt="Close"
              onClick={toggleMenu}
            />
          </Tooltip>
        </div>
        <div className={styles['menu']}>
          <Input
            label={appConstants.LABEL.ROLE_NAME}
            placeholder={appConstants.PLACEHOLDER.ENTER_ROLE}
            name="roleName"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.roleName}
            error={formik.touched.roleName && formik.errors.roleName}
            touched={formik.touched.roleName}
          />
          <div className={styles['slider-content']}>
            <PrivilageSelector
              key={updateData}
              isOpen={isOpen}
              api={_endpoints.dropdown.getPages}
              keys={{ label: 'pageName', id: 'pageId' }}
              onUpdate={(data) => {
                formik.setFieldValue('roleDetails', data.pageActions);
              }}
              id={id}
              data={id ? updateData : createData}
              isLoading={onCreateLoad ?? onUpdateLoad}
            />
            {isOpen ? (
              <ApplicationsPrevilages
                isOpen={isOpen}
                api={_endpoints.request.getRequestTypes}
                onUpdate={(data) => formik.setFieldValue('roleRequests', data)}
                data={initialValues}
                isLoading={onCreateLoad ?? onUpdateLoad}
              />
            ) : null}
          </div>
        </div>
        <div className={styles['button-container']}>
          <Button
            onClick={formik.handleSubmit}
            label={id ? 'Edit Role' : 'Create Role'}
            variant="danger"
            disabled={isPostLoading}
            isLoading={isPostLoading}
          />
        </div>
      </div>
      <Button
        variant="danger"
        label="Create Role"
        onClick={toggleMenu}
        buttonType="submit"
        type={appConstants.TYPE.ADD}
      />
    </>
  );
};

export default SlideMenuRoles;
