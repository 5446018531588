import React, { useEffect, useState } from 'react';
import styles from './EmailsLog.module.scss';
import EmailsReceivedList from './components/EmailsReceivedList';
import EmailsReceived from './components/EmailsReceived';
import Container from '../../../../../../components/hoc/container/Container';
// import noDataSvg from '../../../../../../assets/no';
const EmailsLog = ({ emailHistory }) => {
  const [selectedEmail, setSelectedEmail] = useState({});

  useEffect(() => {
    setSelectedEmail(emailHistory && emailHistory[0]);
  }, [emailHistory]);
  return (
    <>
      {emailHistory?.length > 0 ? (
        <div className={styles.requests}>
          <EmailsReceivedList
            data={emailHistory}
            selectedEmail={selectedEmail}
            setSelectedEmail={setSelectedEmail}
          />
          <EmailsReceived email={selectedEmail} />
        </div>
      ) : (
        <>
          <Container>
            <div className={styles['no-data']}>
              <p>No emails found</p>
            </div>
          </Container>
        </>
      )}
    </>
  );
};

export default EmailsLog;
