import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import styles from '../Table.module.scss';
import { getSvgByType } from '../../../../app/helpers/forms/GetSvgByType';
import appConstants from '../../../../app/constant/constants/appConstants';
import Tag from '../../../atoms/tag/Tag';
import Input from '../../../atoms/forms/input/input/Input';
import capitalizeFirstLetter from '../../../../app/helpers/forms/capitalizedFirstLetter';
import Loader from '../atoms/loader/Loader';
import { RadioButton } from '../../../atoms/forms/radio-group/RadioGroup';
import { usePostMutation } from '../../../../services/apiCall';
import { successMessage } from '../../../hoc/toast/Toast';
import { handleError } from '../Table';
import SuccessModal from '../../../hoc/success-modal/SuccessModals';

const hasActionsHeader = (actions) => {
  return (
    actions?.hasCancel ||
    actions?.hasEdit ||
    actions?.hasDelete ||
    actions?.hasView
  );
};

const RenderTableHeader = ({ tableHeaderData, actions, visibleColumns }) => (
  <thead>
    <tr>
      {actions?.hasOptionalSelect ? <th></th> : null}
      {tableHeaderData
        .filter((header) => visibleColumns.includes(header.key))
        .map((header, index) =>
          header?.isAppeal ? (
            <th key={index} className={styles['header-cell']}></th>
          ) : (
            <th key={index} className={styles['header-cell']}>
              <div className={styles['cell']}>
                <p>{header.title}</p>
                {header.isSorted && (
                  <span>
                    <img
                      src={getSvgByType(appConstants.TYPE.FILTER_ARROW)}
                      alt=""
                    />
                  </span>
                )}
              </div>
            </th>
          )
        )}
      {hasActionsHeader(actions) ? <th></th> : null}
    </tr>
  </thead>
);

const RenderTableBody = ({
  tableHeaderData,
  tableData,
  onRowClick,
  actions,
  isFiltering,
  visibleColumns,
  setQuery,
  query,
  isLoading,
  api,
  data,
  rowKey,
  deleteApi,
  fetchData,
}) => {
  const [post] = usePostMutation();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [item, setItem] = useState(null);

  const [selectedRow, setSelectedRow] = useState(null);

  const formik = useFormik({
    initialValues: tableHeaderData.reduce((acc, header) => {
      acc[header.key] = '';
      return acc;
    }, {}),
    onSubmit: (values) => {
      // console.log('submit value', values);
    },
  });

  const hasAnyAppeal = tableData?.some((item) => item.isAppeal);

  const handleRowClick = (item) => {
    // here we Find the item with the matching rowKey
    const selectedItem = data?.items?.find(
      (dataItem) => dataItem[rowKey] === item[rowKey]
    );

    if (onRowClick) {
      onRowClick(selectedItem);
    }
    if (actions?.hasOptionalSelect) setSelectedRow(item[rowKey]);
    return selectedItem;
  };

  const handleActions = (e, func, item) => {
    e.stopPropagation();

    if (item[rowKey] === '') {
      item[rowKey] = 0;
    }
    const selectedItem = data?.items?.find(
      (dataItem) => dataItem[rowKey] === item[rowKey]
    );

    if (typeof func === 'function') {
      func(selectedItem);
    } else {
      throw new Error(
        `Action function is not provided or not a function. Received: ${typeof func} --> please provide the function from outside the function or remove the functionality from table`
      );
    }
  };

  const handleDelete = async () => {
    const selectedItem = data?.items?.find(
      (dataItem) => dataItem[rowKey] === item[rowKey]
    );
    try {
      if (selectedItem) {
        await post({
          apiUrl: deleteApi,
          params: [`/${selectedItem[rowKey]}`],
        }).unwrap();

        successMessage('Deleted Successfully');
        handleCloseModal();
        fetchData(query);
      } else {
        handleError();
      }
    } catch (error) {
      handleError();
    }
  };
  const filteredHeaders = tableHeaderData.filter((header) =>
    visibleColumns.includes(header.key)
  );

  useEffect(() => {
    // this use effect is triggered when the header inputs are changing
    const values = formik.values;
    // structure an array as the backend needs
    const formatValues = Object.keys(values)
      .map((key) => ({
        columnName: capitalizeFirstLetter(key),
        columnValue: values[key],
      }))
      .filter(({ columnValue }) => columnValue !== '');
    // the new query to be sent
    const newQuery = { ...query, filterColumns: formatValues };
    // if the query is empty we should delete it from the query that will be send to backend
    if (formatValues.length === 0) {
      delete newQuery.filterColumns;
    }
    // rerender the parent when this state updates / recall api
    setQuery(newQuery);
  }, [formik.values]);
  useEffect(() => {
    formik.resetForm();
  }, [isFiltering]);
  const handleOpenModal = (e, item) => {
    e.stopPropagation();
    setItem(item);
    setIsDeleteModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleSubmitModal = () => {
    handleDelete();
  };
  return (
    <>
      {isLoading && api && (
        <td className={styles['loading-container']}>
          <Loader />
        </td>
      )}

      <tbody className={styles['table-body']}>
        {isFiltering
          ? filteredHeaders?.map((header, i) => (
              <td>
                <Input
                  name={header.key}
                  placeholder={appConstants.PLACEHOLDER.SEARCH}
                  onChange={formik.handleChange}
                  value={formik.values[header.key]}
                />
              </td>
            ))
          : null}
        {tableData && tableData?.length > 0 ? (
          tableData.map((item, index) => (
            <tr
              key={index}
              onClick={() => handleRowClick(item)}
              className={`${styles['clickable-row']} ${
                selectedRow === item[rowKey] ? styles['selected-row'] : ''
              }`}
            >
              {actions?.hasOptionalSelect ? (
                <td>
                  <RadioButton
                    name="rowSelection"
                    value={item[rowKey]}
                    checked={selectedRow === item[rowKey]}
                    onChange={(e) => handleRowClick(e, item)}
                  />
                </td>
              ) : null}
              {filteredHeaders?.map((header, i) => (
                <td key={i}>
                  {header.key === appConstants.STATUS ? (
                    <Tag label={item[header.key]} variant={item[header.key]} />
                  ) : (
                    <div
                      dangerouslySetInnerHTML={{ __html: item[header.key] }}
                    />
                    // <p>{item[header.key]}</p>
                  )}
                </td>
              ))}
              {hasActionsHeader(actions) && (
                <td
                  className={`${styles['action-container']} ${
                    hasAnyAppeal ? styles['action-container-appeal'] : ''
                  }`}
                >
                  {actions?.hasRole && (
                    <img
                      className={styles['action-image']}
                      src={getSvgByType(appConstants.TYPE.VIEW)}
                      alt="view"
                      onClick={(e) =>
                        handleActions(e, actions.hasRole.func, item)
                      }
                    />
                  )}
                  {actions?.hasEdit && (
                    <img
                      className={styles['action-image']}
                      src={getSvgByType(appConstants.TYPE.EDIT)}
                      alt="edit"
                      onClick={(e) =>
                        handleActions(e, actions.hasEdit.func, item)
                      }
                    />
                  )}
                  {actions?.hasDelete && (
                    <img
                      className={styles['action-image']}
                      src={getSvgByType(appConstants.TYPE.DELETE)}
                      alt="delete"
                      onClick={(e) => handleOpenModal(e, item)}
                    />
                  )}
                  {actions?.hasCancel && (
                    <img
                      className={styles['action-image']}
                      src={getSvgByType(appConstants.TYPE.CANCEL)}
                      alt="cancel"
                      onClick={(e) =>
                        handleActions(e, actions.hasCancel.func, item)
                      }
                    />
                  )}
                  {item.isAppeal && (
                    <div
                      className={styles.appeal}
                      onClick={(e) =>
                        handleActions(e, actions.hasRole.func, item)
                      }
                    >
                      <img
                        className={styles['action-image']}
                        src={getSvgByType(appConstants.TYPE.APPEAL)}
                        alt="appeal"
                      />
                      <p>{appConstants.APPEAL}</p>
                    </div>
                  )}
                </td>
              )}
            </tr>
          ))
        ) : !isLoading || !api ? (
          <tr>
            <td
              className={styles['no-data']}
              colSpan={tableHeaderData.length + 1}
            >
              {query.searchBy ? (
                <div className={styles['no-data-text']}>
                  <p>No results were found for</p>
                  <p>`{query.searchBy}`</p>
                </div>
              ) : (
                <p>{appConstants.NO_DATA}</p>
              )}
            </td>
          </tr>
        ) : null}
      </tbody>
      <SuccessModal
        isOpen={isDeleteModalOpen}
        onClose={handleCloseModal}
        onSubmit={handleSubmitModal}
        closeOnSubmit={true}
        onApproveButton={{ text: 'delete' }}
        text="Are you sure you want to delete ?"
      />
    </>
  );
};

export { RenderTableHeader, RenderTableBody };
