import React from 'react';
import styles from './Container.module.scss';

const Container = ({ children, width, className }) => {
  return (
    <div
      className={`${styles['container']} ${className || ''}`}
      style={{ width: width }}
    >
      {children}
    </div>
  );
};

export default Container;
