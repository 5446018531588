import { useFormik } from 'formik';
import * as Yup from 'yup';
import { usePostMutation } from '../../../../../services/apiCall';
import { handleError } from '../../../../molecules/table/Table';
import { useState } from 'react';

// const apiUrl = _endpoints.users.changeRole;

const validationSchema = Yup.object({
  firstName: Yup.string().required('firstName is required'),
  lastName: Yup.string().required('lastName is required'),
  email: Yup.string().required('firstName is required'),
  mobile: Yup.string().required('mobile is required'),
});

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  mobile: '',
};

const useForm = ({ onSuccess, data, userId }) => {
  const [key, setKey] = useState(0);

  const [post, { isLoading, isSuccess }] = usePostMutation();
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    // values.userId = [userId.userId];
    // let payload = {
    //   //   apiUrl,
    //   data: values,
    // };

    try {
      // await post(payload).unwrap();
      // resetForm();
      // setKey((prev) => prev + 1);

      // successMessage('user role updated !');
      onSuccess();
    } catch (error) {
      handleError(error);
    } finally {
      setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  return { isLoading, isSuccess, handleSubmit, formik, key };
};

export default useForm;
