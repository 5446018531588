import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useMemo, useState } from 'react';
import { usePostMutation } from '../../../../../../../services/apiCall';
import _endpoints from '../../../../../../../app/constant/endpoints/_endpoints';
import { handleError } from '../../../../../../../components/molecules/table/Table';
import { successMessage } from '../../../../../../../components/hoc/toast/Toast';

const apiUrl = _endpoints.settings.postSignature;

const validationSchema = Yup.object().shape({
  signatureName: Yup.string().required('signature name is required'),
  signature: Yup.string().required('signature is required'),
});

const useForm = ({ data, type, onSuccess }) => {
  const [key, setKey] = useState(0);
  const [post, { isLoading, isSuccess }] = usePostMutation();

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    if (type === 'create') delete values.emailSignatureId;
    let payload = {
      apiUrl,
      data: values,
    };
    try {
      await post(payload).unwrap();
      resetForm();
      setKey((prev) => prev + 1);
      onSuccess();
     successMessage('Saved Successfully');
    } catch (error) {
      handleError();
    } finally {
      setSubmitting(false);
    }
  };
  const initialValues = useMemo(
    () => ({
      emailSignatureId: type === 'edit' ? data?.emailSignatureId : null,
      signatureName: data?.signatureName || '',
      signature: data?.signature || '',
    }),
    [data, type]
  );
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  return { isLoading, isSuccess, handleSubmit, formik, key };
};

export default useForm;
