import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styles from './Tab.module.scss';
import { getSvgByType } from '../../../app/helpers/forms/GetSvgByType';

const Tabs = ({
  children,
  useRouting,
  tabClassname,
  variant,
  innerClassname,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(0);

  const child = React.Children.toArray(children);

  useEffect(() => {
    if (useRouting) {
      const activeIndex = child.findIndex(
        (tab) => tab.props.path === location.pathname
      );
      setIsActive(activeIndex);
    }
  }, [location, children, useRouting]);

  const handleTabClick = (index, path) => {
    setIsActive(index);
    if (useRouting) {
      navigate(path);
    }
  };

  return (
    <div className={styles.tabs}>
      <div
        className={`${styles['tab-buttons']} ${styles[variant] ?? ''} ${
          innerClassname ?? ''
        }`}
      >
        {child.map((tab, index) => {
          const svgPath = getSvgByType(tab.props.type);
          return (
            <div
              key={index}
              className={
                index === isActive
                  ? `${styles.tabHead} ${styles.active} ${tabClassname ?? ''}`
                  : `${styles.tabHead} ${tabClassname ?? ''}`
              }
              onClick={() => handleTabClick(index, tab.props.path)}
            >
              {tab.props.type ? (
                <img
                  src={svgPath}
                  className={styles.svg}
                  alt={tab.props.type}
                />
              ) : null}
              {useRouting ? (
                <Link className={styles.link} to={tab.props.path}>
                  {tab.props.label}
                </Link>
              ) : (
                tab.props.label
              )}
              {tab.props.totalCount != null && (
                <div
                  className={`${styles.enum} ${isActive ? styles.active : ''}`}
                >
                  <span>{tab.props.totalCount}</span>
                </div>
              )}
              {/* {tab.props.notificationNumber ? (
                <div className={styles.enum}>
                  <span>{tab.props.notificationNumber}</span>
                </div>
              ) : null} */}
            </div>
          );
        })}
      </div>
      {!useRouting && (
        <div
          className={`${styles['tab-content']} ${
            variant === 'container' ? styles.removePadding : ''
          }`}
        >
          {child.map((tab, index) => (
            <div
              key={index}
              className={
                index === isActive
                  ? `${styles.tab} ${styles.active}`
                  : styles.tab
              }
            >
              {tab.props.children}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const Tab = ({ children }) => {
  return <div>{children}</div>;
};

export { Tabs, Tab };
