import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useMemo, useState } from 'react';
import { usePostMutation } from '../../../../../../services/apiCall';
import { successMessage } from '../../../../../hoc/toast/Toast';
import _endpoints from '../../../../../../app/constant/endpoints/_endpoints';
import { handleError } from '../../../../../molecules/new-table/NewTable';

const apiUrl = _endpoints.program.setProgramVersion;

const validationSchema = Yup.object().shape({
  id: Yup.string().required('Application is required'),
  // department: Yup.string().required('Department is required'),
  DeanUserId: Yup.string().required('Dean is required'),
  ChairUserId: Yup.string().required('Chair is required'),
});

const useForm = ({ onSuccess, data, type }) => {
  const [key, setKey] = useState(0);
  const [post, { isLoading, isSuccess }] = usePostMutation();

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const url = `${apiUrl}?Id=${values.id}&DeanUserId=${values.DeanUserId}&ChairUserId=${values.ChairUserId}`;
    const payload = {
      apiUrl: url,
      // data: values,
    };

    try {
      await post(payload).unwrap();
      resetForm();
      setKey((prev) => prev + 1);
      successMessage('Saved Successfully');
      if (onSuccess) onSuccess();
    } catch (error) {
      handleError(error?.data?.message);
    } finally {
      setSubmitting(false);
    }
  };

  const initialValues = useMemo(
    () => ({
      id: data?.id || '',
      programVersion: data?.versionCode || '',
      programName: data?.description || '',
      department: '',
      DeanUserId: '',
      ChairUserId: '',
    }),
    [type, data]
  );

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  return { isLoading, isSuccess, handleSubmit, formik, key };
};

export default useForm;
