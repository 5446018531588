import { useFormik } from 'formik';
import * as Yup from 'yup';
import { usePostMutation } from '../../../../../../../services/apiCall';
import { handleError } from '../../../../../../molecules/new-table/NewTable';
import { useMemo } from 'react';
import _endpoints from '../../../../../../../app/constant/endpoints/_endpoints';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { reRenderHeader } from '../../../../../../../app/globals/storeSlices/userSlice';

const apiUrl = _endpoints.application.courseIncomplete;

const getValidationSchema = () =>
  Yup.object().shape({
    term: Yup.string().required('Required'),
    appeal: Yup.string().required('Required'),
    mobile: Yup.string().required('Required'),

    appealDocuments: Yup.array()
      .min(1, 'At least one appeal document is required')
      .of(Yup.mixed().required('Appeal Documents is required')),
    studentsDocuments: Yup.array()
      .min(1, 'At least one student document is required')
      .of(Yup.mixed().required('Students Documents is required')),
  });

const useForm = ({ selectedCourse, termId, onSuccess }) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [post, { isLoading }] = usePostMutation();

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    // Initialize FormData
    const formData = new FormData();
    formData.append('Comments', values.comments);
    formData.append('TermId', termId);
    formData.append('CourseId', selectedCourse?.courseId);
    formData.append('SectionId', selectedCourse?.section?.id);

    values.supportingDocuments.forEach((file, index) => {
      formData.append('SupportingDocuments', file);
    });

    const payload = { apiUrl, data: formData, type: 'formData' };

    try {
      await post(payload).unwrap();
      resetForm();
      onSuccess();
      dispatch(reRenderHeader());
      setTimeout(() => {
        navigate(-1);
      }, 1000);
    } catch (error) {
      handleError();
    } finally {
      setSubmitting(false);
    }
  };

  const initialValues = useMemo(
    () => ({
      term: '',
      appeal: '',
      mobile: '',
      appealDocuments: [],
      studentsDocuments: [],
    }),
    []
  );

  const formik = useFormik({
    initialValues,
    validationSchema: getValidationSchema(),
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });
  console.log('Djdnw', formik);

  return { formik, isLoading };
};
export default useForm;
