import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../../assets/icons/logo/audLogo.svg';
import logoSmall from '../../../assets/icons/logo/audLogoSmall.svg';
import styles from './Sidebar.module.scss';
import appConstants from '../../../app/constant/constants/appConstants';
import redCircleSvg from '../../../assets/icons/vectors/redCircle.svg';
import fleshSvg from '../../../assets/icons/vectors/flesh.svg';
import NavigationLink from './factory/Factory';
import { useDispatch, useSelector } from 'react-redux';
import { setTitle } from '../../../app/globals/pageTitleSlice';
import { getSideBarItems } from '../../../app/helpers/permissions/getSideBarItems';
import Tooltip from '../../molecules/tootltip/Tooltip';

const Sidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const [isOpen, setIsOpen] = useState(true);

  const filteredLinks = getSideBarItems(user.sideBarPages);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleNavigate = (path, label) => {
    navigate(path);
    dispatch(setTitle({ isBack: false, title: label }));
  };

  useEffect(() => {
    if (filteredLinks.length > 0) {
      const firstLink = filteredLinks[0];
      const firstChildLink = firstLink.childrens && firstLink.childrens[0];
      if (firstChildLink) {
        dispatch(setTitle({ title: firstLink.label, isBack: false }));
      } else {
        dispatch(setTitle({ title: firstLink.label, isBack: false }));
      }
    }
  }, []);

  return (
    <nav className={styles['sidebar-container']}>
      <button
        className={`${styles.toggleButton} ${
          !isOpen ? styles['toggle-button-rotate'] : ''
        }`}
        onClick={toggleSidebar}
      >
        <Tooltip content={isOpen ? 'Close' : 'Open'} placement="right">
          <img src={redCircleSvg} alt="" />
        </Tooltip>
        <img className={styles.flesh} src={fleshSvg} alt="" />
      </button>

      <div className={`${styles.sidebar} ${isOpen ? styles.open : ''}`}>
        <div className={styles['logo']}>
          <img
            className={`${styles['aud-logo']} ${
              !isOpen ? styles['aud-logo-small'] : ''
            }`}
            src={isOpen ? logo : logoSmall}
            alt={appConstants.AUD_LOGO}
          />
        </div>
        <div className={styles.navigation}>
          {filteredLinks.map((link, index) => (
            <NavigationLink
              handleNavigate={handleNavigate}
              key={index}
              link={link}
              isBarOpen={isOpen}
            />
          ))}
        </div>
      </div>
    </nav>
  );
};

export default Sidebar;
