import React, { useState } from 'react';
import styles from './Application.module.scss';
import Input from '../../../../../../atoms/forms/input/input/Input';
import Button from '../../../../../../atoms/forms/button/Button';
import Select from '../../../../../../atoms/forms/select/select/Select';
import appConstants from '../../../../../../../app/constant/constants/appConstants';
import Container from '../../../../../../hoc/container/Container';
import UploadFile from '../../../../../../atoms/forms/upload-file/UploadFile';
import TextArea from '../../../../../../atoms/forms/text-area/TextArea';
import Title from '../../../../../../atoms/title/Title';
import MultipleFileUpload from '../../../../../../atoms/forms/upload-file/MultipleFileUpload';
import useForm from './hook';
import SuccessModal from '../../../../../../hoc/success-modal/SuccessModals';

const Application = () => {
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const onSuccess = () => {
    setIsSuccessModalOpen(true);
  };
  const { formik, isLoading } = useForm({
    onSuccess: onSuccess,
  });
  return (
    <>
      <form onSubmit={formik.handleSubmit} className="form">
        <div className={styles['application-container']}>
          <Container>
            <div className={styles.application}>
              <div className={styles['input-container']}>
                <Select
                  placeholder={appConstants.PLACEHOLDER.SELECT_TERM}
                  label={appConstants.LABEL.APPEAL_TERM}
                  type={appConstants.TYPE.DATE}
                  name="term"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.term}
                  error={formik.touched.term && formik.errors.term}
                  touched={formik.touched.term}
                />
                <Select
                  placeholder={appConstants.PLACEHOLDER.SELECT_APPEAL}
                  label={appConstants.LABEL.NATURE_OF_APPEAL}
                  type={appConstants.TYPE.APPEAL}
                  name="appeal"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.appeal}
                  error={formik.touched.appeal && formik.errors.appeal}
                  touched={formik.touched.appeal}
                />
              </div>
              <Input
                label="Mobile"
                placeholder="Enter Mobile"
                type={'mobile'}
                name="mobile"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.mobile}
                error={formik.touched.mobile && formik.errors.mobile}
                touched={formik.touched.mobile}
              />

              <Title
                variant="sm-blue"
                text="The following documents must be attached with this form:"
              />

              <MultipleFileUpload
                title="Upload letter document with reason appeal"
                subTitle="Sample Letter"
                name="appealDocuments"
                value={formik.values.appealDocuments}
                onChange={(e) => {
                  formik.setFieldValue('appealDocuments', e.target.value);
                }}
                onBlur={formik.handleBlur}
                error={formik.errors.appealDocuments}
                touched={formik.touched.appealDocuments}
                maxSize="45000000"
              />
              <MultipleFileUpload
                title="Upload document to support student’s letter"
                subTitle="Supported: pdf, jpg, tiff, doc & docx Max size: 4.5MB"
                name="studentsDocuments"
                value={formik.values.studentsDocuments}
                onChange={(e) => {
                  formik.setFieldValue('studentsDocuments', e.target.value);
                }}
                onBlur={formik.handleBlur}
                error={formik.errors.studentsDocuments}
                touched={formik.touched.studentsDocuments}
                maxSize="45000000"
              />

              <Button
                label="Submit"
                buttonType="submit"
                isLoading={isLoading}
                variant={appConstants.VARIANT.DANGER}
                type={appConstants.TYPE.CHECK}
              />
            </div>
          </Container>
        </div>{' '}
      </form>
      <SuccessModal
        isOpen={isSuccessModalOpen}
        isSuccess
        text={'Submitted Successfully'}
        timeout={1000}
      />
    </>
  );
};

export default Application;
