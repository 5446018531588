import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styles from './SendEmail.module.scss';
import EmailTab from '../../../../../components/organisims/email-tabs/email/EmailTab';
import Container from '../../../../../components/hoc/container/Container';

const SendEmail = () => {
  const location = useLocation();
  const [defaultActiveTab, setDefaultActiveTab] = useState(0);

  useEffect(() => {
    if (location.state?.openEmailTemplateTab) {
      setDefaultActiveTab(1);
    } else setDefaultActiveTab(0);
  }, [location.state]);

  return (
    <div className={styles['email-container']}>
      <Container>
        <EmailTab />
      </Container>

      {/* <TabBox label={appConstants.LABEL.EMAIL_TEMPLATE}>
          <EmailTemplate />
        </TabBox>
        <TabBox label="Reminder">
          <EmailReminder />
        </TabBox> */}
    </div>
  );
};

export default SendEmail;
