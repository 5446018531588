import React, { useState, useRef, useCallback, useId, useEffect } from 'react';
import styles from './DateSelect.module.scss';
import Label from '../../label/Label';
import { useDropdown } from '../factory/Factory';
import { getSvgByType } from '../../../../../app/helpers/forms/GetSvgByType';
import appConstants from '../../../../../app/constant/constants/appConstants';
import { format, parseISO } from 'date-fns';

const formatDateForInput = (dateString) => {
  if (!dateString) return '';
  return format(parseISO(dateString), 'yyyy-MM-dd');
};
const DateSelect = ({
  label,
  placeholder,
  name,
  value: initialValue,
  onChange,
  error,
  touched,
  style,
  classname,
  startDate,
  endDate,
  ...props
}) => {
  const id = useId();

  const formattedInitialValue = formatDateForInput(initialValue);
  const formattedStartDate = formatDateForInput(startDate);
  const formattedEndDate = formatDateForInput(endDate);

  const [value, setValue] = useState(formattedInitialValue || '');
  const { isOpen, dropdownRef, inputRef, setIsOpen } = useDropdown(value);

  useEffect(() => {
    setValue(formattedInitialValue);
  }, [formattedInitialValue]);

  const handleChange = useCallback(
    (e) => {
      const newValue = e.target.value;
      setValue(newValue);
      if (onChange) {
        onChange(name, newValue);
      }
    },
    [name, onChange]
  );

  const handleWrapperClick = () => {
    setIsOpen(!isOpen);
    if (inputRef.current) {
      inputRef.current.showPicker();
    }
  };

  return (
    <div
      className={`${styles['select-date-container']} ${classname}`}
      style={style}
      ref={dropdownRef}
    >
      {label && <Label text={label} htmlFor={id} />}
      <div
        className={styles['select-wrapper']}
        tabIndex={0}
        onClick={handleWrapperClick}
      >
        <div className={styles['select-display']}>
          <img src={getSvgByType(appConstants.TYPE.DATE)} alt={placeholder} />
          <input
            id={id}
            type={appConstants.TYPE.DATE}
            ref={inputRef}
            value={value}
            onChange={handleChange}
            placeholder={placeholder}
            className={`${styles['select']} ${!value && styles['placeholder']}`}
            min={formattedStartDate}
            max={formattedEndDate}
            {...props}
          />
        </div>
      </div>
      {error && touched && (
        <div className={styles['error-message']}>{error}</div>
      )}
    </div>
  );
};

export default DateSelect;
