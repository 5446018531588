import React from 'react';
import { Outlet } from 'react-router-dom';
import globalStyle from '../../../../globals.module.scss';
import styles from './StudentsBodyLayout.module.scss';
import StudentsHeader from '../header/StudentsHeader';

const StudentsBodyLayout = () => {
  const pageClasses = `${styles.page} ${globalStyle['body-layout-width']}`;

  return (
    <div className={styles['app-body']}>
      <div className={styles['layout']}>
        <StudentsHeader />
        <div className={pageClasses}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default StudentsBodyLayout;
