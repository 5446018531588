import React from 'react';
import styles from './Title.module.scss';
const getClassName = (variant) => {
  const className = styles[`page-title--${variant}`];
  return className || styles['page-title'];
};

const Title = ({ text, variant }) => {
  const className = getClassName(variant);

  return <p className={className}>{text ?? ''}</p>;
};

export default Title;
