import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  title: 'All requests',
  isBack: false,
};

const pageTitleSlice = createSlice({
  name: 'pageTitle',
  initialState,
  reducers: {
    setTitle: (state, action) => {
      state.title = action?.payload?.title;
      state.isBack = action?.payload?.isBack;
    },
  },
});

export const { setTitle } = pageTitleSlice.actions;
export default pageTitleSlice.reducer;
