import React, { useState, useEffect, useRef } from 'react';
import Slider from 'react-slick';
import styles from './Carousel.module.scss';
import ApplicationTag from '../../atoms/application-tag/ApplicationTag';
import SlideMenuApplications from '../../hoc/slide-menu-rtl/SlideMenuApplications';

const Carousel = ({
  data,
  isLoading,
  setActiveApplication,
  activeApplication,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef(null);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex),
    responsive: [
      {
        breakpoint: 2024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1424,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    if (data && data.length > 0) {
      const activeIndex = data.findIndex(
        (app) => app.id === activeApplication?.id
      );
      if (activeIndex >= 0 && activeIndex !== currentSlide) {
        sliderRef.current?.slickGoTo(activeIndex);
      }
    }
  }, [activeApplication, data, currentSlide]);

  useEffect(() => {
    if (data && data.length > 0) {
      setCurrentSlide(0);
      sliderRef.current?.slickGoTo(0);
    }
  }, [data]);
  return (
    <div className={styles['slider-wrapper']}>
      <div className={styles['slider-container']}>
        <Slider {...settings} ref={sliderRef}>
          {data?.map((app, index) => (
            <div key={index} className={styles['card']}>
              {isLoading ? null : (
                <ApplicationTag
                  isActive={activeApplication?.id === app.id}
                  data={app}
                  onClick={() => setActiveApplication(app)}
                  variant="small"
                />
              )}
            </div>
          ))}
        </Slider>
      </div>
      <SlideMenuApplications
        data={data}
        activeApplication={activeApplication}
        setActiveApplication={setActiveApplication}
      />
    </div>
  );
};

export default Carousel;
