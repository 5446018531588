import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { format } from 'date-fns';
import styles from './Table.module.scss';
import Title from '../../atoms/title/Title';
import { TableFilters } from './factory/Filters';
import { RenderTableBody, RenderTableHeader } from './factory/Components';
import Paginator from '../paginator/Paginator';
import { useGetQuery, usePostMutation } from '../../../services/apiCall';
import debounce from 'lodash.debounce';
import useFetchOnFocus from '../../../app/hooks/useFetchOnFocus';
import useFetchOnReconnect from '../../../app/hooks/useFetchOnReconnect';
import { errorMessage } from '../../hoc/toast/Toast';

export const handleError = (error) => {
  if (error?.status) {
    errorMessage('Server error occurred');
  } else if (error?.request) {
    errorMessage('No response from server. Please try again later.');
  } else {
    errorMessage(error?.message || 'An error occurred');
  }
};
const formatDate = (dateString) => {
  try {
    const date = new Date(dateString);
    return format(date, 'MM/dd/yyyy hh:mm a');
  } catch (e) {
    return '';
  }
};

const isValidDate = (value) => {
  const date = new Date(value);
  return !isNaN(date.getTime());
};

const Table = ({
  title,
  onRowClick,
  rightContainer,
  actions = {},
  deleteApi,
  api,
  method = 'POST',
  refetch,
  extraQuery,
  apiConfig = { perPage: 10 },
  rowKey = 'id',
  headerTitle,
  dependsOn,
}) => {
  const tableContainer_REF = useRef(null);
  const [post] = usePostMutation();
  const { data: getCallData } = useGetQuery(
    { api },
    { skip: method !== 'GET' || !api }
  );
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isFiltering, setIsFiltering] = useState(false);
  const [visibleColumns, setVisibleColumns] = useState([]);

  const [query, setQuery] = useState({
    pageSize: apiConfig?.perPage,
    pageNumber: 0,
  });

  const fetchData = useCallback(
    debounce(async (query) => {
      // to make sure the scrolling will reset when calling the api (the scrolling restarts to top position)
      if (tableContainer_REF.current) {
        tableContainer_REF.current.scrollTop = 0;
      }
      setIsLoading(true);
      try {
        let result;
        if (method === 'POST') {
          result = await post({
            apiUrl: extraQuery
              ? `${api}?${extraQuery?.query}=${extraQuery?.id}`
              : api,
            data: query,
          }).unwrap();
        } else if (method === 'GET') {
          result = await getCallData;
        }
        if (result) {
          setData(result);
          setVisibleColumns(
            result.tableView?.[0]?.fields?.map((field) => field.fieldName) || []
          );
        }
      } catch (error) {
        const errorMessage = error?.data?.message;
        handleError(errorMessage);
      } finally {
        setIsLoading(false);
      }
    }, 500),
    [post, api, getCallData, method, extraQuery?.id]
  );
  useFetchOnFocus(fetchData, query, api);
  useFetchOnReconnect(fetchData, query, api);

  useEffect(() => {
    if (!isFiltering) {
      delete query.filterColumns;
    }
    if (api) fetchData(query);
  }, [query, refetch, api, extraQuery?.id]);

  const tableHeaderData = useMemo(
    () =>
      data?.tableView?.[0]?.fields
        ?.filter((field) => !dependsOn || field.relatedType === dependsOn)
        ?.map((field) => ({
          title: field.headerName,
          key: field.fieldName,
          isSorted: field.hasSort,
        })) || [],
    [data, dependsOn]
  );

  const tableData = useMemo(() => {
    if (tableHeaderData?.length === 0) {
      return [];
    }

    return (
      data?.items
        ?.map((item) => {
          let rowData = {};
          let hasData = false;

          tableHeaderData.forEach((header) => {
            const fieldParts = header.key.split('.');
            let value = item;

            for (let part of fieldParts) {
              value = value?.[part] || '';
            }

            if (isValidDate(value)) {
              value = formatDate(value);
            }

            if (value) {
              hasData = true;
            }

            rowData[header.key] = value;
          });

          rowData[rowKey] = item[rowKey] || '';

          return hasData ? rowData : null;
        })
        .filter((row) => row !== null) || []
    );
  }, [data, tableHeaderData, rowKey]);

  const tableActions = { ...data?.tableView?.[0], ...actions } || actions;

  const handleFilterClick = () => {
    setIsFiltering(!isFiltering);
  };

  const handleColumnVisibilityChange = (columnKey) => {
    const key = columnKey.value;

    setVisibleColumns((prevVisibleColumns) =>
      prevVisibleColumns.includes(key)
        ? prevVisibleColumns.filter((existingKey) => existingKey !== key)
        : [...prevVisibleColumns, key]
    );
  };

  const handlePageChange = (page) => {
    setQuery((prevQuery) => ({
      ...prevQuery,
      pageNumber: page,
    }));
  };

  useEffect(() => {
    if (isLoading) {
      const tableContainer = document.querySelector(
        `.${styles['table-container']} ${isLoading && styles.oos}`
      );
      if (tableContainer) {
        tableContainer.scrollTo(0, 0);
      }
    }
  }, [isLoading]);

  const hasData = data?.items?.length > 0;
  const totalPages = data?.totalPages || 1;

  return (
    <div className={styles['table-wrapper']}>
      {headerTitle && <Title text={title} variant="md" />}
      <TableFilters
        filters={tableActions}
        rightContainer={rightContainer}
        handleFilterClick={handleFilterClick}
        isFiltering={isFiltering}
        visibleColumns={visibleColumns}
        onColumnVisibilityChange={handleColumnVisibilityChange}
        tableHeaderData={tableHeaderData}
        isLoading={isLoading}
        handleSearchChange={setQuery}
        query={query}
        hasData={hasData}
      />
      {title && !headerTitle && <Title text={title} />}
      <div
        ref={tableContainer_REF}
        className={`${styles['table-container']} ${
          isLoading ? styles['hidden-overflow'] : ''
        }`}
      >
        <table className={styles['table']}>
          <RenderTableHeader
            tableHeaderData={tableHeaderData}
            actions={tableActions}
            isFiltering={isFiltering}
            visibleColumns={visibleColumns}
            isLoading={isLoading}
          />
          <RenderTableBody
            tableHeaderData={tableHeaderData}
            tableData={tableData}
            data={data}
            onRowClick={onRowClick}
            actions={tableActions}
            isFiltering={isFiltering}
            visibleColumns={visibleColumns}
            isLoading={isLoading}
            query={query}
            setQuery={setQuery}
            api={api}
            rowKey={rowKey}
            deleteApi={deleteApi}
            fetchData={fetchData}
          />
        </table>
      </div>
      {hasData && data?.tableView[0]?.hasPaging && (
        <Paginator
          currentPage={query.pageNumber}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          config={apiConfig}
          data={data}
        />
      )}
    </div>
  );
};

export default Table;
