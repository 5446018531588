import React from 'react';
import styles from './ProgramVersionForm.module.scss';
import Button from '../../../../../../components/atoms/forms/button/Button';
import Select from '../../../../../../components/atoms/forms/select/select/Select';
import Input from '../../../../../atoms/forms/input/input/Input';
import appConstants from '../../../../../../app/constant/constants/appConstants';
import { useGetQuery } from '../../../../../../services/apiCall';
import _endpoints from '../../../../../../app/constant/endpoints/_endpoints';

const userApi = _endpoints.dropdown.getUser;

const ProgramVersionForm = ({ formik, onClose, isLoading }) => {
  const handleFormSubmit = (event) => {
    event.preventDefault();
    formik.handleSubmit();
  };

  const { data: usersData } = useGetQuery({ api: userApi });

  return (
    <form
      className={styles.form}
      autoComplete="off"
      onSubmit={handleFormSubmit}
    >
      <Input
        label="Program Version"
        type={appConstants.TYPE.FILE}
        placeholder="Enter program version"
        name="programVersion"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.programVersion}
        error={formik.touched.programVersion && formik.errors.programVersion}
        touched={formik.touched.programVersion}
        disabled={true}
      />

      <Input
        label="Program Name"
        type={appConstants.TYPE.FILE}
        placeholder="Enter program name"
        name="programName"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.programName}
        error={formik.touched.programName && formik.errors.programName}
        touched={formik.touched.programName}
        disabled={true}
      />

      <Select
        label="Department"
        type={appConstants.TYPE.FILE}
        placeholder="Select department"
        name="department"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.department}
        error={formik.touched.department && formik.errors.department}
        touched={formik.touched.department}
      />

      <Select
        label="Dean"
        type={appConstants.TYPE.FILE}
        placeholder="Select dean"
        name="DeanUserId"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        options={usersData}
        value={formik.values.DeanUserId}
        error={formik.touched.DeanUserId && formik.errors.DeanUserId}
        touched={formik.touched.DeanUserId}
      />

      <Select
        label="Chair"
        type={appConstants.TYPE.FILE}
        placeholder="Select chair"
        name="ChairUserId"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        options={usersData}
        value={formik.values.ChairUserId}
        error={formik.touched.ChairUserId && formik.errors.ChairUserId}
        touched={formik.touched.ChairUserId}
      />

      <div className="form-footer">
        <Button onClick={onClose} label={appConstants.CANCEL} />
        <Button
          onClick={handleFormSubmit}
          label={formik.values.id ? 'Save Changes' : 'Add'}
          variant={appConstants.VARIANT.DANGER}
          type={appConstants.TYPE.CHECK}
          isLoading={isLoading}
          buttonType="submit"
        />
      </div>
    </form>
  );
};

export default ProgramVersionForm;
