import { useFormik } from 'formik';
import * as Yup from 'yup';
import { usePostMutation } from '../../../../../../services/apiCall';
import _endpoints from '../../../../../../app/constant/endpoints/_endpoints';
import { handleError } from '../../../../../molecules/new-table/NewTable';
import { successMessage } from '../../../../../hoc/toast/Toast';

const apiUrl = _endpoints.specialApproval.post;

const useForm = ({ data, onSuccess }) => {
  const [post, { isLoading, isSuccess }] = usePostMutation();
  const validationSchema = Yup.object().shape({
    studentCredits: Yup.number()
      .required('Student credits are required')
      .min(0, 'Credits must be greater than or equal to 0'),
    programs: Yup.array().min(1, 'At least one program must be selected'),
    groups: Yup.array().min(1, 'At least one group must be selected'),
  });

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const payload = {
      apiUrl,
      data: values,
    };

    try {
      await post(payload).unwrap();
      resetForm();
      successMessage('Saved Successfully!');
      onSuccess();
    } catch (error) {
      handleError();
    } finally {
      setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      requestType: 3,
      studentCredits: data?.studentCredits || '',
      programs: data?.programs || [],
      groups: data?.groups || [],
    },
    validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  return { formik, isLoading };
};

export default useForm;
