import React, { useEffect, useState } from 'react';
import styles from './OfflinePage.module.scss';
import {
  errorMessage,
  successMessage,
} from '../../../components/hoc/toast/Toast';
import Button from '../../../components/atoms/forms/button/Button';

const OfflinePage = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  let timeoutId;

  const retryConnection = () => {
    if (navigator.onLine) {
      window.location.href = '/offline';
    } else {
      errorMessage('Still offline. Please check your connection!');
    }
  };

  useEffect(() => {
    const updateOnlineStatus = () => {
      setIsOnline(navigator.onLine);
      if (navigator.onLine) {
        successMessage('You’re back online!');

        if (timeoutId) clearTimeout(timeoutId);

        timeoutId = setTimeout(() => {
          window.history.back();
        }, 1000);
      }
    };

    updateOnlineStatus();

    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);

    return () => {
      window.removeEventListener('online', updateOnlineStatus);
      window.removeEventListener('offline', updateOnlineStatus);
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, []);

  return (
    <div className={styles['offline-page']}>
      <h1>You Are Offline</h1>
      <p>It looks like you have lost your internet connection.</p>
      <Button onClick={retryConnection} label="Retry" variant="danger" />
    </div>
  );
};

export default OfflinePage;
