import React, { useEffect, useId, useState } from 'react';
import styles from './MultiAttachment.module.scss';
import Label from '../label/Label';
import { getSvgByType } from '../../../../app/helpers/forms/GetSvgByType';
import appConstants from '../../../../app/constant/constants/appConstants';

const MultiAttachment = ({
  label,
  placeholder,
  required,
  name,
  value = [],
  onChange,
  onBlur,
  error,
  touched,
  style,
  autoComplete,
  ...props
}) => {
  const id = useId();

  const [attachments, setAttachments] = useState(value);

  useEffect(() => {
    setAttachments(value);
  }, [value]);

  const handleFileChange = (event) => {
    const files = event.target.files ? Array.from(event.target.files) : [];
    if (files.length > 0) {
      const newAttachments = [...attachments, ...files];
      setAttachments(newAttachments);

      if (onChange) {
        onChange({
          target: { name, value: newAttachments },
        });
      }
    }
  };

  const handleRemoveAttachment = (index) => {
    const newAttachments = attachments.filter((_, i) => i !== index);
    setAttachments(newAttachments);
    if (onChange) {
      onChange({
        target: { name, value: newAttachments },
      });
    }
  };

  return (
    <div className={styles['input-container']} style={style}>
      {label && <Label text={label} required={required} htmlFor={id} />}
      <div
        className={`${styles['input-wrapper']} ${
          error && touched ? styles['input-error'] : ''
        }`}
      >
        <label className={styles['custom-upload-button']}>
          <img
            src={getSvgByType(appConstants.TYPE.ATTACHMENTS_PAPER)}
            className={styles.svg}
            alt="Upload"
          />
        </label>
        {attachments.length > 0 && (
          <div className={styles['attachments']}>
            {attachments.map((file, index) => (
              <div key={index} className={styles['attachment-item']}>
                <span className={styles['file-name']}>
                  {file.fileName ?? file.name}
                </span>

                <button
                  type="button"
                  className={styles['remove-button']}
                  onClick={() => handleRemoveAttachment(index)}
                >
                  &times;
                </button>
              </div>
            ))}
          </div>
        )}
        <input
          id="fileInput"
          name={name}
          type="file"
          className={styles.input}
          onChange={handleFileChange}
          onBlur={onBlur}
          autoComplete={autoComplete}
          multiple
          {...props}
        />
        <label htmlFor="fileInput" className={styles['add-more']}>
          <p>Add More</p>
        </label>
      </div>
      {error && touched && (
        <div className={styles['error-message']}>{error}</div>
      )}
    </div>
  );
};

export default MultiAttachment;
