import React, { useState } from 'react';
import styles from './EmailReminder.module.scss';
import NewTable from '../../../molecules/new-table/NewTable';
import _endpoints from '../../../../app/constant/endpoints/_endpoints';
import New_EmailForm from '../email/email-form/New_EmailForm';
import Modal from '../../../hoc/modal/Modal';
import Button from '../../../atoms/forms/button/Button';
import appConstants from '../../../../app/constant/constants/appConstants';
import EmailReminderForm from './form/EmailReminderForm';

const api = _endpoints.reminder.getReminder;
const EmailReminder = () => {
  const [isModalOpen, setIsModalOpen] = useState();
  const [rowData, setRowData] = useState(null);
  const [refetch, setRefetch] = useState(false);

  const onSuccess = () => {
    setIsModalOpen(false);
    setRowData(null);
    setRefetch(!refetch);
  };
  const handleNewReminder = (item) => {
    setRowData(item);
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setRowData(null);
  };

  return (
    <div>
      <NewTable
        api={api}
        refetch={refetch}
        rightContent={
          <Button
            variant={appConstants.VARIANT.DANGER}
            label="Add New"
            type={appConstants.TYPE.ADD}
            className={styles.button}
            onClick={() => setIsModalOpen(!isModalOpen)}
          />
        }
        actions={{
          hasEdit: {
            func: (item) => {
              handleNewReminder(item);
            },
          },
          hasDelete: true,
        }}
        deleteApi={_endpoints.reminder.deleteReminder}
      />

      <Modal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        title="Create Reminder"
      >
        <EmailReminderForm
          data={rowData}
          handleClose={handleCloseModal}
          onSuccess={onSuccess}
        />
      </Modal>
    </div>
  );
};

export default EmailReminder;
