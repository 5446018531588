import React, { useEffect, useState } from 'react';
import styles from './Login.module.scss';
import Button from '../../../components/atoms/forms/button/Button';
import Input from '../../../components/atoms/forms/input/input/Input';
import appConstants from '../../../app/constant/constants/appConstants';
import loginVideo from '../../../assets/video/login/login-background-video.mp4';
import audLogo from '../../../assets/icons/logo/audLogo.svg';
import useForm from './factory/hook';
import _endpoints from '../../../app/constant/endpoints/_endpoints';

const Login = () => {
  const { formik, isLoading } = useForm();

  return (
    <div className={styles['login-page']}>
      <div className={styles['form-container']}>
        <div className={styles['form']}>
          <div className={styles.logo}>
            <img src={audLogo} alt={appConstants.AUD_LOGO} />
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className={styles['input-container']}>
              <Input
                label={appConstants.LABEL.EMAIL}
                type={appConstants.TYPE.EMAIL}
                placeholder={appConstants.PLACEHOLDER.ENTER_EMAIL}
                name="username"
                value={formik.values.username}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.username && formik.errors.username}
                touched={formik.touched.username}
              />
              <Input
                label={appConstants.LABEL.PASSWORD}
                type={appConstants.TYPE.PASSWORD}
                placeholder={appConstants.PLACEHOLDER.ENTER_PASSWORD}
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.password && formik.errors.password}
                touched={formik.touched.password}
                inputType="password"
              />

              <div className={styles.footer}>
                <Button
                  buttonType="submit"
                  label="Login"
                  width="100%"
                  type="none"
                  variant="danger"
                  className={styles.button}
                  onClick={formik.handleSubmit}
                  isLoading={isLoading}
                  isDisabled={isLoading}
                />
                <p className={styles.text}>{appConstants.FORGOT_PASSWORD_Q}</p>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className={styles['login-video']}>
        <div className={styles.video}>
          <video autoPlay muted loop playsInline>
            <source src={loginVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>
  );
};

export default Login;
