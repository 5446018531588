import React from 'react';
import styles from './GradeAppeal_TEMP.module.scss';
import appConstants from '../../../../../app/constant/constants/appConstants';
import { Tab, Tabs } from '../../../../molecules/tab/Tab';
import CommonApplication from '../commonComponents/CommonApplication/CommonApplication';
import _endpoints from '../../../../../app/constant/endpoints/_endpoints';
const api = _endpoints.terms.getIncompleteTerms;

const GradeAppeal_TEMP = () => {
  return <CommonApplication requestType="8" api={api} />;
};

export default GradeAppeal_TEMP;
