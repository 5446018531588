import React, { useState, useEffect, useRef, useMemo } from 'react';
import styles from './SlideMenuRtl.module.scss';
import { getSvgByType } from '../../../app/helpers/forms/GetSvgByType';
import appConstants from '../../../app/constant/constants/appConstants';
import { useDispatch, useSelector } from 'react-redux';
import { setTitle } from '../../../app/globals/pageTitleSlice';
import ApplicationTag from '../../atoms/application-tag/ApplicationTag';
import SearchInput from '../../atoms/forms/input/searchInput/SearchInput';
import _endpoints from '../../../app/constant/endpoints/_endpoints';
import { getSideBarItems } from '../../../app/helpers/permissions/getSideBarItems';
import { useGetQuery } from '../../../services/apiCall';
import Tooltip from '../../molecules/tootltip/Tooltip';
const applicationsApi = _endpoints.request.getRequestTypes;

const SlideMenuApplications = ({
  data,
  activeApplication,
  setActiveApplication,
  variant,
}) => {
  const requestTypes = useSelector((state) => state?.user?.permissions?.types);

  const finalData = data || requestTypes;

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const menuRef = useRef(null);

  const filteredLinks = getSideBarItems(user.sideBarPages);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('no-scroll');
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.body.classList.remove('no-scroll');
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.body.classList.remove('no-scroll');
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  useEffect(() => {
    if (filteredLinks.length > 0) {
      const firstLink = filteredLinks[0];
      const firstChildLink = firstLink.childrens && firstLink.childrens[0];

      if (firstChildLink) {
        dispatch(setTitle(firstChildLink.label ?? ''));
      } else {
        dispatch(setTitle(firstLink.label));
      }
    }
  }, [filteredLinks, dispatch]);

  const handleApplicationClick = (app) => {
    setActiveApplication(app);
    setIsOpen(false);
  };
  const filteredApplications = useMemo(() => {
    if (!finalData || searchTerm === '') return finalData;
    return finalData.filter((app) =>
      app?.applicationName?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [finalData, searchTerm]);

  useEffect(() => {
    if (finalData && finalData.length > 0 && !activeApplication) {
      setActiveApplication(finalData[0]);
    }
  }, [finalData]);

  return (
    <>
      <div
        className={`${styles.backdrop} ${isOpen ? styles.open : ''}`}
        onClick={toggleMenu}
      ></div>
      <div
        className={`${styles['menu-container']} ${isOpen ? styles.open : ''}`}
        ref={menuRef}
      >
        <div className={styles.header}>
          <h3>{appConstants.LABEL.ALL_APPLICATIONS}</h3>
          <Tooltip content="Close" left>
            <img
              src={getSvgByType(appConstants.TYPE.EXIT)}
              alt=""
              onClick={toggleMenu}
            />
          </Tooltip>
         
        </div>
        <div>
          <SearchInput
            placeholder={appConstants.SEARCH_APPLICATION}
            value={searchTerm}
            onChange={(_, value) => setSearchTerm(value)}
          />
        </div>
        <div className={styles['slider-content']}>
          {filteredApplications?.map((app, index) => (
            <div
              key={index}
              className={styles['card']}
              onClick={() => handleApplicationClick(app)}
            >
              <ApplicationTag
                isActive={activeApplication?.id === app.id}
                data={app}
              />
            </div>
          ))}
        </div>
      </div>
      <Tooltip content="All Applications Menu">
        <div
          className={`${styles['menu-button']} ${styles[variant]}`}
          onClick={toggleMenu}
        >
          <img
            src={getSvgByType(appConstants.TYPE.ALL_APPLICATIONS)}
            alt=""
            className={styles['burger-icon']}
          />
        </div>
      </Tooltip>
    </>
  );
};

export default SlideMenuApplications;
