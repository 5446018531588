import React, { useState, useEffect, useRef } from 'react';
import styles from './SideMenu.module.scss';
import { getSvgByType } from '../../../../app/helpers/forms/GetSvgByType';
import appConstants from '../../../../app/constant/constants/appConstants';
import logo from '../../../../assets/icons/logo/audLogo.svg';
import fleshSvg from '../../../../assets/icons/vectors/flesh.svg';
import redCircleSvg from '../../../../assets/icons/vectors/redCircle.svg';
import { useNavigate } from 'react-router-dom';
import NavigationLink from '../../../organisims/side-bar/factory/Factory';
import { useDispatch, useSelector } from 'react-redux';
import { setTitle } from '../../../../app/globals/pageTitleSlice';
import { getSideBarItems } from '../../../../app/helpers/permissions/getSideBarItems';

const SideMenuSlider = ({ data, isStudent }) => {
  const menuRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [isOpen, setIsOpen] = useState(false);

  const filteredLinks = isStudent ? data : getSideBarItems(user.sideBarPages);
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleNavigate = (path, label) => {
    setIsOpen(false);
    dispatch(setTitle(label ?? ''));

    setTimeout(() => {
      navigate(path);
    }, 400);
  };

  const handleLogoClick = () => {
    navigate('/');
  };
  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('no-scroll');
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.body.classList.remove('no-scroll');
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.body.classList.remove('no-scroll');
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  // this to ensure that the title of the header is correct always the first item in the sidebar data
  useEffect(() => {
    if (filteredLinks && filteredLinks.length > 0) {
      const firstLink = filteredLinks[0];
      const firstChildLink = firstLink?.childrens?.[0];

      if (firstChildLink?.label) {
        dispatch(setTitle(firstChildLink?.label ?? ''));
      } else if (firstLink?.label) {
        dispatch(setTitle(firstLink?.label ?? ''));
      }
    }
  }, [filteredLinks, dispatch]);

  return (
    <>
      <div
        className={`${styles['menu-container']} ${isOpen ? styles.open : ''}`}
        ref={menuRef}
      >
        <div className={styles['logo-container-mobile']}>
          <img
            className={styles['aud-logo']}
            src={logo}
            alt={appConstants.AUD_LOGO}
            onClick={handleLogoClick}
          />
          <button className={styles.toggleButton} onClick={toggleMenu}>
            <img src={redCircleSvg} alt="" />
            <img className={styles.flesh} src={fleshSvg} alt="" />
          </button>
        </div>

        <div className={styles.navigation}>
          {filteredLinks.map((link, index) => (
            <NavigationLink
              handleNavigate={handleNavigate}
              key={index}
              link={link}
              isBarOpen={isOpen}
            />
          ))}
        </div>
      </div>
      <img
        src={getSvgByType(appConstants.TYPE.BURGER_MENU)}
        alt=""
        className={styles['burger-icon']}
        onClick={toggleMenu}
      />
    </>
  );
};

export default SideMenuSlider;
