import React, { useEffect, useMemo, useState } from 'react';
import Select from '../../../../../../components/atoms/forms/select/select/Select';
import DateSelect from '../../../../../../components/atoms/forms/select/date/DateSelect';
import Input from '../../../../../../components/atoms/forms/input/input/Input';
import TextEditor from '../../../../../../components/atoms/forms/text-editor/TextEditor';
import Button from '../../../../../../components/atoms/forms/button/Button';
import useEmailForm from './factory/hook';
import appConstants from '../../../../../../app/constant/constants/appConstants';
import styles from '../EmailPage.module.scss';
import formstyles from './EmailTemplateForm.module.scss';
import Container from '../../../../../../components/hoc/container/Container';
import MultiAttachment from '../../../../../../components/atoms/forms/multi-attachment/MultiAttachment';
import _endpoints from '../../../../../../app/constant/endpoints/_endpoints';
import { useGetQuery } from '../../../../../../services/apiCall';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../../../../../components/molecules/table/atoms/loader/Loader';
import { getSvgByType } from '../../../../../../app/helpers/forms/GetSvgByType';

const applicationsApi = _endpoints.request.getRequestTypes;
const notificationTypeApi = _endpoints.notification.getNotificationTypesByAppId;
const getnotificationByIdApi = _endpoints.notification.getNotificationsById;
const signatureApi = _endpoints.dropdown.GetEmailSignaturesDD;

const EmailTemplateForm = () => {
  const navigate = useNavigate();
  const [hasEditedAttachments, setHasEditedAttachments] = useState(false);
  const { id } = useParams();
  const { data: notificationData } = useGetQuery(
    { api: getnotificationByIdApi, params: [id] },
    {
      refetchOnMountOrArgChange: true,
      skip: !id,
    }
  );
  const { data: eSignatureData, isLoading: isEsignatureLoading } = useGetQuery({
    api: signatureApi,
  });
  const { formik, isLoading, initialAttachments } = useEmailForm({
    data: notificationData,
    hasEditedAttachments,
  });
  const { data: applicationsData } = useGetQuery({ api: applicationsApi });
  const { data: notificationTypeData, isLoading: isNotificationLoading } =
    useGetQuery(
      {
        api: `${notificationTypeApi}=${formik.values.applicationType}`,
      },
      { skip: !formik.values.applicationType }
    );

  // generate applciation options
  const applicationOptions = applicationsData?.map((val) => ({
    value: val.id,
    label: val.applicationName,
  }));

  // generate notification types options
  const notificationTypeOptions = notificationTypeData?.map((val) => ({
    value: val.notificationTypeId,
    label: val.notificationType,
  }));
  useEffect(() => {
    if (
      JSON.stringify(initialAttachments) !==
      JSON.stringify(formik.values.Attachments)
    ) {
      setHasEditedAttachments(true);
    } else {
      setHasEditedAttachments(false);
    }
  }, [formik.values.Attachments, initialAttachments]);
  const handleBackNavigation = () => {
    navigate('/email', { state: { openEmailTemplateTab: true } });
  };
  return (
    <Container className={styles['container-class']}>
      {isLoading && <Loader backdrop />}
      <div className={formstyles.header}>
        <div className={formstyles['back-title']}>
          <img
            src={getSvgByType(appConstants.TYPE.BACKBUTTON)}
            alt=""
            onClick={handleBackNavigation}
          />
          <h1>{`${id ? 'Edit' : 'Create New'}`} Email Template</h1>
        </div>
        <Button
          variant={appConstants.VARIANT.DANGER}
          type={appConstants.TYPE.SAVE}
          label={appConstants.TYPE.SAVE}
          isLoading={isLoading}
          onClick={formik.handleSubmit}
          buttonType="submit"
        />
      </div>

      <form className={styles.form}>
        <div className={styles['input-container']}>
          <Input
            label={appConstants.LABEL.NAME}
            placeholder={appConstants.PLACEHOLDER.ENTER_EMAIL_NAME}
            type={appConstants.TYPE.EMAIL}
            name="NotificationName"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.NotificationName}
            error={
              formik.touched.NotificationName && formik.errors.NotificationName
            }
            touched={formik.touched.NotificationName}
          />
          <Select
            label={appConstants.APPLICATION}
            placeholder={appConstants.PLACEHOLDER.SELECT_APPLICATION}
            type={appConstants.TYPE.FILE}
            name="applicationType"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.applicationType}
            error={
              formik.touched.applicationType && formik.errors.applicationType
            }
            touched={formik.touched.applicationType}
            options={applicationOptions}
          />
          {/* <Select
            label={appConstants.LABEL.EMAIL_TYPE}
            placeholder={appConstants.PLACEHOLDER.EMAIL_TYPE}
            type={appConstants.TYPE.FILE}
            name="NotificationType"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.NotificationType}
            error={
              formik.touched.NotificationType && formik.errors.NotificationType
            }
            touched={formik.touched.NotificationType}
            options={notificationTypeOptions}
            disabled={formik.values.applicationType === null}
            isLoading={isNotificationLoading}
          /> */}
        </div>
        <div className={styles['input-container']}>
          <Input
            label={appConstants.LABEL.SUBJECT}
            placeholder={appConstants.PLACEHOLDER.ENTER_SUBJECT}
            type={appConstants.TYPE.SUBJECT}
            name="subject"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.subject}
            error={formik.touched.subject && formik.errors.subject}
            touched={formik.touched.subject}
          />
          <MultiAttachment
            label={appConstants.LABEL.ATTACHMENT}
            placeholder={appConstants.PLACEHOLDER.ENTER_ATTACHMENT}
            name="Attachments"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.Attachments}
            error={formik.touched.Attachments && formik.errors.Attachments}
            touched={formik.touched.Attachments}
          />
        </div>
        <TextEditor
          name="NotificationContent"
          value={formik.values.NotificationContent}
          onChange={(value) =>
            formik.setFieldValue('NotificationContent', value)
          }
          error={
            formik.touched.NotificationContent &&
            formik.errors.NotificationContent
          }
          touched={formik.touched.NotificationContent}
          isCustom
        />
        <Select
          label={appConstants.LABEL.EMAIL_SIGNATURE}
          placeholder={appConstants.PLACEHOLDER.SELECT_SIGNATURE}
          type={appConstants.TYPE.SIGNITURE}
          name="EmailSignatureId"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.EmailSignatureId}
          error={
            formik.touched.EmailSignatureId && formik.errors.EmailSignatureId
          }
          touched={formik.touched.EmailSignatureId}
          options={eSignatureData}
        />
      </form>
    </Container>
  );
};

export default EmailTemplateForm;
