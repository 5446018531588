import React, { useEffect, useState } from 'react';
import Carousel from '../../../../molecules/carousel/Carousel';
import _endpoints from '../../../../../app/constant/endpoints/_endpoints';
import Form from './factory/new-user-form/application-forms/InitialForm';
import { useGetQuery } from '../../../../../services/apiCall';
import styles from './Terms_TEMP.module.scss';
import NewTable from '../../../../molecules/new-table/NewTable';
import Container from '../../../../hoc/container/Container';
import useForm from './factory/new-user-form/hook';

const applicationsApi = _endpoints.request.getRequestTypes;
const termsApi = _endpoints.term.getTerms;

const Terms_TEMP = () => {
  const { data: applicationsData, isLoading } = useGetQuery({
    api: applicationsApi,
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [formType, setFormType] = useState('create');
  const [refetch, setRefetch] = useState(false);

  const [activeApplication, setActiveApplication] = useState({
    id: applicationsData?.id ?? 0,
    applicationName:
      applicationsData?.applicationName ?? 'academic standing appeal',
  });

  const onSuccess = () => {
    setIsModalOpen(false);
    setRefetch(!refetch);
  };

  const fetchRowData = (item, type) => {
    setRowData(item);
    setFormType(type);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setFormType('create');
  };

  const handleModal = (type) => {
    setFormType(type);
    setIsModalOpen(!isModalOpen);
    setRowData(null);
  };
  const { formik, isLoading: formLoad } = useForm({
    data: rowData,
    type: formType,
    onSuccess,
    activeApplication,
  });
  useEffect(() => {
    setActiveApplication({
      id: applicationsData?.id ?? 0,
      applicationName:
        applicationsData?.applicationName ?? 'academic standing appeal',
    });
  }, []);
  return (
    <div className={styles['terms-container']}>
      <Carousel
        key={applicationsData?.id}
        data={applicationsData}
        isLoading={isLoading}
        activeApplication={activeApplication}
        setActiveApplication={setActiveApplication}
      />

      <Container>
        <NewTable
          isTerm
          form={
            <Form
              formik={formik}
              data={rowData}
              type={formType}
              handleClose={handleCloseModal}
              isLoading={formLoad}
              activeApplication={activeApplication}
            />
          }
          api={termsApi}
          refetch={refetch}
          dependsOn={activeApplication?.id}
          actions={{
            hasEdit: {
              func: (item) => {
                fetchRowData(item, 'edit');
              },
            },
            hasCreate: {},
          }}
          hasAdd={false}
          isModalOpen={isModalOpen}
          setIsModalOpen={() => handleModal('create')}
          modalConfig={{
            buttonLabel: 'new term',
            modalTitle:
              formType === 'edit' ? `edit ${rowData?.termName}` : 'add term',
          }}
        />
      </Container>
    </div>
  );
};

export default Terms_TEMP;
