import React, { useState, useEffect, useRef } from 'react';
import styles from './DropdownButton.module.scss';
import { getSvgByType } from '../../../app/helpers/forms/GetSvgByType';
import Checkbox from '../forms/checkbox/Checkbox';
import appConstants from '../../../app/constant/constants/appConstants';
import Tooltip from '../../molecules/tootltip/Tooltip';

const DropdownButton = ({
  items,
  type,
  onClose,
  isMulty = false,
  disabled,
  handleCheckboxChange,
  tooltipContent,
}) => {
  const [isPortalOpen, setPortalOpen] = useState(false);
  const [animationClass, setAnimationClass] = useState('');
  const btnRef = useRef();
  const portalRef = useRef();

  const openPortal = () => {
    if (disabled) return;
    setPortalOpen(true);
    setAnimationClass('entering');
  };

  const closePortal = () => {
    setAnimationClass('exiting');
    setTimeout(() => {
      setPortalOpen(!isPortalOpen);
      setAnimationClass('');
      if (onClose) onClose();
    }, 300);
  };

  const handleClickOutside = (event) => {
    if (
      portalRef.current &&
      !portalRef.current.contains(event.target) &&
      !btnRef.current.contains(event.target)
    ) {
      closePortal();
    }
  };

  useEffect(() => {
    if (isPortalOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isPortalOpen]);
  return (
    <div className={styles['portal-container']}>
      <Tooltip content={tooltipContent}>
        <span
          className={styles['expend-btn']}
          onClick={openPortal}
          ref={btnRef}
        >
          <img src={getSvgByType(type)} alt="options" />
        </span>
      </Tooltip>

      {isPortalOpen && (
        <div
          ref={portalRef}
          className={`${styles['portal-content']} ${styles[animationClass]}`}
        >
          <div className={styles[type ?? '']}>
            {items?.map((item, index) => (
              <div key={index} onClick={!isMulty ? closePortal : undefined}>
                {type === appConstants.TYPE.COL_VISIBILITY ? (
                  <Checkbox
                    label={item.label}
                    ischecked={item.checked}
                    onChange={() => handleCheckboxChange(item)}
                  />
                ) : (
                  <div className={styles.btn}>
                    <img
                      src={getSvgByType(item.type)}
                      alt=""
                      className={styles.image}
                    />
                    <p>{item.label}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default DropdownButton;
