import React, { useMemo } from 'react';
import styles from './NewReasonForm.module.scss';
import { useGetQuery } from '../../../../../../../services/apiCall';
import _endpoints from '../../../../../../../app/constant/endpoints/_endpoints';
import Input from '../../../../../../../components/atoms/forms/input/input/Input';
import Select from '../../../../../../../components/atoms/forms/select/select/Select';
import Button from '../../../../../../../components/atoms/forms/button/Button';
import appConstants from '../../../../../../../app/constant/constants/appConstants';
import useForm from './hook';

const api = _endpoints.request.getRequestTypes;

const NewReasonForm = ({ data, type, handleClose, onSuccess }) => {
  const { data: applicationsData } = useGetQuery({ api });
  const { formik, isLoading } = useForm({
    data: data,
    type: type,
    onSuccess: onSuccess,
  });

  // generate application options
  const applicationOptions = applicationsData?.map((val) => ({
    value: val.id,
    label: val.applicationName,
  }));

  return (
    <form className="form" autoComplete="off" onSubmit={formik.handleSubmit}>
      <Input
        label="Reason Name"
        placeholder="Enter reason name"
        name="reason"
        type={appConstants.TYPE.FILE}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.reason}
        error={formik.touched.reason && formik.errors.reason}
        touched={formik.touched.reason}
        autoComplete="off"
      />

      <Select
        label="Application"
        placeholder="Select Application"
        name="requestType"
        type={appConstants.TYPE.FILE}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.requestType}
        error={formik.touched.requestType && formik.errors.requestType}
        touched={formik.touched.requestType}
        options={applicationOptions}
      />

      <div className="form-footer">
        <Button
          onClick={handleClose}
          label={appConstants.CANCEL}
          buttonType="button"
        />
        <Button
          label="Save"
          variant={appConstants.VARIANT.DANGER}
          type={appConstants.TYPE.CHECK}
          isLoading={isLoading}
          buttonType="submit"
        />
      </div>
    </form>
  );
};

export default NewReasonForm;
