import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useMemo, useState } from 'react';
import _endpoints from '../../../../../app/constant/endpoints/_endpoints';
import { successMessage } from '../../../../hoc/toast/Toast';
import { handleError } from '../../../../molecules/new-table/NewTable';
import { usePostMutation } from '../../../../../services/apiCall';

const apiUrl = _endpoints.reminder.postREminder;

const getValidationSchema = () =>
  Yup.object().shape({
    requestType: Yup.string().required('Application is required'),
    termId: Yup.string().required('Term is required'),
    emailTemplateId: Yup.string().required('Email Template is required'),
    schedule: Yup.string().required('schedule is required'),
  });

const useForm = ({ data, onSuccess, handleClose }) => {
  const [key, setKey] = useState(0);
  const [post, { isLoading, isSuccess }] = usePostMutation();
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const payload = {
      apiUrl,
      data: {
        ...(data && data.id ? { id: data.id } : {}),
        applicationId: values.requestType,
        reminderDays: values.schedule,
        termId: values.termId,
        templateId: values.emailTemplateId,
      },
    };
    try {
      await post(payload).unwrap();
      resetForm();
      setKey((prev) => prev + 1);
      handleClose();
      successMessage('Saved Successfully');
      if (onSuccess) onSuccess();
    } catch (error) {
      handleError(error);
    } finally {
      setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      requestType: data?.applicationId,
      termId: data?.termId || '',
      emailTemplateId: data?.templateId || '',
      schedule: data?.reminderDays || '',
    },
    validationSchema: getValidationSchema(),
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  return { isLoading, isSuccess, formik, key };
};

export default useForm;
