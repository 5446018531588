import { useFormik } from 'formik';
import * as Yup from 'yup';
import { usePostMutation } from '../../../../../services/apiCall';
import { handleError } from '../../../../molecules/table/Table';
import { useState } from 'react';
import _endpoints from '../../../../../app/constant/endpoints/_endpoints';
import { successMessage } from '../../../../hoc/toast/Toast';

const apiUrl = _endpoints.profile.postPassword;

const validationSchema = Yup.object({
  oldPassword: Yup.string().required('Old password is required'),
  newPassword: Yup.string().required('New password is required'),
  repeatPass: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
    .required('Repeat password is required'),
});

const initialValues = {
  oldPassword: '',
  newPassword: '',
  repeatPass: '',
};

const useForm = ({ onSuccess }) => {
  const [key, setKey] = useState(0);

  const [post, { isLoading, isSuccess }] = usePostMutation();
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const { oldPassword, newPassword } = values;

    let payload = {
      apiUrl,
      data: {
        oldPassword,
        newPassword,
      },
    };

    try {
      await post(payload).unwrap();
      resetForm();
      setKey((prev) => prev + 1);

      successMessage('Saved Successfully');
      onSuccess();
    } catch (error) {
      handleError(error);
    } finally {
      setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  return { isLoading, isSuccess, handleSubmit, formik, key };
};

export default useForm;
