import React from 'react';
import Modal from '../../../../hoc/modal/Modal';
import useForm from './hook';
import appConstants from '../../../../../app/constant/constants/appConstants';
import Input from '../../../../atoms/forms/input/input/Input';
import Button from '../../../../atoms/forms/button/Button';

const PasswordForm = ({ isChangePass, onClose }) => {
  const { formik, isLoading } = useForm({
    onSuccess: onClose,
  });
  return (
    <Modal isOpen={isChangePass} onClose={onClose} title="Change Password">
      <form onSubmit={formik.handleSubmit} autoComplete="off" className="form">
        <Input
          label="Current Password"
          placeholder="Enter Current Password"
          inputType="password"
          type={appConstants.TYPE.PASSWORD}
          name="oldPassword"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.oldPassword}
          error={formik.touched.oldPassword && formik.errors.oldPassword}
          touched={formik.touched.oldPassword}
        />
        <Input
          label="New Password"
          placeholder="Enter New Password"
          inputType="password"
          type={appConstants.TYPE.PASSWORD}
          name="newPassword"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.newPassword}
          error={formik.touched.newPassword && formik.errors.newPassword}
          touched={formik.touched.newPassword}
        />
        <Input
          label="Repeat New Password"
          placeholder="Enter Repeated Password"
          inputType="password"
          type={appConstants.TYPE.PASSWORD}
          name="repeatPass"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.repeatPass}
          error={formik.touched.repeatPass && formik.errors.repeatPass}
          touched={formik.touched.repeatPass}
        />
        <div className="form-footer">
          <Button
            onClick={onClose}
            label={appConstants.CANCEL}
            buttonType="button"
          />
          <Button
            label="Change Password"
            variant={appConstants.VARIANT.DANGER}
            isLoading={isLoading}
            buttonType="submit"
          />
        </div>
      </form>
    </Modal>
  );
};

export default PasswordForm;
