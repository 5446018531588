const findLink = (links) => {
  let result = null;

  links.some((link) => {
    if (link.path === location.pathname) {
      result = link;
      return true;
    }
    if (link.childrens) {
      result = findLink(link.childrens);
      if (result) return true;
    }
    return false;
  });

  return result;
};

export default findLink;
