import React, { createContext, useContext, useState, useCallback } from 'react';
import SuccessModal from '../../components/hoc/success-modal/SuccessModals';

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = useCallback(() => {
    setModalOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  return (
    <ModalContext.Provider value={{ isModalOpen, openModal, closeModal }}>
      {children}
      <SuccessModal isOpen={isModalOpen} onClose={closeModal} />
    </ModalContext.Provider>
  );
};

export const useSuccessModal = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('erro please try again later');
  }
  return context.openModal;
};
