import React from 'react';
import { ToastContainer as DefaultToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Toast.css';

const defaultToast = {
  position: 'top-right',
  autoClose: 1500,
  hideProgressBar: true,
  newestOnTop: false,
  closeOnClick: true,
  rtl: false,
  pauseOnFocusLoss: true,
  draggable: true,
  pauseOnHover: true,
  icon: false,
  theme: 'colored',
};
export const successMessage = (message) => {
  const defaultMsg = 'toast.success';

  toast.success(<Msg title={message ?? defaultMsg} success />);
};
export const errorMessage = (message) => {
  const defaultMsg = 'toast.failure';

  toast.error(<Msg title={message ?? defaultMsg} />);
};
const Msg = ({ title }) => {
  return <div className={'taost-container'}>{title}</div>;
};

const ToastContainer = () => {
  return <DefaultToastContainer {...defaultToast} />;
};

export default ToastContainer;
