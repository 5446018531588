import React, { useState } from 'react';
import styles from './Restrictions_TEMP.module.scss';
import Container from '../../../../hoc/container/Container';
import NewRestrictionForm from './factory/new-restriction-form/NewRestrictionForm';
import NewTable from '../../../../molecules/new-table/NewTable';
import _endpoints from '../../../../../app/constant/endpoints/_endpoints';
import { useNavigate } from 'react-router-dom';

const Restrictions_TEMP = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [formType, setFormType] = useState('create');
  const [refetch, setRefetch] = useState(false);

  const onSuccess = () => {
    setIsModalOpen(false);
    setRefetch(!refetch);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setFormType('create');
  };
  const handleNavigate = (item) => {
    navigate(`/restrictions-details/${item?.id}`);
  };
  return (
    <div className={styles['restrictions']}>
      <Container>
        <NewTable
          filters={{ hasSearch: true }}
          form={
            <NewRestrictionForm
              data={rowData}
              type={formType}
              handleClose={handleCloseModal}
              onSuccess={onSuccess}
            />
          }
          actions={{
            hasDelete: true,
            hasCreate: {},
          }}
          deleteApi={_endpoints.restrictions.delete}
          refetch={refetch}
          onRowClick={(item) => handleNavigate(item)}
          api={_endpoints.restrictions.getRestrictionsTable}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          modalConfig={{
            buttonLabel: 'new restriction',
            modalTitle:
              formType === 'edit' ? 'edit restriction' : 'add restriction',
          }}
        />
      </Container>
    </div>
  );
};

export default Restrictions_TEMP;
