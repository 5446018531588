import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useState } from 'react';
import _endpoints from '../../../../../../../../../app/constant/endpoints/_endpoints';
import { usePostMutation } from '../../../../../../../../../services/apiCall';
import { handleError } from '../../../../../../../../molecules/new-table/NewTable';
import { successMessage } from '../../../../../../../../hoc/toast/Toast';

const apiUrl = _endpoints.restrictions.postRequestDetail;

const validationSchema = Yup.object({
  detailId: Yup.string().required('field is required'),
});

const useForm = ({ onSuccess, data }) => {
  const [key, setKey] = useState(0);
  const [post, { isLoading, isSuccess }] = usePostMutation();
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      values.detailId = values.detailId + '';
      await post({ apiUrl, data: values }).unwrap();
      resetForm();
      setKey((prev) => prev + 1);
    successMessage('Saved Successfully');
      if (onSuccess) onSuccess();
    } catch (error) {
      handleError();
    } finally {
      setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      restrictionDefinition: data.id + '',
      detailId: '',
      detailText: '',
    },
    validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  return { isLoading, isSuccess, formik, key };
};

export default useForm;
