import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styles from './Email_TEMP.module.scss';
import { TabBox, TabsBoxs } from '../../../../molecules/tab-box/TabBox';
import appConstants from '../../../../../app/constant/constants/appConstants';
import EmailTab from '../../../../organisims/email-tabs/email/EmailTab';
import EmailTemplate from '../../../../organisims/email-tabs/email-template/EmailTemplate';
import EmailReminder from '../../../../organisims/email-tabs/EmailReminder/EmailReminder';

const Email_TEMP = () => {
  const location = useLocation();
  const [defaultActiveTab, setDefaultActiveTab] = useState(0);

  useEffect(() => {
    if (location.state?.openEmailTemplateTab) {
      setDefaultActiveTab(1);
    } else setDefaultActiveTab(0);
  }, [location.state]);

  return (
    <div className={styles['email-container']}>
      <TabsBoxs defaultActiveTab={defaultActiveTab}>
        <TabBox label={appConstants.LABEL.EMAIL_TEMPLATE}>
          <EmailTemplate />
        </TabBox>
        <TabBox label="Reminder">
          <EmailReminder />
        </TabBox>
        <TabBox label={appConstants.LABEL.EMAIL}>
          <EmailTab />
        </TabBox>
      </TabsBoxs>
    </div>
  );
};

export default Email_TEMP;
