import React, { useState } from 'react';
import Select from '../../../../../../../atoms/forms/select/select/Select';
import TextArea from '../../../../../../../atoms/forms/text-area/TextArea';
import useForm from './hook';
import Container from '../../../../../../../hoc/container/Container';
import Button from '../../../../../../../atoms/forms/button/Button';
import _endpoints from '../../../../../../../../app/constant/endpoints/_endpoints';
import { useGetQuery } from '../../../../../../../../services/apiCall';
import styles from './CourseWithdrawal.module.scss';
import SuccessModal from '../../../../../../../hoc/success-modal/SuccessModals';

const reasonsApi = _endpoints.reason.getReasonsDropdown;
const CourseWithdrawalForm = ({ selectedCourse, termId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  const { data: reasonData, isLoading: appLoading } = useGetQuery({
    api: reasonsApi,
  });

  const onSuccess = () => {
    setIsSuccessModalOpen(true);
  };
  const { formik, isLoading } = useForm({
    selectedCourse: selectedCourse,
    termId: termId,
    onSuccess: onSuccess,
  });

  return (
    <>
      <Container>
        <form className={styles['course-actions']} action={formik.handleSubmit}>
          <Select
            label="Reason for Withdrawal"
            name="withdrawalReasonId"
            value={formik.values.withdrawalReasonId}
            onChange={formik.handleChange}
            placeholder="Select reason"
            error={formik.errors.withdrawalReasonId}
            touched={formik.touched.withdrawalReasonId}
            isLoading={appLoading}
            options={reasonData}
          />
          <TextArea
            label="Other Reason"
            name="reason"
            value={formik.values.reason}
            onChange={formik.handleChange}
            placeholder="Specify reason"
            error={formik.errors.reason}
            touched={formik.touched.reason}
          />
          <Button
            label="Submit"
            disabled={isLoading}
            buttonType="submit"
            onClick={formik.handleSubmit}
            isLoading={isLoading}
          />
        </form>
      </Container>
      <SuccessModal
        isOpen={isSuccessModalOpen}
        isSuccess
        text={'Submitted Successfully'}
        timeout={1000}
        subText="Your request needs to be verify by registrar"
      />
    </>
  );
};

export default CourseWithdrawalForm;
