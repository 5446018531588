import React, { useState } from 'react';
import NewUserForm from '../factory/new-user-form/NewUserForm';
import _endpoints from '../../../../../../app/constant/endpoints/_endpoints';
import NewTable from '../../../../../molecules/new-table/NewTable';
import Modal from '../../../../../hoc/modal/Modal';
import UserRoleForm from '../factory/assign-a-user-role/UserRoleForm';
import useFetchPermissions from '../../../../../../app/helpers/permissions/getPermissionsCall';

const UsersTab = () => {
  useFetchPermissions();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAssignFormOpen, setIsAssignFormOpen] = useState(false);
  const [RoleId, setRoleId] = useState(false);

  const [rowData, setRowData] = useState(null);
  const [formType, setFormType] = useState('create');
  const [refetch, setRefetch] = useState(false);

  const onSuccess = () => {
    setIsModalOpen(false);
    setIsAssignFormOpen(false);
    setRefetch(!refetch);
  };

  const fetchRowData = (item, type) => {
    setRowData(item);
    setFormType(type);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setFormType('create');
  };
  const handleCloseModalAssign = () => {
    setIsAssignFormOpen(false);
    setFormType('create');
  };
  const handleModal = (type) => {
    setFormType(type);
    setIsModalOpen(!isModalOpen);
    setRowData(null);
  };
  const handleAssignRoleForm = (item, type) => {
    setIsAssignFormOpen(true);
    setRoleId(item);
    setFormType(type);
  };
  return (
    <>
      <NewTable
        form={
          <NewUserForm
            data={rowData}
            type={formType}
            handleClose={handleCloseModal}
            onSuccess={onSuccess}
          />
        }
        api={_endpoints.users.getUsers}
        refetch={refetch}
        actions={{
          hasEdit: {
            permisionKey: 'EDIT_USER',
            func: (item) => {
              fetchRowData(item, 'edit');
            },
          },
          hasRole: {
            func: (item) => {
              handleAssignRoleForm(item, 'edit');
            },
            permisionKey: 'ASSIGN_ROLE',
          },
          // hasDelete: {
          //   hasDelete: 'DELETE_USER',
          // },
          hasCreate: {
            permisionKey: 'CREATE_USER',
          },
        }}
        rowKey="userId"
        isModalOpen={isModalOpen}
        setIsModalOpen={() => handleModal('create')}
        modalConfig={{
          buttonLabel: 'new user',
          modalTitle: formType === 'edit' ? 'edit user' : 'add User',
        }}
      />
      <Modal
        title="assign role"
        onClose={() => setIsAssignFormOpen(false)}
        isOpen={isAssignFormOpen}
      >
        <UserRoleForm
          handleClose={handleCloseModalAssign}
          userId={RoleId}
          onSuccess={onSuccess}
        />
      </Modal>
    </>
  );
};

export default UsersTab;
