import React from 'react';
import styles from './MobileHeader.module.scss';
import ProfileDropdown from '../../../atoms/profile-dropdown/ProfileDropdown';
import SideMenuSlider from '../side-menu-slider/SideMenuSlider';

const MobileHeader = ({ data, title, isStudent }) => {
  return (
    <div className={styles['students-header-mobile']}>
      <div className={styles.header}>
        <SideMenuSlider data={data} isStudent={isStudent} />
        <div className={styles.title}>
          <p>{title?.title}</p>
        </div>
        <div className={styles.profile}>
          <ProfileDropdown />
        </div>
      </div>
    </div>
  );
};

export default MobileHeader;
