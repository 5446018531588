import React, { useState } from 'react';
import styles from '../Components.module.scss';
import appConstants from '../../../../../../app/constant/constants/appConstants';
import _endpoints from '../../../../../../app/constant/endpoints/_endpoints';
import { errorMessage } from '../../../../../../components/hoc/toast/Toast';
import { useFormik } from 'formik';
import Container from '../../../../../../components/hoc/container/Container';
import SimpleTable from '../../../../../../components/molecules/simple-table/SimpleTable';
import TextArea from '../../../../../../components/atoms/forms/text-area/TextArea';
import Button from '../../../../../../components/atoms/forms/button/Button';
import SuccessModal from '../../../../../../components/hoc/success-modal/SuccessModals';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { usePostMutation } from '../../../../../../services/apiCall';
import objectToFormData from '../../../../../../app/helpers/forms/objectToFormData';

const ProvostForm = ({ onSubmit, data }) => {
  const navigate = useNavigate();
  const [post] = usePostMutation();
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isWarningModalOpen, setWarningModalOpen] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const [isRejecting, setIsRejecting] = useState(false);
  const [actionType, setActionType] = useState('');

  const request = data?.request;

  const formik = useFormik({
    initialValues: {
      comment: '',
    },
    validationSchema: Yup.object({
      comment: Yup.string().required('Required'),
    }),
    onSubmit: (values) => {
      onSubmit(values);
    },
  });
  const handleApprove = async () => {
    setIsApproving(true);
    setActionType('approve');

    const payload = {
      RequestId: data?.request.id,
      Comments: formik.values.comment,
    };

    const formData = objectToFormData(payload);

    try {
      await post({
        apiUrl: _endpoints.approver.approveRequest,
        data: formData,
        type: 'formData',
      }).unwrap();
      setIsSuccessModalOpen(true);
      setTimeout(() => {
        navigate(-1);
      }, 1000);
    } catch (error) {
      errorMessage('Something went wrong!');
    } finally {
      setIsApproving(false);
    }
  };

  const handleRejection = async () => {
    setIsRejecting(true);
    setActionType('reject');

    const payload = {
      requestId: data?.request.id,
      comments: formik.values.comment,
    };
    try {
      await post({
        apiUrl: _endpoints.approver.rejectRequest,
        data: payload,
      }).unwrap();
      setIsSuccessModalOpen(true);
      setWarningModalOpen(false);
      setTimeout(() => {
        navigate(-1);
      }, 1000);
    } catch (error) {
      errorMessage('Something went wrong!');
    } finally {
      setIsRejecting(false);
    }
  };

  const closeModal = () => {
    setIsSuccessModalOpen(false);
    setWarningModalOpen(false);
  };

  return (
    <div className={styles['requests-container']}>
      <Container>
        <form onSubmit={formik.handleSubmit} className={styles.form}>
          <TextArea
            label={appConstants.LABEL.COMMENT}
            placeholder={appConstants.PLACEHOLDER.ENTER_COMMENT}
            type={appConstants.TYPE.NOTE}
            name="comment"
            value={formik.values.comment}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.comment && formik.errors.comment}
          />

          <Button
            label="Process Request"
            type={appConstants.TYPE.CHECK}
            variant={appConstants.VARIANT.DEFAULT}
            onClick={handleApprove}
            isLoading={isApproving}
          />

          <SuccessModal
            isOpen={isSuccessModalOpen}
            isSuccess
            text={
              actionType === 'approve' ? 'Request Approved' : 'Request Rejected'
            }
            timeout={1000}
            subText={
              actionType === 'approve'
                ? 'Request has been reviewed and given approval.'
                : 'Request has been reviewed and rejected.'
            }
          />
          <SuccessModal
            isOpen={isWarningModalOpen}
            text="Reject Request"
            subText="Are you sure you want to reject this request?"
            onClose={closeModal}
            onSubmit={handleRejection}
            onApproveButton={{ text: 'Reject' }}
            isLoading={isRejecting}
          />
        </form>
      </Container>
    </div>
  );
};
export default ProvostForm;
