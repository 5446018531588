import React, { useEffect, useState } from 'react';
import styles from './TermsConditions.module.scss';
import Container from '../../../../../../components/hoc/container/Container';
import _endpoints from '../../../../../../app/constant/endpoints/_endpoints';
import NewTermsAndConditionsForm from './new-termsAndConditions-form/NewTermsAndConditionsForm';
import NewTable from '../../../../../../components/molecules/new-table/NewTable';
import useForm from './new-termsAndConditions-form/hook';

const TermsConditions = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [formType, setFormType] = useState('create');
  const [refetch, setRefetch] = useState(false);

  const onSuccess = () => {
    setIsModalOpen(false);
    setRefetch(!refetch);
  };

  const fetchRowData = (item, type) => {
    setRowData(item);
    setFormType(type);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setFormType('create');
  };

  const handleModal = (type) => {
    setFormType(type);
    setIsModalOpen(!isModalOpen);
    setRowData(null);
  };
  const { formik, isLoading } = useForm({
    data: rowData,
    type: formType,
    onSuccess: onSuccess,
  });

  useEffect(() => {
    formik.resetForm();
  }, [isModalOpen]);

  return (
    <Container className={styles['terms-container']}>
      <NewTable
        headerTitle
        title="Terms and Conditions"
        form={
          <NewTermsAndConditionsForm
            formik={formik}
            data={rowData}
            type={formType}
            onClose={handleCloseModal}
            isLoading={isLoading}
          />
        }
        hasAdd={false}
        // deleteApi={_endpoints.settings.deleteTermsAndConditions}
        api={_endpoints.settings.getTermsAndConditions}
        actions={{
          hasEdit: {
            func: (item) => fetchRowData(item, 'edit'),
          },
          // hasDelete: true,
          hasCreate: {},
        }}
        refetch={refetch}
        isModalOpen={isModalOpen}
        setIsModalOpen={() => handleModal('create')}
        modalConfig={{
          buttonLabel: 'new terms and conditions',
          modalTitle:
            formType === 'edit'
              ? 'edit terms and conditions'
              : 'add terms and conditions',
          classname: styles.modal,
        }}
      />
    </Container>
  );
};

export default TermsConditions;
