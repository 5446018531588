import React from 'react';
import { Outlet } from 'react-router-dom';
import styles from './RegistrarBodyLayout.module.scss';
import RegistrarHeader from '../header/RegistrarHeader';
import Sidebar from '../../../organisims/side-bar/Sidebar';
import { useResponsive } from '../../../../assets/styles/breakpoints/breakpoint';

const RegistrarBodyLayout = () => {
  const { isTablet } = useResponsive();

  return (
    <div className={styles['app-body']}>
      {!isTablet ? <Sidebar /> : null}
      {!isTablet ? (
        <div className={styles['layout']}>
          <RegistrarHeader />
          <div className={styles['page']}>
            <Outlet />
          </div>
        </div>
      ) : null}
      {isTablet ? (
        <div className={styles['mobile-layout']}>
          <RegistrarHeader />
          <div className={styles['page']}>
            <Outlet />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default RegistrarBodyLayout;
