import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import _endpoints from '../app/constant/endpoints/_endpoints';

// Set up the base query with optional header preparation
const baseQuery = fetchBaseQuery({
  baseUrl: _endpoints.baseUrl,
  prepareHeaders: async (headers) => {
    // Content Security Policy (CSP) - Controls the resources the browser is allowed to load
    headers.set(
      'Content-Security-Policy',
      'default-src "self"; script-src "self" ; object-src "none"; frame-ancestors "none";'
    );

    // X-Frame-Options - Prevents the site from being embedded in iframes
    headers.set('X-Frame-Options', 'DENY');

    // X-Content-Type-Options - Prevents MIME type sniffing
    headers.set('X-Content-Type-Options', 'nosniff');

    // Referrer-Policy - Controls how much referrer information should be included with requests
    headers.set('Referrer-Policy', 'no-referrer');

    // Permissions-Policy - Controls which features and APIs can be used in the browser
    headers.set(
      'Permissions-Policy',
      'geolocation=(self), microphone=(), camera=(), autoplay=()'
    );

    // Strict-Transport-Security (HSTS) - Enforces secure (HTTPS) connections to the server
    headers.set(
      'Strict-Transport-Security',
      'max-age=31536000; includeSubDomains; preload'
    );

    // X-XSS-Protection - Enables the cross-site scripting filter built into most browsers
    headers.set('X-XSS-Protection', '1; mode=block');

    // Expect-CT - Ensures that any misissued certificates are quickly discovered
    headers.set('Expect-CT', 'max-age=86400, enforce');

    // Cache-Control - Controls how documents are cached, stored, and revalidated by browsers
    headers.set(
      'Cache-Control',
      'no-store, no-cache, must-revalidate, proxy-revalidate'
    );

    // CORP - Only allow resources to be loaded by the same origin for privacy and security.
    headers.set('Cross-Origin-Resource-Policy', 'same-origin');

    // COEP - Require resources to be explicitly approved for cross-origin embedding.
    headers.set('Cross-Origin-Embedder-Policy', 'require-corp');

    // COOP - Isolate your site’s browsing context to prevent cross-origin attacks.
    headers.set('Cross-Origin-Opener-Policy', 'same-origin');

    return headers;
  },
  mode: 'cors',
});

// Define a service using a base URL and expected endpoints
export const apiSlice = createApi({
  // Use the base query defined above
  baseQuery: baseQuery,
  // Define endpoints here (empty for now)
  endpoints: () => ({}),
  // Automatically refetch data when the network reconnects
  refetchOnReconnect: true,
  // Automatically refetch data when the window regains focus
  refetchOnFocus: true,
});

// Middleware t o log errors from RTK Query
export const rtkQueryErrorLogger = () => (next) => (action) => {
  // Pass the action to the next middleware or reducer
  return next(action);
};
