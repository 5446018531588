import React, { useEffect, useState } from 'react';
import styles from './EmailTab.module.scss';
import { useGetQuery } from '../../../../services/apiCall';
import _endpoints from '../../../../app/constant/endpoints/_endpoints';
import SearchInput from '../../../atoms/forms/input/searchInput/SearchInput';
import { EmailNotificationList } from '../../../atoms/email-notification-card/EmailNotificationCard';
import New_EmailForm from './email-form/New_EmailForm';
import { getSvgByType } from '../../../../app/helpers/forms/GetSvgByType';
import Paginator from '../../../molecules/paginator/Paginator';
import appConstants from '../../../../app/constant/constants/appConstants';
import Title from '../../../atoms/title/Title';
import Modal from '../../../hoc/modal/Modal';
import FilterEmailForm from './filter-form/FilterEmailForm';
import Tooltip from '../../../molecules/tootltip/Tooltip';

const api = _endpoints.email.getEmails;
const truncateFileName = (name) => {
  return name.length > 20 ? `${name.slice(0, 10)}...` : name;
};
const EmailTab = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [searchBy, setSearchBy] = useState(0);
  const [filter, setFilter] = useState({
    isFilter: false,
    term: '',
    type: '',
    application: '',
  });
  const itemsPerPage = 10;

  const { data, isLoading, refetch } = useGetQuery({
    api: api,
    params: [
      `/${currentPage}/${itemsPerPage}${
        searchBy ? `?SearchBy=${searchBy}` : ''
      }`,
    ],
  });

  const totalPages = Math.ceil(data?.totalCount / itemsPerPage);

  const onPageChange = (newPage) => {
    setCurrentPage(newPage);
    refetch();
  };

  const [notification, setNotification] = useState();

  useEffect(() => {
    setNotification(data?.items[0]);
  }, [data]);

  const handleCloseFilter = () => {
    setFilter((prev) => ({
      isFilter: false,
      term: '',
      type: '',
      application: '',
    }));
  };

  useEffect(() => {
    refetch();
  }, []);
  return (
    <>
      <div className={styles['email-tab-container']}>
        <div className={styles['email-nav']}>
          <div className={styles['header-container']}>
            <div className={styles['header']}>
              <SearchInput
                placeholder="Search..."
                onChange={(_, value) => setSearchBy(value)}
              />
              <div className={styles.filters}>
                <Tooltip content="Filter">
                  <img
                    src={getSvgByType(appConstants.TYPE.FILTER)}
                    className={styles['filter-btn']}
                    onClick={() =>
                      setFilter((prev) => ({ ...prev, isFilter: true }))
                    }
                  />
                </Tooltip>

                <New_EmailForm onSuccess={refetch} />
              </div>
            </div>
            <EmailNotificationList
              data={data}
              getNotification={setNotification}
              isLoading={isLoading}
            />
          </div>

          <Paginator
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={onPageChange}
          />
        </div>
        <div className={styles['email-content-diplay']}>
          <div className={styles['content-header']}>
            <div className={styles['headings']}>
              <div className={styles['title-container']}>
                <p className={styles['title']}>{notification?.subject}</p>
              </div>
              <div className={styles['profile']}>
                <img
                  src={getSvgByType(appConstants.TYPE.PROFILE_FEMALE)}
                  alt=""
                />
                <div className={styles['profile-info']}>
                  <p className={styles['from']}>{notification?.fromEmail}</p>
                  <p className={styles['to']}>
                    <span>To : </span>
                    {notification?.toEmail}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className={styles['content-email']}
            dangerouslySetInnerHTML={{ __html: notification?.emailContent }}
          />
          <div className={styles.attachments}>
            <Title text="Attachments" variant="sm-bold-black" />
            <div className={styles.attachmentsCont}>
              {notification?.attachments.length > 0
                ? notification?.attachments?.map((attach) => {
                    return (
                      <div
                        key={attach.fileName}
                        className={styles.attachmentsSubCont}
                      >
                        <img
                          src={getSvgByType(
                            appConstants.TYPE.ATTACHMENTS_PAPER
                          )}
                          alt=""
                        />
                        <p>{truncateFileName(attach?.fileName)}</p>
                      </div>
                    );
                  })
                : 'No attachments'}
            </div>
          </div>
        </div>
      </div>
      <Modal
        title="Filter By"
        isOpen={filter.isFilter}
        onClose={handleCloseFilter}
      >
        <FilterEmailForm
          setFilter={setFilter}
          filter={filter}
          onClose={handleCloseFilter}
        />
      </Modal>
    </>
  );
};

export default EmailTab;
