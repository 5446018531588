import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { successMessage } from '../../../../../../../hoc/toast/Toast';
import { usePostMutation } from '../../../../../../../../services/apiCall';
import _endpoints from '../../../../../../../../app/constant/endpoints/_endpoints';
import { handleError } from '../../../../../../../molecules/new-table/NewTable';
import objectToFormData from '../../../../../../../../app/helpers/forms/objectToFormData';
import { reRenderHeader } from '../../../../../../../../app/globals/storeSlices/userSlice';
import { useDispatch } from 'react-redux';

const validationSchema = Yup.object().shape({
  MetInstructor: Yup.string().required('Please select an option'),
  Comments: Yup.string().required('Comments is required'),
  SupportingDocuments: Yup.array()
    .min(1, 'At least one supporting document is required')
    .of(Yup.mixed()),
  Reason: Yup.number().when('MetInstructor', {
    is: (MetInstructor) => MetInstructor == 'no',
    then: (schema) => schema.required('Reason is required'),
    otherwise: (schema) => schema.notRequired(),
  }),
});

const useGradeAppealForm = ({ selectedCourse, termId, onSuccess }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [postAppeal, { isLoading, isSuccess }] = usePostMutation();

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const formData = new FormData();
      formData.append('CourseId', selectedCourse?.courseId || '');
      formData.append('SectionId', selectedCourse?.section?.id || '');
      formData.append('TermId', termId);
      if (values.Reason) formData.append('Reason', values.Reason);
      formData.append('Comments', values.Comments);

      formData.append(
        'MetInstructor',
        values.MetInstructor == 'no' ? false : true
      );
      values.SupportingDocuments.forEach((file, index) => {
        formData.append('SupportingDocuments', file);
      });

      const payload = {
        apiUrl: _endpoints.application.gradeAppeal,
        data: formData,
        type: 'formData',
      };

      await postAppeal(payload).unwrap();
      resetForm();
      onSuccess();
      dispatch(reRenderHeader());
      setTimeout(() => {
        navigate(-1);
      }, 2000);
    } catch (error) {
      // handleError(error);
    } finally {
      setSubmitting(false);
    }
  };

  const initialValues = {
    MetInstructor: 'yes',
    Comments: '',
    SupportingDocuments: [],
    Reason: '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });
  return { formik, isLoading, isSuccess };
};

export default useGradeAppealForm;
