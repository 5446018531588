import React, { useEffect, useMemo, useState } from 'react';
import Button from '../../../../../../atoms/forms/button/Button';
import Input from '../../../../../../atoms/forms/input/input/Input';
import Select from '../../../../../../atoms/forms/select/select/Select';
import styles from './NewApproverForm.module.scss';
import appConstants from '../../../../../../../app/constant/constants/appConstants';
import _endpoints from '../../../../../../../app/constant/endpoints/_endpoints';
import { useGetQuery } from '../../../../../../../services/apiCall';
import useForm from './hook';
import { getSvgByType } from '../../../../../../../app/helpers/forms/GetSvgByType';

const api = _endpoints.roles.getUnPagedRoles;
const emailTemplateapi = _endpoints.notification.GetUnPagedNotifications;
const statusapi = _endpoints.dropdown.getStatus;

const NewApproverForm = ({
  data,
  type,
  handleClose,
  activeApplication,
  subflow,
  onSuccess,
}) => {
  const { data: roles } = useGetQuery({ api });
  const { data: notifications } = useGetQuery({ api: emailTemplateapi });
  const { data: status } = useGetQuery({ api: statusapi });
  const { formik, isLoading: isPostLoading } = useForm({
    data: data,
    type: type,
    onSuccess: onSuccess,
    activeApplication: activeApplication,
    subflow: subflow,
  });

  const handleFormSubmit = (event) => {
    event.preventDefault();
    formik.handleSubmit();
  };

  // Generate roles options
  const roleOptions = useMemo(
    () =>
      roles?.map((val) => ({
        value: val.roleId,
        label: val.roleName,
      })),
    [roles]
  );
  // Generate notification options
  const notificationsOptions = useMemo(
    () =>
      notifications?.map((val) => ({
        value: val.value,
        label: val.label,
      })),
    [notifications]
  );
  // Generate status options
  const statusOptions = useMemo(
    () =>
      status?.map((val) => ({
        value: val.statusId,
        label: val.statusText,
      })),
    [status]
  );
  useEffect(() => {
    if (type === 'create') {
      formik.resetForm();
    }
  }, [type]);

  return (
    <form
      className={styles['form']}
      autoComplete="off"
      onSubmit={formik.handleSubmit}
    >
      <div className={styles.section}>
        {type == 'edit' && (
          <Input
            label={appConstants.LABEL.APPROVER_SEQUENCE}
            placeholder={appConstants.PLACEHOLDER.ENTER_SEQUENCE}
            type={appConstants.TYPE.FILE}
            name="seq"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.seq}
            error={formik.touched.seq && formik.errors.seq}
            touched={formik.touched.seq}
            inputType="number"
            disabled
          />
        )}

        <Select
          label={appConstants.LABEL.APPROVER_ROLE}
          type={appConstants.TYPE.ROLE}
          placeholder={appConstants.PLACEHOLDER.SELECT_APPROVER}
          name="roleId"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.roleId}
          error={formik.touched.roleId && formik.errors.roleId}
          touched={formik.touched.roleId}
          options={roleOptions}
        />

        <Select
          label="status"
          type={appConstants.TYPE.ROLE}
          placeholder="select status"
          name="activeStatus"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.activeStatus}
          error={formik.touched.activeStatus && formik.errors.activeStatus}
          touched={formik.touched.activeStatus}
          options={statusOptions}
        />
      </div>
      <div className={styles.section}>
        <p className={styles.title}>On Approval</p>
        <Input
          label={appConstants.LOG}
          type={appConstants.TYPE.REQUEST_LOG}
          placeholder={appConstants.ENTER_LOG_TEXT}
          name="approvedMessage"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.approvedMessage}
          error={
            formik.touched.approvedMessage && formik.errors.approvedMessage
          }
          touched={formik.touched.approvedMessage}
        />
        <div className={styles['inner-section']}>
          <Select
            label={appConstants.EMAIL_STUDENT}
            type={appConstants.TYPE.EMAIL}
            placeholder={appConstants.SELECT_TEMPLATE}
            name="onApproval_StudentTemplate"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.onApproval_StudentTemplate}
            error={
              formik.touched.onApproval_StudentTemplate &&
              formik.errors.onApproval_StudentTemplate
            }
            touched={formik.touched.onApproval_StudentTemplate}
            options={notificationsOptions}
          />
          <Select
            label={appConstants.NOTIFY_NEXT_APPROVER}
            type={appConstants.TYPE.EMAIL}
            placeholder={appConstants.SELECT_TEMPLATE}
            name="onApproval_NextStepTemplate"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.onApproval_NextStepTemplate}
            error={
              formik.touched.onApproval_NextStepTemplate &&
              formik.errors.onApproval_NextStepTemplate
            }
            touched={formik.touched.onApproval_NextStepTemplate}
            options={notificationsOptions}
          />
        </div>
      </div>
      <p className={styles.title}>On Rejection</p>
      <Input
        label={appConstants.LOG}
        type={appConstants.TYPE.REQUEST_LOG}
        placeholder={appConstants.ENTER_LOG_TEXT}
        name="rejectedMessage"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.rejectedMessage}
        error={formik.touched.rejectedMessage && formik.errors.rejectedMessage}
        touched={formik.touched.rejectedMessage}
      />
      <div className={styles['inner-section']}>
        <Select
          label={appConstants.EMAIL_STUDENT}
          type={appConstants.TYPE.EMAIL}
          placeholder={appConstants.SELECT_TEMPLATE}
          name="onRejection_StudentTemplate"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.onRejection_StudentTemplate}
          error={
            formik.touched.onRejection_StudentTemplate &&
            formik.errors.onRejection_StudentTemplate
          }
          touched={formik.touched.onRejection_StudentTemplate}
          options={notificationsOptions}
        />
        <Select
          label={appConstants.NOTIFY_ALL_PREVIOUS_APPROVERS}
          type={appConstants.TYPE.EMAIL}
          placeholder={appConstants.SELECT_TEMPLATE}
          name="onRejection_NotifyTemplate"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.onRejection_NotifyTemplate}
          error={
            formik.touched.onRejection_NotifyTemplate &&
            formik.errors.onRejection_NotifyTemplate
          }
          touched={formik.touched.onRejection_NotifyTemplate}
          options={notificationsOptions}
        />
      </div>
      <div className="form-footer">
        <Button onClick={handleClose} label={appConstants.CANCEL} />
        <Button
          onClick={handleFormSubmit}
          label="Save"
          variant={appConstants.VARIANT.DANGER}
          type={appConstants.TYPE.CHECK}
          isLoading={isPostLoading}
          buttonType="button"
        />
      </div>
    </form>
  );
};

export default NewApproverForm;
