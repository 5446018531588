import React from 'react';
import styles from './EmailReceived.module.scss';
import Container from '../../../../../../../components/hoc/container/Container';

const EmailsReceivedList = ({ data, selectedEmail, setSelectedEmail }) => {
  return (
    <div className={styles['emailListCont']}>
      {data?.map((item, index) => {
        const isActive = item.id === selectedEmail?.id;
        return (
          <div
            key={item?.id}
            className={`${styles['received_List']} ${
              isActive ? styles['active'] : ''
            }`}
            onClick={() => setSelectedEmail(item)}
          >
            <div
              className={`${styles['leftBorder']} ${
                isActive ? styles['activeLeftBorder'] : ''
              }`}
            >
              <p></p>
            </div>
            <div className={styles['List']}>
              <h2>{item?.subject}</h2>
              <h3>{item.termName}</h3>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default EmailsReceivedList;
