import React, { useState, useRef } from 'react';
import Button from '../button/Button';
import styles from './UploadFile.module.scss';
import appConstants from '../../../../app/constant/constants/appConstants';
import Title from '../../title/Title';
import { getSvgByType } from '../../../../app/helpers/forms/GetSvgByType';

const MultipleFileUpload = ({
  title,
  subTitle,
  name,
  value = [],
  onChange,
  error,
  touched,
  maxSize = 5000000, 
}) => {
  const [uploadedFiles, setUploadedFiles] = useState(value);
  const [sizeError, setSizeError] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileUpload = (event) => {
    const files = event.target.files ? Array.from(event.target.files) : [];
    const validFiles = [];
    let errorMsg = null;

    files.forEach((file) => {
      if (file.size <= maxSize) {
        validFiles.push(file);
      } else {
        errorMsg = `File "${file.name}" exceeds the maximum size of ${maxSize / 1000000}MB.`;
      }
    });

    if (errorMsg) setSizeError(errorMsg);

    const newFiles = [...uploadedFiles, ...validFiles];
    setUploadedFiles(newFiles);
    onChange?.({
      target: {
        name,
        value: newFiles,
      },
    });
  };

  const handleOnClick = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };

  const handleFileRemove = (index) => {
    const updatedFiles = uploadedFiles.filter((_, i) => i !== index);
    setUploadedFiles(updatedFiles);
    onChange?.({
      target: {
        name,
        value: updatedFiles,
      },
    });
  };

  const truncateFileName = (name) => {
    return name.length > 15 ? `${name.slice(0, 10)}...` : name;
  };

  return (
    <>
      <div className={styles['actions-container']}>
        <div className={styles['text-wrapper']}>
          <Title text={title} variant="sm-bold-black" />
          <Title text={subTitle} variant="sm-blue" />
          {uploadedFiles.length > 0 && (
            <div className={styles['file-list']}>
              {uploadedFiles.map((file, index) => (
                <div key={index} className={styles['files-name']}>
                  <div className={styles.name}>
                    <img src={getSvgByType('fileUpload')} alt="File Icon" />
                    {truncateFileName(file.name)}
                  </div>
                  <img
                    className={styles.remove}
                    src={getSvgByType('greyX')}
                    alt="Remove File"
                    onClick={() => handleFileRemove(index)}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
        <div className={styles['buttons-wrapper']}>
          <Button
            label={appConstants.LABEL.UPLOAD_FILE}
            type={appConstants.TYPE.ATTACHMENTS}
            variant={appConstants.VARIANT.DEFAULT}
            onClick={(e) => handleOnClick(e)}
          />
          <input
            ref={fileInputRef}
            name={name}
            type="file"
            multiple
            onChange={handleFileUpload}
            style={{ display: 'none' }}
          />
        </div>
      </div>
      {sizeError && <div className={styles['error-message']}>{sizeError}</div>}
      {error&& <div className={styles['error-message']}>{error}</div>}
    </>
  );
};

export default MultipleFileUpload;
