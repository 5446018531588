import React from 'react';
import { Route } from 'react-router-dom';
import { routes } from '../../app/constant/routes/Routes';
import CourseWithdrawal from '../../features/authenticated/students/applications/Course-withdrawal/CourseWithdrawals';
import CourseIncomplete from '../../features/authenticated/students/applications/course-incomplete/CourseIncomplete';
import CourseCommon from '../../features/authenticated/students/applications/course-common/CourseCommon';
import AcademyStandingAppeal from '../../features/authenticated/students/applications/academy-standing-apeal/AcademyStandingApeal';
import GradeAppeal from '../../features/authenticated/students/applications/grade-appeal/GradeAppeal';
import appConstants from '../../app/constant/constants/appConstants';
import ProfilePage from '../../features/authenticated/profile/ProfilePage';

export const commonRoutes = [
  <Route
    key="course-withdrawal"
    path={routes.STUDENTS.COURSE_WITHDRAWAL}
    element={<CourseWithdrawal />}
  />,
  <Route
    key="academy-standing-appeal"
    path={routes.STUDENTS.ACADEMY_STANDING_APPEAL}
    element={<AcademyStandingAppeal />}
  />,
  <Route
    key="course-incomplete"
    path={routes.STUDENTS.COURSE_INCOMPLETE}
    element={<CourseIncomplete />}
  />,
  <Route
    key="course-overload"
    path={routes.STUDENTS.COURSE_OVERLOAD}
    element={<CourseCommon api="" title={appConstants.COURSE_OVERLOAD} />}
  />,
  <Route
    key="waiting-list"
    path={routes.STUDENTS.WAITING_LIST}
    element={<CourseCommon api="" title={appConstants.COURSE_WAITLIST} />}
  />,
  <Route
    key="grade-appeal"
    path={routes.STUDENTS.GRADE_APPEAL}
    element={<GradeAppeal api="" title={appConstants.GRADE_APPEAL} />}
  />,
  <Route
    key="profile"
    path={routes.PROFILE}
    element={<ProfilePage api="" title={appConstants.Profile} />}
  />,
];
