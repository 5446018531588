import appConstants from '../../../../app/constant/constants/appConstants';
import campus from '../../../../assets/icons/vectors/campus.svg';
import degree from '../../../../assets/icons/vectors/degree.svg';
import email from '../../../../assets/icons/vectors/email.svg';
import graduationHat from '../../../../assets/icons/vectors/graduationHat.svg';
import phone from '../../../../assets/icons/vectors/phone.svg';

const getSvgByType = (type) => {
  let svg;
  switch (type) {
    case appConstants.TYPE.GRADUATIONHAT:
      svg = graduationHat;
      break;
    case appConstants.TYPE.EMAILMESSAGE:
      svg = email;
      break;
    case appConstants.TYPE.DEGREE:
      svg = degree;
      break;
    case appConstants.TYPE.PHONE:
      svg = phone;
      break;
    case appConstants.TYPE.CAMPUS:
      svg = campus;
      break;
    default:
      svg = '';
      break;
  }
  return svg;
};

export default getSvgByType;
