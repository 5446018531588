import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';

import PageNotFound from '../../features/authentication/page-not-found/PageNotFound';
import RegistrarBodyLayout from '../../components/hoc/registrar/registrar-body-layout/RegistrarBodyLayout';
import AllRequests from '../../features/authenticated/registrar/all-requests/AllRequests';
import Users from '../../features/authenticated/registrar/admin-panel/users/Users';
import ApprovedFlow from '../../features/authenticated/registrar/admin-panel/approved-flow/ApprovedFlow';
import Terms from '../../features/authenticated/registrar/admin-panel/terms/Terms';
import ProgramVersions from '../../features/authenticated/registrar/admin-panel/program-versions/ProgramVersions';
import Courses from '../../features/authenticated/registrar/admin-panel/courses/Courses';
import EmailPage from '../../features/authenticated/registrar/admin-panel/email-page/EmailPage';
import RequestDetail from '../../features/authenticated/registrar/request-detail/RequestDetail';
import Settings from '../../features/authenticated/registrar/settings/Settings';
import Reasons from '../../features/authenticated/registrar/settings/settings-pages/reasons/Reasons';
import EmailTemplateForm from '../../features/authenticated/registrar/admin-panel/email-page/email-template/EmailTemplateForm';
import SpecialApproval from '../../features/authenticated/registrar/admin-panel/special-approval/SpecialApproval';
import Restrictions from '../../features/authenticated/registrar/admin-panel/restrictions/Restrictions';
import Esignature from '../../features/authenticated/registrar/settings/settings-pages/e-signature/Esignature';
import TermsConditions from '../../features/authenticated/registrar/settings/settings-pages/terms-conditions/TermsConditions';
import RestrictionsDetails from '../../components/templates/registrar/admin-panel/restrictions/restrictions-details/RestrictionsDetails';
import DisableUserPage from '../../features/authenticated/registrar/admin-panel/disableUser/DisableUserPage';
import StudentGroup from '../../features/authenticated/registrar/settings/settings-pages/student-group/StudentGroup';
import SendEmail from '../../features/authenticated/registrar/operations/send-email/SendEmail';
import GradeChange from '../../features/authenticated/registrar/allApplications/applications/gradeChange/GradeChange';
import AllApplications from '../../features/authenticated/registrar/allApplications/AllApplications';
import RemovalOfI from '../../features/authenticated/registrar/allApplications/applications/removalOfI/RemovalOfI';
import { routes } from '../../app/constant/routes/Routes';
import UnAuthorized from '../../features/authentication/un-authorized/UnAuthorized';
import { commonRoutes } from '../common/CommonRoutes';
import _endpoints from '../../app/constant/endpoints/_endpoints';
const requestDetailsBasePath = routes.REGISTRAR.ALL_REQUESTS;

const emailBaseRoute = routes.REGISTRAR.EMAIL;
const settingsBaseRoute = routes.REGISTRAR.SETTINGS.SETTINGS;
const restrictionBaseRoutes = routes.REGISTRAR.RESTRICTIONS;
const allAppBaseRoute = routes.REGISTRAR.ALL_APPLICATIONS;
const emailRoutes = new Set([
  routes.REGISTRAR.EMAIL_TEMPLATE,
  routes.REGISTRAR.EMAIL_TEMPLATE_ID,
  routes.REGISTRAR.SEND_EMAIL,
]);
const allApplicationsRoutes = new Set([
  routes.REGISTRAR.ALL_APPLICATIONS,
  routes.REGISTRAR.ALL_APPLICATIONS_FORM.GRADE_CHANGE,
  routes.REGISTRAR.ALL_APPLICATIONS_FORM.REMOVAL_OF_I_GRADE,
]);

const restrictionRoutes = new Set([routes.REGISTRAR.RESTRICTIONS_DETAILS]);
const settingsRoutes = new Set(Object.values(routes.REGISTRAR.SETTINGS));
const requestsRoutes = new Set([routes.REGISTRAR.REQUEST_DETAILS]);

const getAllPaths = (pages) => {
  const paths = [];
  pages.forEach((page) => {
    if (page.path) paths.push(page.path);
    if (page.childPages?.length) {
      paths.push(...getAllPaths(page.childPages));
    }
  });
  return paths;
};

const ProtectedRoute = ({ element: Component, route }) => {
  const sideBarPages = useSelector((state) => state?.user?.sideBarPages || []);
  const availablePaths = getAllPaths(sideBarPages);

  const isAuthorized =
    availablePaths.includes(route) ||
    (settingsRoutes.has(route) && availablePaths.includes(settingsBaseRoute)) ||
    (emailRoutes.has(route) && availablePaths.includes(emailBaseRoute)) ||
    (restrictionRoutes.has(route) &&
      availablePaths.includes(restrictionBaseRoutes)) ||
    (requestsRoutes.has(route) &&
      availablePaths.includes(requestDetailsBasePath)) ||
    (allApplicationsRoutes.has(route) &&
      availablePaths.includes(allAppBaseRoute));

  return isAuthorized ? (
    <Component />
  ) : (
    <Navigate to="/not-authorized" replace />
  );
};

const RegistrarRoutes = () => (
  <Routes>
    <Route element={<RegistrarBodyLayout />}>
      <Route
        path={routes.REGISTRAR.ALL_REQUESTS}
        element={
          <ProtectedRoute
            route={routes.REGISTRAR.ALL_REQUESTS}
            element={AllRequests}
          />
        }
      />
      <Route
        path={routes.REGISTRAR.ALL_APPLICATIONS}
        element={
          <ProtectedRoute
            route={routes.REGISTRAR.ALL_APPLICATIONS}
            element={AllApplications}
          />
        }
      />
      <Route
        path={routes.REGISTRAR.ALL_APPLICATIONS_FORM.GRADE_CHANGE}
        element={
          <ProtectedRoute
            route={routes.REGISTRAR.ALL_APPLICATIONS_FORM.GRADE_CHANGE}
            element={GradeChange}
          />
        }
      />
      <Route
        path={routes.REGISTRAR.ALL_APPLICATIONS_FORM.REMOVAL_OF_I_GRADE}
        element={
          <ProtectedRoute
            route={routes.REGISTRAR.ALL_APPLICATIONS_FORM.REMOVAL_OF_I_GRADE}
            element={RemovalOfI}
          />
        }
      />
      <Route
        path={routes.REGISTRAR.USERS}
        element={
          <ProtectedRoute route={routes.REGISTRAR.USERS} element={Users} />
        }
      />
      <Route
        path={routes.REGISTRAR.DISABLE_REQUEST_USER}
        element={
          <ProtectedRoute
            route={routes.REGISTRAR.DISABLE_REQUEST_USER}
            element={DisableUserPage}
          />
        }
      />
      <Route
        path={routes.REGISTRAR.APPROVER_FLOW}
        element={
          <ProtectedRoute
            route={routes.REGISTRAR.APPROVER_FLOW}
            element={ApprovedFlow}
          />
        }
      />
      <Route
        path={routes.REGISTRAR.TERMS}
        element={
          <ProtectedRoute route={routes.REGISTRAR.TERMS} element={Terms} />
        }
      />
      <Route
        path={routes.REGISTRAR.PROGRAM_VERSIONS}
        element={
          <ProtectedRoute
            route={routes.REGISTRAR.PROGRAM_VERSIONS}
            element={ProgramVersions}
          />
        }
      />
      <Route
        path={routes.REGISTRAR.COURSES}
        element={
          <ProtectedRoute route={routes.REGISTRAR.COURSES} element={Courses} />
        }
      />
      <Route
        path={routes.REGISTRAR.REPLACE_INSTRUCTOR}
        element={
          <ProtectedRoute
            route={routes.REGISTRAR.REPLACE_INSTRUCTOR}
            element={StudentGroup}
          />
        }
      />
      <Route
        path={routes.REGISTRAR.EMAIL}
        element={
          <ProtectedRoute route={routes.REGISTRAR.EMAIL} element={EmailPage} />
        }
      />
      <Route
        path={routes.REGISTRAR.EMAIL_TEMPLATE}
        element={
          <ProtectedRoute
            route={routes.REGISTRAR.EMAIL_TEMPLATE}
            element={EmailTemplateForm}
          />
        }
      />
      <Route
        path={routes.REGISTRAR.REQUEST_DETAILS}
        element={
          <ProtectedRoute
            route={routes.REGISTRAR.REQUEST_DETAILS}
            element={RequestDetail}
          />
        }
      />
      <Route
        path={routes.REGISTRAR.SPECIAL_APPROVAL}
        element={
          <ProtectedRoute
            route={routes.REGISTRAR.SPECIAL_APPROVAL}
            element={SpecialApproval}
          />
        }
      />
      <Route
        path={routes.REGISTRAR.RESTRICTIONS}
        element={
          <ProtectedRoute
            route={routes.REGISTRAR.RESTRICTIONS}
            element={Restrictions}
          />
        }
      />
      <Route
        path={routes.REGISTRAR.RESTRICTIONS_DETAILS}
        element={
          <ProtectedRoute
            route={routes.REGISTRAR.RESTRICTIONS_DETAILS}
            element={RestrictionsDetails}
          />
        }
      />
      <Route
        path={routes.REGISTRAR.SEND_EMAIL}
        element={
          <ProtectedRoute
            route={routes.REGISTRAR.SEND_EMAIL}
            element={SendEmail}
          />
        }
      />
      <Route
        path={routes.REGISTRAR.SETTINGS.SETTINGS}
        element={
          <ProtectedRoute
            route={routes.REGISTRAR.SETTINGS.SETTINGS}
            element={Settings}
          />
        }
      >
        <Route
          index
          element={<Navigate to={routes.REGISTRAR.SETTINGS.REASONS} replace />}
        />
        <Route
          path={routes.REGISTRAR.SETTINGS.REASONS}
          element={
            <ProtectedRoute
              route={routes.REGISTRAR.SETTINGS.REASONS}
              element={Reasons}
            />
          }
        />
        <Route
          path={routes.REGISTRAR.SETTINGS.ESIGNATURE}
          element={
            <ProtectedRoute
              route={routes.REGISTRAR.SETTINGS.ESIGNATURE}
              element={Esignature}
            />
          }
        />
        <Route
          path={routes.REGISTRAR.SETTINGS.TERMSCONDITIONS}
          element={
            <ProtectedRoute
              route={routes.REGISTRAR.SETTINGS.TERMSCONDITIONS}
              element={TermsConditions}
            />
          }
        />
        <Route
          path={routes.REGISTRAR.SETTINGS.STUDENT_GROUP}
          element={
            <ProtectedRoute
              route={routes.REGISTRAR.SETTINGS.STUDENT_GROUP}
              element={StudentGroup}
            />
          }
        />
      </Route>
      {commonRoutes}
    </Route>

    <Route path="/not-authorized" element={<UnAuthorized />} />
    <Route path={routes.PAGE_NOT_FOUND} element={<PageNotFound />} />
  </Routes>
);

export default RegistrarRoutes;
