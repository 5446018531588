import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import styles from './Factory.module.scss';
import { getSvgByType } from '../../../../app/helpers/forms/GetSvgByType';
import appConstants from '../../../../app/constant/constants/appConstants';

const NavigationLink = ({ link, isBarOpen, handleNavigate }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    if (
      link?.childPages?.some((child) => location.pathname.includes(child.path))
    ) {
      setIsOpen(true);
    }
  }, [location.pathname, link.childrens]);
  return (
    <>
      <div
        className={`${styles['nav-link']} ${isOpen ? styles.open : ''} ${
          !isBarOpen ? styles.fullWidth : ''
        }`}
      >
        {link?.childPages?.length > 0 ? (
          <div
            onClick={handleToggle}
            className={`${styles.link} ${isBarOpen ? '' : styles.closed} `}
          >
            <img
              className={styles['link-image']}
              src={getSvgByType(link.icon)}
              alt=""
            />
            <div
              className={`${styles['pageName-container']}${
                isBarOpen ? '' : styles.closed
              }  `}
            >
              {isBarOpen ? (
                <p className={styles.text}>{link.pageName}</p>
              ) : null}
              {link?.childPages?.length > 0 && (
                <img
                  className={`${styles.arrow} ${
                    isBarOpen ? '' : styles.closed
                  }`}
                  src={getSvgByType(appConstants.TYPE.DOWN_ARROW)}
                  alt=""
                  onClick={(e) => {
                    e.stopPropagation();
                    handleToggle();
                  }}
                />
              )}
            </div>
          </div>
        ) : (
          <NavLink
            to={link.path}
            className={({ isActive }) =>
              `${styles['parent-link']} ${isBarOpen ? ' ' : styles.closed} ${
                isActive ? styles.active : ''
              }`
            }
            onClick={() => {
              handleNavigate(link.path, link.label);
            }}
          >
            <img
              className={styles['link-image']}
              src={getSvgByType(link.icon)}
              alt=""
            />
            {isBarOpen ? <p className={styles.text}>{link.pageName}</p> : null}
          </NavLink>
        )}
      </div>
      <div
        className={`${styles['nav-children']} ${isOpen ? styles.open : ''} ${
          !isBarOpen ? styles.fullWidth : ''
        }`}
      >
        {link?.childPages?.length > 0 &&
          link.childPages.map((child, index) => (
            <NavLink
              key={index}
              to={child.path}
              className={({ isActive }) =>
                `${styles['children-link']} ${isActive ? styles.active : ''}`
              }
              onClick={() => handleNavigate(child.path, child.label)}
            >
              <div
                className={`${styles.link}   ${isBarOpen ? '' : styles.closed}`}
              >
                <img src={getSvgByType(child.icon)} alt="" />
                {isBarOpen ? (
                  <p className={styles.text}>{child.pageName}</p>
                ) : null}
              </div>
            </NavLink>
          ))}
      </div>
    </>
  );
};

export default NavigationLink;
