import React from 'react';
import styles from './Button.module.scss';
import appConstants from '../../../../app/constant/constants/appConstants';
import { getSvgByType } from '../../../../app/helpers/forms/GetSvgByType';
import Loader from '../../../molecules/table/atoms/loader/Loader';

const Button = ({
  label,
  onClick,
  variant = appConstants.DEDAULT,
  type,
  color,
  width,
  isLoading,
  buttonType,
  className,
  isDisabled,
  ...props
}) => {
  const buttonClass = `${styles.button} ${styles[variant]} ${className ?? ''}`;

  return (
    <button
      className={buttonClass}
      onClick={onClick}
      style={{ width: width }}
      type={buttonType}
      disabled={isLoading || isDisabled}
      {...props}
    >
      {isLoading ? (
        <Loader isComponent style={{ width: '20px', height: '21px' }} />
      ) : (
        <>
          {type ? (
            <span className={styles['image-wrapper']}>
              {type === 'none' ? null : (
                <img
                  className={styles[color]}
                  src={getSvgByType(type)}
                  alt={label}
                />
              )}
            </span>
          ) : null}

          {label}
        </>
      )}
    </button>
  );
};

export default Button;
