import React from 'react';
import styles from './WithdrawalModal.module.scss';

const WithdrawalModal = () => {
  return (
    <div className={styles['withdrawal-modal']}>
      <p>
        In the event that a student withdraws or is dismissed from all classes
        during term, refund of tuition and fees will be calculated according to
        the following schedule:
      </p>

      <table className={styles['refund-table']}>
        <thead>
          <tr>
            <th>Schedule</th>
            <th>Address</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>During Drop/Add *</td>
            <td>100%</td>
          </tr>
          <tr>
            <td>Until the end of the second week **</td>
            <td>50%</td>
          </tr>
          <tr>
            <td>Until the end of the third week</td>
            <td>25%</td>
          </tr>
          <tr>
            <td>After the third week</td>
            <td>0%</td>
          </tr>
        </tbody>
      </table>

      <p>
        In the event that a student withdraws from partial classes during the
        term, refunds will be calculated according to the following schedule:
      </p>

      <table className={styles['refund-table']}>
        <thead>
          <tr>
            <th>Schedule</th>
            <th>Address</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Until end of Drop/Add ***</td>
            <td>100%</td>
          </tr>
          <tr>
            <td>Until the end of the second week **</td>
            <td>50%</td>
          </tr>
          <tr>
            <td>Until the end of the third week</td>
            <td>25%</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default WithdrawalModal;
