import React from 'react';
import styles from './NewTermsAndConditionsForm.module.scss';
import Button from '../../../../../../../components/atoms/forms/button/Button';
import Select from '../../../../../../../components/atoms/forms/select/select/Select';
import TextEditor from '../../../../../../../components/atoms/forms/text-editor/TextEditor';
import appConstants from '../../../../../../../app/constant/constants/appConstants';
import { useGetQuery } from '../../../../../../../services/apiCall';
import _endpoints from '../../../../../../../app/constant/endpoints/_endpoints';

const applicationsApi = _endpoints.request.getRequestTypes;

const NewTermsAndConditionsForm = ({ formik, onClose, isLoading }) => {
  const handleFormSubmit = (event) => {
    event.preventDefault();
    formik.handleSubmit();
  };

  const { data: applicationsData } = useGetQuery({ api: applicationsApi });

  // Generate application options
  const applicationOptions = applicationsData?.map((val) => ({
    value: val.id,
    label: val.applicationName,
  }));

  return (
    <form
      className={styles['form']}
      autoComplete="off"
      onSubmit={handleFormSubmit}
    >
      <Select
        label="Application"
        type={appConstants.TYPE.PROFILE}
        placeholder="Select applications"
        name="id"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        options={applicationOptions}
        value={formik.values.id}
        error={formik.touched.id && formik.errors.id}
        touched={formik.touched.id}
        disabled={formik.values.id && formik.values.id !== ''}
      />

      <TextEditor
        placeholder="Add terms and conditions"
        name="termsAndConditions"
        onChange={(value) => formik.setFieldValue('termsAndConditions', value)}
        onBlur={formik.handleBlur}
        value={formik.values.termsAndConditions}
        error={
          formik.touched.termsAndConditions && formik.errors.termsAndConditions
        }
        touched={formik.touched.termsAndConditions}
      />

      <div className="form-footer">
        <Button
          onClick={onClose}
          label={appConstants.CANCEL}
          buttonType="button"
        />
        <Button
          onClick={handleFormSubmit}
          label={formik.values.id ? 'Save Changes' : 'Add'}
          variant={appConstants.VARIANT.DANGER}
          type={appConstants.TYPE.CHECK}
          isLoading={isLoading}
          buttonType="button"
        />
      </div>
    </form>
  );
};

export default NewTermsAndConditionsForm;
