import { useMediaQuery } from 'react-responsive';

const breakpoints = {
  xs: 30 * 16,
  sm: 48 * 16,
  md: 64 * 16,
  lg: 75 * 16,
  xl: 90 * 16,
};
const useResponsive = () => {
  const isTablet = useMediaQuery({ query: `(max-width: ${breakpoints.lg}px)` });

  return { isTablet };
};

export { breakpoints, useResponsive };
