import React from 'react';
import { routes } from '../../../../../../app/constant/routes/Routes';
import List from '../../../../../../components/atoms/listTag/ListTag';
import styles from './academicStandingAppeal.module.scss';
import _endpoints from '../../../../../../app/constant/endpoints/_endpoints';

const rule1 = {
  title:
    'A student will placed on <span>Academic Warning</span> at the end of the first academic term in which his or her CGPA falls bellow 2.0.',
  data: [
    'At the end of the next academic term, the student is removed from Academic Warning and returned to good academic standing, if he or she achieves a CGPA of 2.0 or higher.',
    'If at the end of the next academic term, immediately following placement on Academic Warning, the student’s CGPA remains below 2.0, he or she will be placed on Academic Probation.',
    'Once a student is place on Academic Probation, he or she has one academic term to achieve good academic standing. A student  who, at the end of one academic term on Academic Probation, raises his or her CGPA to 2.0 or higher is removed from Academic Probation and returned to good academic standing.',
    'Students are notified of placement on Academic Warning and Academic Probation in writing.',
  ],
};
const rule2 = {
  title:
    'Conditions Applicable to students on Academic Warning and Academic Probation',
  data: [
    'Students on Academic Warning and Academic Probation must consult with the Student Support Office prior to selecting their courses for the next academic term and must obtain their academic advisors approval to registrar.',
    'As long as student remains on Academic Warning or Academic Probation, the Student Support Office will monitor the student’s performance and will continue to provide support thought regular contact with the student.',
    'Student on Academic Probation must give priority to repeating all required failed courses, and are advised to repeat other courses in which they obtained failing or low grades .',
  ],
};
const rule3 = {
  title: 'Academic Suspension',
  data: [
    'If student’s CGPA, ath the end of one academic term on Academic Probation, is till below 2.00, he or she placed on Academic Suspension from the university for the next academic term.',
    'Students are notified of placement on Academic Suspension in waiting.',
  ],
};
const rule4 = {
  title: 'Readmission from Academic Suspension',
  data: [
    'Students place on Academic Suspension may be granted readmission to the university if the apply for readmission at the end of their suspension period. See the section below on the Academic Appeals Process. <span> Students granted Readmission from Academic Suspension will be placed on Academic Probation. </span> The course  load for these students will be not less than 9 credit hours and not more than 12 credit hours per semester, and must be 6 credit hours in Summer Terms.',
  ],
};
const academicStandingAppeal = {
  id: 0,
  title: 'Academic Standing Appeal',
  desc: 'Request to return from dismissal or academic suspension',
  type: 'academicStandingAppeal',
  api: _endpoints.request.getRequestByType,
  modalContent: {
    title: 'Apply for Academic Standing Appeal',
    subtitle: 'AUD Rules on Academic Standing Appeals',
    component: (
      <div className={styles['modal-content']}>
        <List items={rule1} />
        <List variant="secondary" items={rule2} />
        <List variant="secondary" items={rule3} />
        <List variant="secondary" items={rule4} />
      </div>
    ),
  },
  onModalSubmitroute: routes.STUDENTS.ACADEMY_STANDING_APPEAL,
};

const applicationConfigurations = [
  {
    id: 0,
    onModalSubmitroute: routes.STUDENTS.ACADEMY_STANDING_APPEAL,
    // FormComponent: ApplyForAcademicAppealFrom,
  },
  // {
  //   id: 1,
  //   onModalSubmitroute: routes.STUDENTS.WAITING_LIST,
  // },
  // {
  //   id: 2,
  //   onModalSubmitroute: routes.STUDENTS.COURSE_INCOMPLETE,
  // },
  // {
  //   id: 3,
  //   onModalSubmitroute: routes.STUDENTS.COURSE_WITHDRAWAL,
  // },
  // {
  //   id: 4,
  //   onModalSubmitroute: routes.STUDENTS.COURSE_OVERLOAD,
  // },
  {
    id: 6,
    onModalSubmitroute: routes.STUDENTS.GRADE_APPEAL,
  },
  {
    id: 7,
    onModalSubmitroute: routes.STUDENTS.ACADEMY_STANDING_APPEAL,
  },
];
export default applicationConfigurations;
