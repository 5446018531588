import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import styles from '../Table.module.scss';
import { getSvgByType } from '../../../../app/helpers/forms/GetSvgByType';
import appConstants from '../../../../app/constant/constants/appConstants';
import Tag from '../../../atoms/tag/Tag';
import Input from '../../../atoms/forms/input/input/Input';
import capitalizeFirstLetter from '../../../../app/helpers/forms/capitalizedFirstLetter';
import Loader from '../atoms/loader/Loader';
import { RadioButton } from '../../../atoms/forms/radio-group/RadioGroup';
import {
  useDeleteResourceMutation,
  usePostMutation,
} from '../../../../services/apiCall';
import { successMessage } from '../../../hoc/toast/Toast';
import { handleError } from '../NewTable';
import SuccessModal from '../../../hoc/success-modal/SuccessModals';
import noDataSvg from './noData.svg';
import Toggle from '../../../atoms/forms/toggle/Toggle';
import Checkbox from '../../../atoms/forms/checkbox/Checkbox';
import Button from '../../../atoms/forms/button/Button';
import Tooltip from '../../tootltip/Tooltip';
const formatTitle = (title) => {
  const titleParts = title.split(' - ');
  return titleParts.map((part, index) => (
    <span key={index} style={{ display: 'block', whiteSpace: 'nowrap' }}>
      {part}
    </span>
  ));
};
const hasActionsHeader = (actions) => {
  return (
    actions?.hasCancel ||
    actions?.hasEdit ||
    actions?.hasDelete ||
    actions?.hasView ||
    actions?.hasReverse
  );
};

const RenderTableHeader = ({
  tableHeaderData,
  actions,
  visibleColumns,
  isTerm,
  onSelectAll,
  isAllSelected,
  setQuery,
  query,
  tableData,
}) => {
  const handleSort = (header) => {
    const isCurrentSort = query.sortBy === header.key;
    const newDesc = isCurrentSort ? !query.desc : true;
    setQuery({ ...query, sortBy: header.key, desc: newDesc });
  };

  const hasAnyAppeal = tableData?.some((item) => item.showAppeal);
  return (
    <thead>
      <tr>
        {actions?.hasOptionalSelect && <th></th>}
        {actions?.hasCheckbox && (
          <th>
            <img
              src={getSvgByType(
                isAllSelected ? 'minusCheckSign' : 'plusCheckSign'
              )}
              onClick={() => onSelectAll(!isAllSelected)}
              alt="Select all"
              aria-label="Select all rows"
            />
          </th>
        )}
        {tableHeaderData
          .filter((header) => visibleColumns.includes(header.key))
          .map((header, index) => (
            <th
              key={index}
              className={`${styles['header-cell']} ${
                isTerm && index === 0 ? styles['fixed-width-column'] : ''
              } ${isTerm && index === 0 ? styles['header-term-border'] : ''}`}
            >
              <div
                className={styles['cell']}
                onClick={header.isSorted ? () => handleSort(header) : () => []}
              >
                <p>{formatTitle(header.title)}</p>
                {header.isSorted && (
                  <span>
                    <Tooltip content={`Sort by ${header.title}`}>
                      <img
                        src={getSvgByType(appConstants.TYPE.FILTER_ARROW)}
                        alt="Sort"
                        className={
                          query.sortBy?.toLowerCase() ===
                          header.key.toLowerCase()
                            ? query.desc
                              ? styles['sort-desc']
                              : styles['sort-asc']
                            : ''
                        }
                      />
                    </Tooltip>
                  </span>
                )}
              </div>
            </th>
          ))}
        {hasActionsHeader(actions) && <th></th>}
        {hasAnyAppeal ? <th></th> : null}
      </tr>
    </thead>
  );
};

const RenderTableBody = ({
  tableHeaderData,
  tableData,
  onRowClick,
  actions,
  isFiltering,
  visibleColumns,
  setQuery,
  query,
  isLoading,
  api,
  data,
  rowKey,
  deleteApi,
  fetchData,
  setIsModalOpen,
  form,
  deleteMethod,
  isTerm,
  title,
  deleteString,
  selectedItems,
  setSelectedItems,
  permissionKeys,
  isAppeal,
  onDeleteSuccess,
}) => {
  const [post] = usePostMutation();
  const [del] = useDeleteResourceMutation();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isAppealModalOpen, setIsAppealModalOpen] = useState(false);

  const [item, setItem] = useState(null);

  const [selectedRow, setSelectedRow] = useState(null);

  const formik = useFormik({
    initialValues: tableHeaderData.reduce((acc, header) => {
      acc[header.key] = '';
      return acc;
    }, {}),
    onSubmit: (values) => {
      // console.log('submit value', values);
    },
  });
  console.log('asdasdsasdads', actions);
  const hasAnyAppeal = tableData?.some((item) => item.isAppeal);
  const handleCheckboxChange = (itemId) => {
    setSelectedItems((prevSelectedItems) => {
      const newSelectedItems = prevSelectedItems.includes(itemId)
        ? prevSelectedItems.filter((id) => id !== itemId)
        : [...prevSelectedItems, itemId];

      return newSelectedItems;
    });
  };

  const handleRowClick = (item) => {
    // here we Find the item with the matching rowKey
    const selectedItem = data?.items?.find(
      (dataItem) => dataItem[rowKey] === item[rowKey]
    );

    if (onRowClick) {
      onRowClick(selectedItem);
    }
    if (actions?.hasOptionalSelect) setSelectedRow(item[rowKey]);
    return selectedItem;
  };

  const handleActions = (e, func, item) => {
    e.stopPropagation();
    if (item[rowKey] === '') {
      item[rowKey] = 0;
    }
    const selectedItem = data?.items?.find(
      (dataItem) => dataItem[rowKey] === item[rowKey]
    );

    if (typeof func === 'function') {
      func(selectedItem);
    } else {
      throw new Error(
        `Action function is not provided or not a function. Received: ${typeof func} --> please provide the function from outside the function or remove the functionality from table`
      );
    }
  };

  const handleEdit = (e, func, item, type) => {
    e.stopPropagation();
    if (type === 'edit' && form) setIsModalOpen(true);
    if (item[rowKey] === '') {
      item[rowKey] = 0;
    }
    const selectedItem = data?.items?.find(
      (dataItem) => dataItem[rowKey] === item[rowKey]
    );
    if (typeof func === 'function') {
      func(selectedItem);
    } else {
      throw new Error(
        `Action function is not provided or not a function. Received: ${typeof func} --> please provide the function from outside the function or remove the functionality from table`
      );
    }
  };

  const handleDelete = async () => {
    const selectedItem = data?.items?.find(
      (dataItem) => dataItem[rowKey] === item[rowKey]
    );
    try {
      if (selectedItem) {
        if (deleteMethod === 'del') {
          await del({
            apiUrl: deleteApi,
            params: [`/${selectedItem[rowKey]}`],
          }).unwrap();
        } else {
          await post({
            apiUrl: deleteApi,
            params: [`${deleteString ? '?Id=' : '/'}${selectedItem[rowKey]}`],
          }).unwrap();
        }

        successMessage('Deleted Successfully!');
        handleCloseModal();
        if (onDeleteSuccess) onDeleteSuccess();
        else fetchData(query);
      } else {
        handleError();
      }
    } catch (error) {
      handleError();
    }
  };
  console.log('permissionKeys', permissionKeys);
  const filteredHeaders = tableHeaderData.filter((header) =>
    visibleColumns.includes(header.key)
  );
  // useEffect(() => {
  //   // this use effect is triggered when the header inputs are changing
  //   const values = formik.values;

  //   // structure an array as the backend needs
  //   const formatValues = Object.keys(values)
  //     .map((key) => ({
  //       columnName: capitalizeFirstLetter(key),
  //       columnValue: values[key],
  //     }))
  //     .filter(({ columnValue }) => columnValue !== '');

  //   // the new query to be sent
  //   const newQuery = { ...query, filterColumns: formatValues };
  //   // if the query is empty we should delete it from the query that will be send to backend
  //   if (formatValues.length === 0) {
  //     delete newQuery.filterColumns;
  //   }
  //   // rerender the parent when this state updates / recall api

  //   setQuery(newQuery);
  // }, [formik.values]);
  useEffect(() => {
    if (query?.filterColumns) {
      const queryObject = query.filterColumns.reduce((acc, item) => {
        const key =
          item.columnName.charAt(0).toLowerCase() + item.columnName.slice(1);
        acc[key] = item.columnValue;
        return acc;
      }, {});
      formik.setValues(queryObject);
    }
  }, []);
  useEffect(() => {
    formik.resetForm();
  }, [isFiltering]);

  const handleOpenModal = (e, item) => {
    e.stopPropagation();
    setItem(item);
    setIsDeleteModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleSubmitModal = () => {
    handleDelete();
  };
  return (
    <>
      {isLoading && api ? (
        <td className={styles['loading-container']}>
          <Loader />
        </td>
      ) : null}

      <tbody className={styles['table-body']}>
        {isFiltering
          ? filteredHeaders?.map((header, index) => (
              <td
                key={index}
                className={`${
                  isTerm && index === 0 ? styles['findexxed-width-column'] : ''
                } ${isTerm && index === 0 ? styles['body-term-border'] : ''}`}
              >
                <Input
                  name={header.key}
                  placeholder={appConstants.PLACEHOLDER.SEARCH}
                  onChange={formik.handleChange}
                  value={formik.values[header.key]}
                />
              </td>
            ))
          : null}
        {tableData && tableData?.length > 0 ? (
          tableData.map((item, index) => (
            <tr
              key={index}
              onClick={() => handleRowClick(item)}
              className={`${styles['clickable-row']} ${
                selectedRow === item[rowKey] ? styles['selected-row'] : ''
              }`}
            >
              {actions?.hasOptionalSelect ? (
                <td>
                  <RadioButton
                    name="rowSelection"
                    value={item[rowKey]}
                    checked={selectedRow === item[rowKey]}
                    onChange={(e) => handleRowClick(item, e)}
                  />
                </td>
              ) : null}
              {actions?.hasCheckbox ? (
                <td>
                  <Checkbox
                    ischecked={selectedItems.includes(item[rowKey])}
                    onChange={() => handleCheckboxChange(item[rowKey])}
                  />
                </td>
              ) : null}
              {filteredHeaders?.map((header, i) => (
                <td
                  key={i}
                  className={
                    isTerm && i === 0 ? styles['body-term-border'] : ''
                  }
                  style={{ fontWeight: header.isBold ? '600' : '' }}
                >
                  {header.isColored ? (
                    <Tag
                      label={item[header.key]}
                      variant={item[header.key]}
                      isColored
                    />
                  ) : (
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          item[header.key] !== null ? item[header.key] : '-',
                      }}
                      className={`${
                        isAppeal ? styles['max-width-td'] : styles.comment
                      }`}
                    />
                  )}
                </td>
              ))}
              {hasActionsHeader(actions) && (
                <td
                  className={`${styles['action-container']} ${
                    hasAnyAppeal ? styles['action-container-appeal'] : ''
                  }`}
                >
                  {actions?.hasRole &&
                    (!actions?.hasRole?.permisionKey ||
                      permissionKeys?.includes(
                        actions?.hasRole?.permisionKey
                      )) && (
                      <Tooltip content="Role">
                        <img
                          className={styles['action-image']}
                          src={getSvgByType(appConstants.TYPE.VIEW)}
                          alt="view"
                          onClick={(e) =>
                            handleActions(e, actions.hasRole.func, item)
                          }
                        />
                      </Tooltip>
                    )}
                  {actions?.hasEdit &&
                    (!actions?.hasEdit?.permisionKey ||
                      permissionKeys?.includes(
                        actions?.hasEdit?.permisionKey ?? null
                      )) && (
                      <Tooltip content="Edit">
                        <img
                          className={styles['action-image']}
                          src={getSvgByType(appConstants.TYPE.EDIT)}
                          alt="edit"
                          onClick={(e) =>
                            handleEdit(e, actions.hasEdit.func, item, 'edit')
                          }
                        />
                      </Tooltip>
                    )}
                  {actions?.hasDelete &&
                    (!actions?.hasDelete?.permisionKey ||
                      permissionKeys?.includes(
                        actions?.hasDelete?.permisionKey ?? null
                      )) && (
                      <Tooltip content="Delete" red>
                        <img
                          className={styles['action-image']}
                          src={getSvgByType(appConstants.TYPE.DELETE)}
                          alt="delete"
                          onClick={(e) => handleOpenModal(e, item)}
                        />
                      </Tooltip>
                    )}

                  {data?.items?.[index].canCancel &&
                  (!actions?.canCancel?.permisionKey ||
                    permissionKeys?.includes(
                      actions?.canCancel?.permisionKey ?? null
                    )) ? (
                    <Tooltip content="Cancel Request" red>
                      <img
                        className={styles['action-image']}
                        src={getSvgByType('cancel')}
                        alt="cancel"
                        onClick={(e) =>
                          handleActions(e, actions.hasCancel.func, item)
                        }
                      />
                    </Tooltip>
                  ) : null}

                  {data?.items?.[index].canCancel &&
                  (!actions?.hasReverte?.permisionKey ||
                    permissionKeys?.includes(
                      actions?.hasReverte?.permisionKey ?? null
                    )) ? (
                    <Tooltip content="Reverse" red>
                      <img
                        className={styles['action-image']}
                        src={getSvgByType('reverse')}
                        alt="reverse"
                        onClick={(e) =>
                          handleActions(e, actions.hasReverse.func, item)
                        }
                      />
                    </Tooltip>
                  ) : null}

                  {actions?.hasSwitch && <Toggle />}
                </td>
              )}
              {data?.items?.[index].showAppeal ? (
                <td className={styles.appeal}>
                  <Button
                    label="Re-appeal"
                    variant="danger"
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsAppealModalOpen(true);
                    }}
                    className={styles['appeal-button']}
                  />
                  <Button
                    label="Accept"
                    variant="green"
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsAppealModalOpen(true);
                    }}
                    className={styles['appeal-button']}
                  />
                </td>
              ) : null}
            </tr>
          ))
        ) : !isLoading || !api ? (
          <tr>
            <td
              className={styles['no-data']}
              colSpan={tableHeaderData.length + 1}
            >
              {query.searchBy ? (
                <div className={styles['no-data-text']}>
                  <p>No results were found</p>
                </div>
              ) : (
                <div className={styles['no-data-container']}>
                  <img src={noDataSvg} alt="" />
                  <p>no {title ?? 'data'} Found</p>
                </div>
              )}
            </td>
          </tr>
        ) : null}
      </tbody>
      <SuccessModal
        isOpen={isDeleteModalOpen}
        onClose={handleCloseModal}
        onSubmit={handleSubmitModal}
        closeOnSubmit={true}
        onApproveButton={{ text: 'delete' }}
        text="Are you sure you want to delete?"
      />
      <SuccessModal
        isOpen={isAppealModalOpen}
        onClose={() => setIsAppealModalOpen(false)}
        isSuccess
        timeout={1000}
      >
        <h2>Request completed</h2>
        <p>Your request has been processed. Thank you!</p>
      </SuccessModal>
    </>
  );
};

export { RenderTableHeader, RenderTableBody };
