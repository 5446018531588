import React from 'react';
import styles from './BackHeader.module.scss';
import appConstants from '../../../app/constant/constants/appConstants';
import { getSvgByType } from '../../../app/helpers/forms/GetSvgByType';
import { useNavigate } from 'react-router-dom';
import Tooltip from '../../molecules/tootltip/Tooltip';

const Backheader = ({ title, onBackClick }) => {
  const navigate = useNavigate();
  const handleBack = () => {
    if (onBackClick) onBackClick;
    else navigate(-1);
  };
  return (
    <div className={styles['header-info']}>
      <Tooltip content="Back">
        <img
          src={getSvgByType(appConstants.TYPE.BACKBUTTON)}
          alt=""
          onClick={handleBack}
        />
      </Tooltip>
      <p>{title}</p>
    </div>
  );
};

export default Backheader;
