import React, { useEffect, useState } from 'react';
import Select from '../../../atoms/forms/select/select/Select';
import Button from '../../../atoms/forms/button/Button';
import appConstants from '../../../../app/constant/constants/appConstants';
import styles from './Factory.module.scss';
import SearchInput from '../../../atoms/forms/input/searchInput/SearchInput';
import { getSvgByType } from '../../../../app/helpers/forms/GetSvgByType';
import DropdownButton from '../../../atoms/dropdown-button/DropdownButton';
import TableModal from './TableModal';
import SlideMenuApplications from '../../../hoc/slide-menu-rtl/SlideMenuApplications';
import Tooltip from '../../tootltip/Tooltip';

const portalItems = [
  {
    label: appConstants.LABEL.EXPORT,
    type: appConstants.TYPE.EXPORT,
  },
  {
    label: appConstants.LABEL.PRINT,
    type: appConstants.TYPE.PRINT,
  },
];
const TableFilters = ({
  filters = {
    hasCourseCode: false,
    hasTerm: false,
    hasSearch: true,
    hasFilter: false,
    hasColumnVisibility: false,
    hasExport: false,
    hasSub: false,
  },
  hasCategoriesSlide,
  HasSearchButton,
  rightContent,
  handleFilterClick,
  isFiltering,
  visibleColumns,
  onColumnVisibilityChange,
  tableHeaderData,
  isLoading,
  handleSearchChange,
  query,
  form,
  isModalOpen,
  setIsModalOpen,
  modalConfig,
  setExtraQuery,
  extraQuery,
  hasAdd,
  activeApplication: externalActiveApplication,
  permissionKeys,
  actions,
  getApplication,
  getQuery,
}) => {
  const {
    hasCourseCode,
    hasTerm,
    hasSearch,
    hasFilter,
    hasColumnVisibility,
    hasExport,
  } = filters;
  const [activeApplication, setActiveApplication] = useState(
    extraQuery?.id ?? -1
  );
  const handleSearch = (name, value) => {
    handleSearchChange({ ...query, searchBy: value });
    if (getQuery) {
      getQuery({ ...query, searchBy: value });
    }
  };
  const headerWidth =
    hasTerm && hasCourseCode ? styles['width-50vw'] : styles['width-20vw'];
  const filterClick = () => handleFilterClick();
  const columnVisibilityItems = tableHeaderData.map((header) => ({
    label: header.title,
    value: header.key,
    checked: visibleColumns.includes(header.key),
  }));
  useEffect(() => {
    setExtraQuery({
      id: activeApplication?.id,
      query: 'RequestType',
    });
    if (getApplication) getApplication(activeApplication);
  }, [activeApplication]);

  return (
    <div className={styles.header}>
      <div className={`${styles.left} ${headerWidth}`}>
        {hasTerm && (
          <Select
            placeholder={appConstants.PLACEHOLDER.SELECT_TERM}
            label={appConstants.LABEL.TERM}
            type={appConstants.TYPE.DATE}
          />
        )}
        {hasCourseCode && (
          <Select
            placeholder={appConstants.PLACEHOLDER.ENTER_CODE}
            label={appConstants.LABEL.COURSE_CODE}
            type={appConstants.TYPE.DOCS}
          />
        )}
        {HasSearchButton && (
          <Button
            label={appConstants.LABEL.SEARCH}
            variant={appConstants.VARIANT.DANGER}
            type={appConstants.TYPE.SEARCH}
            color="white"
          />
        )}

        {hasSearch && (
          <SearchInput
            onChange={handleSearch}
            value={query?.searchBy}
            placeholder="search"
          />
        )}
      </div>

      <div className={styles.right}>
        {hasColumnVisibility && (
          <DropdownButton
            items={columnVisibilityItems}
            type={appConstants.TYPE.COL_VISIBILITY}
            isMulty
            handleCheckboxChange={(key) => {
              onColumnVisibilityChange(key);
            }}
            // disabled={isLoading}
            tooltipContent="Column Visibility"
          />
        )}
        {hasFilter && (
          <button
            onClick={filterClick}
            className={styles['filter-button']}
            // disabled={isLoading}
          >
            {isFiltering ? (
              <Tooltip content="Clear Filter" red>
                <img
                  className={styles['filter-image']}
                  src={getSvgByType(appConstants.TYPE.redX)}
                  alt=""
                />
              </Tooltip>
            ) : (
              <Tooltip content="Filter">
                <img
                  className={styles['filter-image']}
                  src={getSvgByType(appConstants.TYPE.FILTER)}
                  alt=""
                />
              </Tooltip>
            )}
          </button>
        )}
        {hasCategoriesSlide && (
          <SlideMenuApplications
            activeApplication={activeApplication}
            setActiveApplication={setActiveApplication}
            variant="small"
          />
        )}
        {hasExport && (
          <DropdownButton
            items={portalItems}
            type={appConstants.TYPE.THREE_DOTS}
            // disabled={isLoading}
            tooltipContent="More"
          />
        )}
        {(form && permissionKeys?.includes(actions?.hasCreate?.permisionKey)) ||
        actions?.hasCreate ? (
          <TableModal
            form={form}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            modalConfig={modalConfig}
            hasAdd={hasAdd}
          />
        ) : rightContent ? (
          rightContent
        ) : null}
      </div>
    </div>
  );
};

export { TableFilters };
