import React, { useState, useEffect } from 'react';
import styles from './CourseTableSelector.module.scss';
import Container from '../../../../../hoc/container/Container';
import Select from '../../../../../atoms/forms/select/select/Select';
import NewTable from '../../../../../molecules/new-table/NewTable';
import Button from '../../../../../atoms/forms/button/Button';
import _endpoints from '../../../../../../app/constant/endpoints/_endpoints';
import appConstants from '../../../../../../app/constant/constants/appConstants';

const CourseTableSelector = ({
  onRowClick,
  selectedCourse,
  terms,
  onNextStep,
  isLoading,
  termId,
  setTermId,
}) => {
  const [error, setError] = useState('');

  const handleTermChange = (event) => {
    const value = event.target.value;
    setTermId(value);
    if (value) setError('');
  };

  const handleNextStep = () => {
    if (!termId) {
      setError('Please select a term.');
      return;
    }
    onNextStep();
  };

  return (
    <Container>
      <div className={styles.application}>
        <div className={styles.term}>
          <Select
            placeholder={appConstants.PLACEHOLDER.SELECT_TERM}
            label={appConstants.LABEL.TERM}
            type={appConstants.TYPE.DATE}
            name="termId"
            options={terms}
            value={termId}
            onChange={handleTermChange}
            isLoading={isLoading}
            isClear={false}
            error={error}
          />
        </div>
        <NewTable
          title={appConstants.CHOOSE_COURSE}
          api={
            termId ? `${_endpoints.student.getActiveCourses}/${termId}` : null
          }
          extraQuery={null}
          onRowClick={onRowClick}
          actions={{ hasOptionalSelect: true, hasSearch: false }}
          rowKey="courseId"
        />
        {selectedCourse && (
          <Button
            label={appConstants.LABEL.NEXT}
            variant={appConstants.VARIANT.DANGER}
            type={appConstants.TYPE.CHECK}
            onClick={handleNextStep}
          />
        )}
      </div>
    </Container>
  );
};

export default CourseTableSelector;
