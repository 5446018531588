import React from 'react';
import SettingsNavbar from '../../../../components/hoc/settings-nav/SettingsNavbar';
import styles from './Settings.module.scss';
import { Outlet } from 'react-router-dom';

const Settings = () => {
  return (
    <div className={styles['settings-layout']}>
      <SettingsNavbar />
      <Outlet />
    </div>
  );
};

export default Settings;
