import React from 'react';
import styles from './DocumentView.module.scss';
import { getSvgByType } from '../../../app/helpers/forms/GetSvgByType';
import appConstants from '../../../app/constant/constants/appConstants';

const DocumentView = ({ type, fileName, fileType }) => {
  const truncateFileName = (name) => {
    return name.length > 30 ? `${name.slice(0, 10)}...` : name;
  };

  return (
    <div className={styles['document-view']}>
      <img src={getSvgByType(type)} alt="" className={styles['file-img']} />
      <div className={styles.info}>
        <p className={styles.title}>{truncateFileName(fileName)}</p>
        <p className={styles.text}>{fileType}</p>
      </div>
      <img
        className={styles.dots}
        src={getSvgByType('verticalThreeDots')}
        alt=""
      />
    </div>
  );
};

export default DocumentView;
