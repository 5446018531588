export default {
  academicStandingAppeal: {
    title: 'Academic Standing Appeal',
    desc: 'Request to return from dismissal or academic suspension',
    type: 'academicStandingAppeal',
  },
  courseWithdrawal: {
    title: 'Course Withdrawal',
    desc: 'Request to return form dismissal or academic suspension',
    type: 'clock',
  },
  courseIncomplete: {
    title: 'Course Incomplete',
    desc: 'Request for an Incomplete Grade',
    type: 'paper',
  },
  gradeChange: {
    title: 'Grade Change / Removal of I Grade',
    desc: 'Request for change and Removal of Incomplete Grade',
    type: 'grade-paper',
  },
  gradeAppeal: {
    title: 'Grade Appeal',
    desc: 'Request for re-evaluation of an assigned grade',
    type: 'grade-appeal',
  },
  courseOverload: {
    title: 'Course Overload',
    desc: 'Request for approval to register a 3rd course in Summer or 6th course in Fall/Spring',
    type: 'books',
  },
  courseWaitlist: {
    title: 'Course Waitlist',
    desc: 'Request to be considered for registration in a class that is full',
    type: 'hour-glass',
  },
  removalOfIGrade: {
    title: 'Removal of I Grade',
    desc: 'Request for an I Grade Removal',
    type: 'grade-change-i',
  },
};
