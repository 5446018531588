import { useFormik } from 'formik';
import * as Yup from 'yup';
import { usePostMutation } from '../../../../../../../services/apiCall';
import { successMessage } from '../../../../../../hoc/toast/Toast';
import { handleError } from '../../../../../../molecules/table/Table';
import { useMemo, useState } from 'react';
import _endpoints from '../../../../../../../app/constant/endpoints/_endpoints';
const apiUrl = _endpoints.courses.postCourseAdvisorDean;

const getValidationSchema = (type) =>
  Yup.object().shape({
    advisor: Yup.string().required('Advisor is required'),
    dean: Yup.string().required('Dean is required'),
    courseDescription: Yup.string().required('Email is required'),
  });

const useForm = ({ data, type, onSuccess }) => {
  const [key, setKey] = useState(0);
  const [post, { isLoading, isSuccess }] = usePostMutation();

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    let payload = {
      apiUrl,
      data: {
        courseId: data?.id,
        advisorId: values.advisor,
        deanId: values.dean,
      },
    };
    try {
      await post(payload).unwrap();
      resetForm();
      setKey((prev) => prev + 1);
      onSuccess();
      successMessage('Saved Successfully');
      return { isSuccess: true };
    } catch (error) {
      handleError();
    } finally {
      setSubmitting(false);
    }
  };

  const initialValues = useMemo(
    () => ({
      courseDescription: data?.description || '',
      advisor: data?.advisor || '',
      dean: data?.dean || '',
    }),
    [data]
  );

  const formik = useFormik({
    initialValues,
    validationSchema: getValidationSchema(type),
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  return { isLoading, isSuccess, handleSubmit, formik, key };
};

export default useForm;
