import { useFormik } from 'formik';
import { usePostMutation } from '../../../../../../../../services/apiCall';
import {
  errorMessage,
  successMessage,
} from '../../../../../../../hoc/toast/Toast';
import { handleError } from '../../../../../../../molecules/table/Table';
import * as Yup from 'yup';
import _endpoints from '../../../../../../../../app/constant/endpoints/_endpoints';
import { useNavigate } from 'react-router-dom';
import { reRenderHeader } from '../../../../../../../../app/globals/storeSlices/userSlice';
import { useDispatch } from 'react-redux';

const getValidationSchema = () =>
  Yup.object().shape({
    withdrawalReasonId: Yup.string().required('Please select a reason'),
    reason: Yup.string().required('Required'),
  });
const useForm = ({ selectedCourse, termId, onSuccess }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [postWithdraw, { isLoading, isSuccess }] = usePostMutation();

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const payload = {
      apiUrl: _endpoints.application.withdrawCourse,
      data: {
        ...values,
        courseId: selectedCourse?.courseId,
        sectionId: selectedCourse?.section?.id,
        termId: termId,
      },
    };
    try {
      const response = await postWithdraw(payload).unwrap();
      resetForm();
      if (response.message) {
        errorMessage(response.message);
      } else {
        onSuccess();
        dispatch(reRenderHeader());
        setTimeout(() => {
          navigate(-1);
        }, 1000);
      }
    } catch (error) {
      handleError();
    } finally {
      setSubmitting(false);
    }
  };

  const initialValues = {
    termId: '',
    withdrawalReasonId: '',
    reason: '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema: getValidationSchema(),
    onSubmit: handleSubmit,
  });
  console.log('cscs', formik.errors);
  return { formik, isLoading, isSuccess };
};

export default useForm;
