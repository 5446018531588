import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import Select from '../../../../../../../../atoms/forms/select/select/Select';
import Button from '../../../../../../../../atoms/forms/button/Button';
import appConstants from '../../../../../../../../../app/constant/constants/appConstants';
import useForm from './hook';
import { useGetQuery } from '../../../../../../../../../services/apiCall';
import _endpoints from '../../../../../../../../../app/constant/endpoints/_endpoints';

const RestrictionsDetailsForm = ({ handleClose, type, onSuccess, items }) => {
  const [secondApiParams, setSecondApiParams] = useState(null);

  const { data: resData, isLoading: isFirstApiLoading } = useGetQuery({
    api: _endpoints.restrictions.getRestrictionDropDown,
    params: [items.restrictionTypeId],
  });

  const { data: dropdownData, isLoading: isSecondApiLoading } = useGetQuery(
    {
      api: secondApiParams,
    },
    { skip: !secondApiParams }
  );

  useEffect(() => {
    if (resData && resData?.api) {
      setSecondApiParams(resData?.api);
    }
  }, [resData]);

  const appData = dropdownData?.map((val) => ({
    value: val.id,
    label: val[resData['label']],
  }));

  const { formik, isLoading, isSuccess } = useForm({
    data: items,
    type: type,
    onSuccess: onSuccess,
  });

  useEffect(() => {
    formik.resetForm();
  }, []);

  return (
    <form
      className={styles['form']}
      autoComplete="off"
      onSubmit={formik.handleSubmit}
    >
      <Select
        label={items?.restrictionType}
        type={appConstants.TYPE.PROFILE}
        placeholder={`enter ${items?.restrictionType}`}
        name="detailId"
        onChange={formik.handleChange}
        onSelectLabel={(label) => {
          formik.setFieldValue('detailText', label);
        }}
        onBlur={formik.handleBlur}
        value={formik.values.detailId}
        error={formik.touched.detailId && formik.errors.detailId}
        touched={formik.touched.detailId}
        options={appData || []}
        isLoading={isSecondApiLoading}
      />
      <div className="form-footer">
        <Button label="cancel" buttonType="button" onClick={handleClose} />
        <Button
          label="save"
          type={appConstants.TYPE.SAVE}
          variant="danger"
          buttonType="submit"
          isLoading={isLoading}
        />
      </div>
    </form>
  );
};

export default RestrictionsDetailsForm;
