import React, { Children, useEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import Portal from '../portal/Portal';
import styles from './Modal.module.scss';
import Button from '../../atoms/forms/button/Button';
import modalExit from '../../../assets/icons/vectors/exit.svg';
import appConstants from '../../../app/constant/constants/appConstants';
import Title from '../../atoms/title/Title';
import { useNavigate } from 'react-router-dom';
import Tooltip from '../../molecules/tootltip/Tooltip';

const Modal = ({
  isOpen,
  onClose,
  onSubmit,
  title,
  modalContent,
  route,
  closeOnSubmit,
  variant = '',
  onSubmitstyles,
  isLoading,
  buttonType,
  classname,
  children,
  isHtml,
  withoutLine,
}) => {
  const navigate = useNavigate();
  const nodeRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isOpen]);

  const handleClose = () => {
    if (onClose) onClose();
  };

  const handleSubmit = () => {
    if (onSubmit) {
      onSubmit();
    }
    if (route) {
      document.body.style.overflow = 'auto';
      navigate(route);
    }

    closeOnSubmit && handleClose();
  };
  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === 'Escape') {
        handleClose();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleEscape);
    }

    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, [isOpen, handleClose]);
  return (
    <Portal>
      <CSSTransition
        in={isOpen}
        timeout={150}
        classNames={{
          enter: styles['fade-enter'],
          enterActive: styles['fade-enter-active'],
          exit: styles['fade-exit'],
          exitActive: styles['fade-exit-active'],
        }}
        unmountOnExit
        nodeRef={nodeRef}
      >
        <div className={styles['modal-overlay']}>
          <div
            ref={nodeRef}
            className={`${styles['modal-content']} ${styles[variant]} ${
              classname || ''
            }`}
            onClick={(e) => e.stopPropagation()}
          >
            <div className={styles['title-container']}>
              <Title text={title} />
              <Tooltip content="Close">
                <img
                  src={modalExit}
                  onClick={handleClose}
                  className={styles['exit-img']}
                  alt="Exit"
                />
              </Tooltip>
            </div>
            {modalContent?.subtitle && (
              <div className={styles['sub-title']}>
                {modalContent?.subtitle ? (
                  <p>{modalContent?.subtitle}</p>
                ) : null}
              </div>
            )}

            <div className={styles.content}>
              {/* {modalContent?.component} */}
              {isHtml ? (
                <div dangerouslySetInnerHTML={{ __html: children }} />
              ) : (
                children
              )}
            </div>
            {modalContent?.component ? (
              <div className={styles.footer}>
                <Button onClick={handleClose} label={appConstants.CANCEL} />
                <Button
                  onClick={handleSubmit}
                  label={onSubmitstyles?.onSubmit.label || appConstants.I_AGREE}
                  variant={appConstants.VARIANT.DANGER}
                  type={
                    onSubmitstyles?.onSubmit.type || appConstants.TYPE.CHECK
                  }
                  isLoading={isLoading}
                  buttonType={buttonType}
                />
              </div>
            ) : null}
          </div>
        </div>
      </CSSTransition>
    </Portal>
  );
};

export default Modal;
