import React from 'react';
import StudentProfileWrapper from '../../../../../components/hoc/students/student-profile-wrapper/StudentProfileWrapper';
import appConstants from '../../../../../app/constant/constants/appConstants';
import { Tab, Tabs } from '../../../../../components/molecules/tab/Tab';
import Container from '../../../../../components/hoc/container/Container';
import RadioGroup from '../../../../../components/atoms/forms/radio-group/RadioGroup';
import Button from '../../../../../components/atoms/forms/button/Button';
import Reappeal_TEMP from '../../../../../components/templates/students/applications/reappeal/Reappeal_TEMP';

const Reappeal = () => {
  return (
    <StudentProfileWrapper title={appConstants.GRADE_APPEAL} isStudent>
      <Reappeal_TEMP />
    </StudentProfileWrapper>
  );
};

export default Reappeal;
