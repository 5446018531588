import React from 'react';
import styles from './ApplicationeSelector.module.scss';
import { getSvgByType } from '../../../../app/helpers/forms/GetSvgByType';

const ApplicationSelector = ({ title, onClose }) => {
  return (
    <div className={styles.selectorCont}>
      <h1>{title}</h1>
      <img src={getSvgByType('exit')} alt="exit" onClick={onClose} />
    </div>
  );
};

export default ApplicationSelector;
