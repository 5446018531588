import { useFormik } from 'formik';
import { useMemo, useState } from 'react';
import * as Yup from 'yup';
import { usePostMutation } from '../../../../../../services/apiCall';
import { successMessage } from '../../../../../../components/hoc/toast/Toast';
import { handleError } from '../../../../../../components/molecules/new-table/NewTable';
import _endpoints from '../../../../../../app/constant/endpoints/_endpoints';

const apiUrl = _endpoints.approver.postRevertRequest;

const getValidationSchema = () =>
  Yup.object().shape({
    requestId: Yup.string().required('requestId is required'),
    studentId: Yup.string().required('studentId is required'),
    studentName: Yup.string().required('studentName is required'),
    courseName: Yup.string().required('courseName is required'),
    comments: Yup.string().required('Required'),
    changeStatus: Yup.string().required('Required'),
  });

const useForm = ({ data, type, onSuccess, requestData }) => {
  const [key, setKey] = useState(0);
  const [post, { isLoading, isSuccess }] = usePostMutation();
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const url = `${apiUrl}?RequestId=${data.id}&Comments=${values.comments}&ApprovalStep=${values.changeStatus}`;
    let payload = {
      apiUrl: url,
    };
    try {
      await post(payload).unwrap();
      resetForm();
      setKey((prev) => prev + 1);
      onSuccess();
      successMessage('Request is canceled');
      return { isSuccess: true };
    } catch (error) {
      handleError();
    } finally {
      setSubmitting(false);
    }
  };
  const initialValues = useMemo(
    () => ({
      requestId: data?.requestId || '',
      studentId: requestData?.student?.studentId || '',
      studentName: data?.student || '',
      courseName: requestData?.request?.course?.courseCode || '',
      comments: '',
      changeStatus: data?.changeStatus || '',
    }),
    [type, data, requestData]
  );

  const formik = useFormik({
    initialValues,
    validationSchema: getValidationSchema(type),
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  return { isLoading, isSuccess, handleSubmit, formik, key };
};

export default useForm;
