import React from 'react';
import styles from './AcademyStandingApealTemplate.module.scss';
import appConstants from '../../../../../app/constant/constants/appConstants';
import { Tab, Tabs } from '../../../../molecules/tab/Tab';
import Application from './components/application/Application';

const AcademyStandingApealTemplate = () => {
  return <Application />;
};

export default AcademyStandingApealTemplate;
