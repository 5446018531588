import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useMemo, useState } from 'react';
import { usePostMutation } from '../../../../../../../services/apiCall';
import _endpoints from '../../../../../../../app/constant/endpoints/_endpoints';
import { handleError } from '../../../../../../molecules/table/Table';
import { successMessage } from '../../../../../../hoc/toast/Toast';

const apiUrl = _endpoints.users.postUser;

const getValidationSchema = (type) =>
  Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
    // password:
    //   type === 'create'
    //     ? Yup.string()
    //         .min(8, 'Password must be at least 8 characters')
    //         .required('Password is required')
    //     : Yup.string(),
    roleId: Yup.string().required('Role is required'),
  });

const useForm = ({ data, type, onSuccess }) => {
  const [key, setKey] = useState(0);
  const [post, { isLoading, isSuccess }] = usePostMutation();

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    if (type === 'create') delete values?.userId;
    let payload = {
      apiUrl,
      data: values,
    };
    try {
      await post(payload).unwrap();
      resetForm();
      setKey((prev) => prev + 1);
      onSuccess();
     successMessage('Saved Successfully');
      return { isSuccess: true };
    } catch (error) {
      handleError();
    } finally {
      setSubmitting(false);
    }
  };

  const initialValues = useMemo(
    () => ({
      userId: type === 'edit' ? data?.userId : null,
      firstName: data?.firstName || '',
      lastName: data?.lastName || '',
      email: data?.email || '',
      otherId: data?.otherId || '',
      roleId: data?.roleId || '',
    }),
    [type, data]
  );

  const formik = useFormik({
    initialValues,
    validationSchema: getValidationSchema(type),
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  return { isLoading, isSuccess, handleSubmit, formik, key };
};

export default useForm;
