import { routes } from '../../../../../app/constant/routes/Routes';

const adminRoutesConfig = {
  6: {
    route: routes.REGISTRAR.ALL_APPLICATIONS_FORM.GRADE_CHANGE,
  },
  7: {
    route: routes.REGISTRAR.ALL_APPLICATIONS_FORM.GRADE_CHANGE,
  },
};

export default adminRoutesConfig;
