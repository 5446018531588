import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import styles from './Components.module.scss';
import appConstants from '../../../../../app/constant/constants/appConstants';
import TextArea from '../../../../../components/atoms/forms/text-area/TextArea';
import Button from '../../../../../components/atoms/forms/button/Button';
import SimpleTable from '../../../../../components/molecules/simple-table/SimpleTable';
import { usePostMutation } from '../../../../../services/apiCall';
import _endpoints from '../../../../../app/constant/endpoints/_endpoints';
import SuccessModal from '../../../../../components/hoc/success-modal/SuccessModals';
import { errorMessage } from '../../../../../components/hoc/toast/Toast';
import { useNavigate } from 'react-router-dom';
import Container from '../../../../../components/hoc/container/Container';
import objectToFormData from '../../../../../app/helpers/forms/objectToFormData';

const defaultTableHeaderData = [
  'Course Code',
  'Section',
  'Term',
  'Instructor',
  'room',
  'building',
];
const AppealForm = ({ onSubmit, data }) => {
  const navigate = useNavigate();
  const [post] = usePostMutation();
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isWarningModalOpen, setWarningModalOpen] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const [isRejecting, setIsRejecting] = useState(false);
  const [actionType, setActionType] = useState('');

  const request = data?.request;
  const defaultTableData = [
    [
      request?.course?.courseCode,
      request?.section?.sectionName,
      request?.termName,
      request?.section?.instructorName,
      request?.section?.room,
      request?.section?.building,
    ],
  ];
  const formik = useFormik({
    initialValues: {
      comment: '',
    },
    validationSchema: Yup.object({
      comment: Yup.string().required('Required'),
    }),
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  const handleApprove = async () => {
    setIsApproving(true);
    setActionType('approve');

    const payload = {
      RequestId: data?.request.id,
      Comments: formik.values.comment,
    };

    const formData = objectToFormData(payload);

    try {
      await post({
        apiUrl: _endpoints.approver.approveRequest,
        data: formData,
        type: 'formData',
      }).unwrap();
      setIsSuccessModalOpen(true);
      setTimeout(() => {
        navigate(-1);
      }, 1000);
    } catch (error) {
      errorMessage('Something went wrong!');
    } finally {
      setIsApproving(false);
    }
  };

  const handleRejection = async () => {
    setIsRejecting(true);
    setActionType('reject');

    const payload = {
      requestId: data?.request.id,
      comments: formik.values.comment,
    };
    try {
      await post({
        apiUrl: _endpoints.approver.rejectRequest,
        data: payload,
      }).unwrap();
      setIsSuccessModalOpen(true);
      setWarningModalOpen(false);
      setTimeout(() => {
        navigate(-1);
      }, 1000);
    } catch (error) {
      errorMessage('Something went wrong!');
    } finally {
      setIsRejecting(false);
    }
  };

  const closeModal = () => {
    setIsSuccessModalOpen(false);
    setWarningModalOpen(false);
  };

  return (
    <Container>
      <form onSubmit={formik.handleSubmit} className={styles.form}>
        <SimpleTable
          tableHeaderData={defaultTableHeaderData}
          tableData={defaultTableData}
        />
        <TextArea
          label="student comment"
          placeholder={appConstants.PLACEHOLDER.ENTER_COMMENT}
          type={appConstants.TYPE.NOTE}
          name="comment"
          value={request?.otherReasons}
          disabled
        />
        <TextArea
          label={appConstants.LABEL.COMMENT}
          placeholder={appConstants.PLACEHOLDER.ENTER_COMMENT}
          type={appConstants.TYPE.NOTE}
          name="comment"
          value={formik.values.comment}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.comment && formik.errors.comment}
        />
        <div className={styles['button-container']}>
          <Button
            label={appConstants.LABEL.VERIFY}
            type={appConstants.TYPE.CHECK}
            variant="blue"
            onClick={handleApprove}
            isLoading={isApproving}
          />
          <Button
            label={appConstants.LABEL.REJECT_REQUEST}
            type={appConstants.TYPE.THUMB_DOWN}
            variant={appConstants.VARIANT.DANGER}
            onClick={() => setWarningModalOpen(true)}
          />
        </div>
        <SuccessModal
          isOpen={isSuccessModalOpen}
          isSuccess
          text={
            actionType === 'approve'
              ? 'Verified Successfully'
              : 'Rejected Successfully'
          }
          timeout={1000}
        />
        <SuccessModal
          isOpen={isWarningModalOpen}
          text="Reject Request"
          subText="Are you sure you want to reject this request?"
          onClose={closeModal}
          onSubmit={handleRejection}
          onApproveButton={{ text: 'Reject' }}
          isLoading={isRejecting}
        />
      </form>
    </Container>
  );
};

export default AppealForm;
