import profileSvg from '../../../assets/icons/forms/profile.svg';
import emailSvg from '../../../assets/icons/forms/email.svg';
import passwordSvg from '../../../assets/icons/forms/password.svg';
import saveSvg from '../../../assets/icons/forms/save.svg';
import calendarSvg from '../../../assets/icons/forms/calendar.svg';
import editSvg from '../../../assets/icons/forms/edit.svg';
import deleteSvg from '../../../assets/icons/vectors/deleteicon.svg';
import viewSvg from '../../../assets/icons/forms/view.svg';
import noteSvg from '../../../assets/icons/forms/notesPad.svg';
import addSvg from '../../../assets/icons/forms/addIcon.svg';
import docsSvg from '../../../assets/icons/forms/docs.svg';
import phoneSvg from '../../../assets/icons/forms/phone.svg';
import settingSvg from '../../../assets/icons/tab/settingSvg.svg';
import allApplicationsSvg from '../../../assets/icons/tab/allAplications.svg';
import exclamationMarkSvg from '../../../assets/icons/tab/exclamationMark.svg';
import folderSvg from '../../../assets/icons/common/folder.svg';
import searchSvg from '../../../assets/icons/forms/search.svg';
import applicationSvg from '../../../assets/icons/vectors/application.svg';
import fileSvg from '../../../assets/icons/vectors/file.svg';
import attachmentsSvg from '../../../assets/icons/vectors/attachment.svg';
import attachmentsPaperSvg from '../../../assets/icons/vectors/attachmentPaper.svg';
import filterSvg from '../../../assets/icons/vectors/filter.svg';
import recSvg from '../../../assets/icons/vectors/rec.svg';
import threeDotsSquaredSvg from '../../../assets/icons/vectors/squareDots.svg';
import verticatDots from '../../../assets/icons/vectors/more-vet-svgrepo-com 1.svg';
import reverseeIcon from '../../../assets/icons/vectors/reverseIcon.svg';
import userTabSvg from '../../../assets/icons/vectors/userTab.svg';
import roleTabSvg from '../../../assets/icons/vectors/roleTab.svg';
import exitSvg from '../../../assets/icons/vectors/exit.svg';
import printSvg from '../../../assets/icons/vectors/roleTab.svg';
import eNumSvg from '../../../assets/icons/vectors/Enum.svg';
import cancelSvg from '../../../assets/icons/vectors/cancelVector.svg';
import warningSvg from '../../../assets/icons/modal/warningMode.svg';

import exportSvg from '../../../assets/icons/vectors/export.svg';
import sampleLetterSvg from '../../../assets/icons/vectors/sampleLetter.svg';
import checkSvg from '../../../assets/icons/vectors/check.svg';
import appealSvg from '../../../assets/icons/vectors/appeal.svg';
import filterArrowSvg from '../../../assets/icons/vectors/filterArrow.svg';
import downArrowSvg from '../../../assets/icons/vectors/arrow.svg';
import CancelSvg from '../../../assets/icons/vectors/delete.svg';
import rightLeftSvg from '../../../assets/icons/vectors/right-leftArrow.svg';
import departmentSvg from '../../../assets/icons/vectors/department.svg';
import redXSvg from '../../../assets/icons/vectors/redX.svg';
import approvedCheck from '../../../assets/icons/vectors/approvedCheck.svg';
import thinDownArrowSvg from '../../../assets/icons/vectors/thinDownArrow.svg';
import usersSvg from '../../../assets/icons/vectors/sidebar/users.svg';
import approvedFlowSvg from '../../../assets/icons/vectors/sidebar/approvedFlow.svg';
import termsSvg from '../../../assets/icons/vectors/sidebar/terms.svg';
import threeDotsSvg from '../../../assets/icons/vectors/threeDots.svg';
import pdfSvg from '../../../assets/icons/vectors/pdf.svg';
import thumbDownSvg from '../../../assets/icons/vectors/thumbDown.svg';
import programVersionSvg from '../../../assets/icons/vectors/sidebar/programVersions.svg';
import coursesSvg from '../../../assets/icons/vectors/sidebar/courses.svg';
import studentGroupSvg from '../../../assets/icons/vectors/sidebar/studentGroup.svg';
import replaceInstructorSvg from '../../../assets/icons/vectors/sidebar/replaceInstructor.svg';
import timeEmailSvg from '../../../assets/icons/vectors/sidebar/email.svg';
import profileMaleSvg from '../../../assets/icons/vectors/gender/male.svg';
import progileFemaleSvg from '../../../assets/icons/vectors/gender/female.svg';
import roleSvg from '../../../assets/icons/vectors/role.svg';
import reqLogSvg from '../../../assets/icons/vectors/requestLog.svg';
import sendSvg from '../../../assets/icons/vectors/send.svg';
import calendarEmptySvg from '../../../assets/icons/vectors/calendarEmpty.svg';
import userCheckSvg from '../../../assets/icons/vectors/userCheck.svg';
import plusSign from '../../../assets/icons/vectors/plusSign.svg';
import mailEmptySvg from '../../../assets/icons/vectors/mailEmpty.svg';
import blackX from '../../../assets/icons/vectors/blackX.svg';
import signitureSvg from '../../../assets/icons/vectors/signiture.svg';
import scheduledSvg from '../../../assets/icons/vectors/scheduled.svg';
import restrictionsSvg from '../../../assets/icons/vectors/restriction.svg';
import signSvg from '../../../assets/icons/vectors/sign.svg';
import boardSvg from '../../../assets/icons/vectors/board.svg';
import reasonSvg from '../../../assets/icons/vectors/reason.svg';
import backButtonSvg from '../../../assets/icons/vectors/backButton.svg';
import attentionSvg from '../../../assets/icons/vectors/attention.svg';
import backArrowSvg from '../../../assets/icons/vectors/backArrowBtn.svg';
import signOutSVg from '../../../assets/icons/common/sign-out.svg';
import fileUpload from '../../../assets/icons/vectors/fileUpload.svg';
import greyX from '../../../assets/icons/vectors/greyX.svg';
import hashtagIcon from '../../../assets/icons/vectors/hashtag.svg';
import emailTabIcon from '../../../assets/icons/vectors/emailTabIcon.svg';
import bellIcon from '../../../assets/icons/vectors/bell.svg';
// import profileSvg from '../../../assets/icons/common/profile.svg';
import editICon from '../../../assets/icons/vectors/profile/editIcon.svg';
import settingBarSvg from '../../../assets/icons/vectors/sidebar/settings-2.svg';
import userIcon from '../../../assets/icons/vectors/userIcon.svg';
import enterIcon from '../../../assets/icons/vectors/enter.svg';
import minusCheckSign from '../../../assets/icons/vectors/minusCheckSign.svg';
import plusCheckSign from '../../../assets/icons/vectors/plusCheckSign.svg';
import thumbsUp from '../../../assets/icons/vectors/thumbsUp.svg';
import sandClock from '../../../assets/icons/vectors/sandClock.svg';
import sendEmailSvg from '../../../assets/icons/vectors/send-emails.svg';
import mobileIcon from '../../../assets/icons/vectors/mobileInput.svg';
//Mobile
import burgerMenuSvg from '../../../assets/icons/mobile/vectors/burgerMenu.svg';

import appConstants from '../../constant/constants/appConstants';

const svgMap = {
  [appConstants.TYPE.EMAIL]: emailSvg,
  [appConstants.TYPE.PASSWORD]: passwordSvg,
  [appConstants.TYPE.SEND]: sendSvg,
  [appConstants.TYPE.MAIL_EMPTY]: mailEmptySvg,
  [appConstants.TYPE.SIGNITURE]: signitureSvg,
  [appConstants.TYPE.SCHEDULED]: scheduledSvg,
  [appConstants.TYPE.CALENDAR_EMPTY]: calendarEmptySvg,
  [appConstants.TYPE.USER_CHECK]: userCheckSvg,
  [appConstants.TYPE.SIGN]: signSvg,
  [appConstants.TYPE.BOARD]: boardSvg,
  [appConstants.TYPE.REASON]: reasonSvg,
  [appConstants.TYPE.ENUM]: eNumSvg,
  [appConstants.TYPE.SEND_EMAIL]: sendEmailSvg,

  [appConstants.TYPE.DATE]: calendarSvg,
  [appConstants.TYPE.DOCS]: docsSvg,
  [appConstants.TYPE.ALL_APPLICATIONS]: allApplicationsSvg,
  [appConstants.TYPE.FOLDER]: folderSvg,
  [appConstants.TYPE.EXCLAMATION_MARK]: exclamationMarkSvg,
  [appConstants.TYPE.ADMIN_PANEL]: settingSvg,
  [appConstants.TYPE.SEARCH]: searchSvg,
  [appConstants.TYPE.APPLICATION]: applicationSvg,
  [appConstants.TYPE.ATTACHMENTS]: attachmentsSvg,
  [appConstants.TYPE.SAMPLE_LETTER]: sampleLetterSvg,
  [appConstants.TYPE.APPEAL]: appealSvg,
  [appConstants.TYPE.CHECK]: checkSvg,
  [appConstants.TYPE.NOTE]: noteSvg,
  [appConstants.TYPE.FILTER_ARROW]: filterArrowSvg,
  [appConstants.TYPE.DOWN_ARROW]: downArrowSvg,

  // side bar svgs
  [appConstants.TYPE.USERS]: usersSvg,
  [appConstants.TYPE.APPROVER_FLOW]: approvedFlowSvg,
  [appConstants.TYPE.TERMS]: termsSvg,
  [appConstants.TYPE.PROGRAM_VERSIONS]: programVersionSvg,
  [appConstants.TYPE.COURSES]: coursesSvg,
  [appConstants.TYPE.STUDENT_GROUP]: studentGroupSvg,
  [appConstants.TYPE.REPLACE_INSTRUCTOR]: replaceInstructorSvg,
  [appConstants.TYPE.TIME_EMAIL]: timeEmailSvg,
  //
  [appConstants.TYPE.CANCEL]: CancelSvg,
  [appConstants.TYPE.ADD]: addSvg,
  [appConstants.TYPE.SAVE]: saveSvg,
  [appConstants.TYPE.BACK_ARROW]: backArrowSvg,

  [appConstants.TYPE.RIGHT_LEFT_ARROW]: rightLeftSvg,
  [appConstants.TYPE.DEPARTMENT]: departmentSvg,
  [appConstants.TYPE.RESTRICTIONS]: restrictionsSvg,

  [appConstants.TYPE.EDIT]: editSvg,
  [appConstants.TYPE.VIEW]: viewSvg,
  [appConstants.TYPE.DELETE]: deleteSvg,
  [appConstants.TYPE.ATTACHMENTS_PAPER]: attachmentsPaperSvg,
  [appConstants.TYPE.redX]: redXSvg,
  ['approved']: approvedCheck,
  ['notApproved']: redXSvg,
  ['thumbsUp']: thumbsUp,
  ['sandClock']: sandClock,
  ['cancel']: cancelSvg,
  ['warning']: warningSvg,
  ['fileUpload']: fileUpload,
  ['greyX']: greyX,
  ['verticalThreeDots']: verticatDots,
  ['reverse']: reverseeIcon,
  ['hashtag']: hashtagIcon,
  ['emailTab']: emailTabIcon,
  ['bell']: bellIcon,
  ['mobile']: mobileIcon,
  [appConstants.TYPE.THIN_ARROW]: thinDownArrowSvg,
  [appConstants.TYPE.PROFILE_MALE]: profileMaleSvg,
  [appConstants.TYPE.PROFILE_FEMALE]: progileFemaleSvg,
  [appConstants.TYPE.THREE_DOTS]: threeDotsSvg,
  [appConstants.TYPE.THUMB_DOWN]: thumbDownSvg,
  [appConstants.TYPE.PDF]: pdfSvg,
  [appConstants.TYPE.FILTER]: filterSvg,
  [appConstants.TYPE.COL_VISIBILITY]: recSvg,
  [appConstants.TYPE.THREE_DOTS_SQUARED]: threeDotsSquaredSvg,
  [appConstants.TYPE.ROLE_TAB]: roleTabSvg,
  [appConstants.TYPE.ROLE]: roleSvg,
  [appConstants.TYPE.ATTENTION]: attentionSvg,

  [appConstants.TYPE.USER_TAB]: userTabSvg,
  [appConstants.TYPE.EXIT]: exitSvg,
  [appConstants.TYPE.PRINT]: printSvg,
  [appConstants.TYPE.REQUEST_LOG]: reqLogSvg,
  [appConstants.TYPE.BACKBUTTON]: backButtonSvg,
  ['enterIcon']: enterIcon,
  ['minusCheckSign']: minusCheckSign,
  ['plusCheckSign']: plusCheckSign,
  ['blackX']: blackX,
  // MOBILE
  [appConstants.TYPE.BURGER_MENU]: burgerMenuSvg,

  [appConstants.TYPE.PHONE]: phoneSvg,
  [appConstants.TYPE.FILE]: fileSvg,
  [appConstants.TYPE.EXPORT]: exportSvg,
  [appConstants.TYPE.SETTINGS]: settingBarSvg,
  [appConstants.TYPE.PLUS_SIGN]: plusSign,

  [appConstants.TYPE.EDIT_PROFILE]: editICon,
  [appConstants.TYPE.PROFILE]: profileSvg,
  [appConstants.TYPE.SIGNOUT]: signOutSVg,
  ['userIcon']: userIcon,
};

const getSvgByType = (type) => svgMap[type] || profileSvg;

export { getSvgByType };
