import React from 'react';
import { useSelector } from 'react-redux';
import Loader from '../../../../../../components/molecules/table/atoms/loader/Loader';
import InstructorForm from './InstructorForm';
import ChairForm from './ChairForm';
import DeanForm from './DeanForm';
import RegistrarForm from './RegistrarForm';
import ProvostFrom from './ProvostForm';
const GradeAppealWrapper = ({ data }) => {
  const { permissions } = useSelector((state) => state.user);

  if (!permissions) {
    return <Loader />;
  }
  const renderForm = () => {
    // Map of forms based on permission keys
    const formKeysMap = {
      GRADEAPPEAL_INSTRUCTOR_VERIFCIATION: <InstructorForm data={data} />,
      GRADEAPPEAL_PROVOST_VERIFCIATION: <ProvostFrom data={data} />,
      GRADEAPPEAL_CHAIR_VERIFCIATION: <ChairForm data={data} />,
      GRADEAPPEAL_DEAN_VERIFCIATION: <DeanForm data={data} />,
      GRADEAPPEAL_REGISTRAR_VERIFCIATION: <RegistrarForm data={data} />,
    };

    const permissionKeys =
      permissions?.permissions?.map((perm) => perm.actionTextKey) || [];

    const permission = Object.keys(formKeysMap).find((key) =>
      permissionKeys.includes(key)
    );
    if (data.canAct === 1 && permission) {
      return formKeysMap[permission];
    }

    return <p></p>;
  };

  return renderForm();
};

export default GradeAppealWrapper;
