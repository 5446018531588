import React from 'react';
import styles from './CourseDetails.module.scss';
import Container from '../../../../../hoc/container/Container';
import Button from '../../../../../atoms/forms/button/Button';
import appConstants from '../../../../../../app/constant/constants/appConstants';
import _endpoints from '../../../../../../app/constant/endpoints/_endpoints';

const CourseDetails = ({ selectedCourse, onBackClick }) => {
  const courseDetails = [
    { label: 'Course Code', value: selectedCourse?.course?.courseCode || '-' },
    {
      label: 'Course Description',
      value: selectedCourse?.course?.description || '-',
    },
    { label: 'Credit', value: selectedCourse?.course?.credit || '-' },
    { label: 'Capacity', value: selectedCourse?.course?.capacity || '-' },
    {
      label: 'Instructor',
      value: selectedCourse?.section?.instructorName || '-',
    },
    { label: 'Section', value: selectedCourse?.section?.sectionName || '-' },
    { label: 'Room', value: selectedCourse?.section?.room || '-' },
  ];
  const chunkedDetails = [];
  for (let i = 0; i < courseDetails.length; i += 2) {
    chunkedDetails.push(courseDetails.slice(i, i + 2));
  }

  return (
    <div>
      <div className={styles['course-wrapper']}>
        <Container>
          <div className={styles['course-detail-container']}>
            {chunkedDetails.map((chunk, index) => (
              <div key={index} className={styles['course-detail']}>
                {chunk.map((detail, idx) => (
                  <div key={idx}>
                    <p className={styles.title}>{detail.label}</p>
                    <p className={styles['sub-title']}>{detail.value}</p>
                  </div>
                ))}
              </div>
            ))}
            <Button
              label="Change Course"
              type={appConstants.TYPE.BACK_ARROW}
              className={styles['button']}
              onClick={onBackClick}
            />
          </div>
        </Container>
      </div>
    </div>
  );
};

export default CourseDetails;
