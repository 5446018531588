import React from 'react';
import styles from './Label.module.scss';

const Label = ({ text, isCursor, style, htmlFor, classname }) => {
  return (
    <label
      htmlFor={htmlFor}
      className={`${styles.label} ${isCursor ? styles.hover : ''} ${
        classname ?? ''
      }`}
      style={style}
    >
      {text}
    </label>
  );
};

export default Label;
