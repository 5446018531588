import React from 'react';
import styles from './CancelrequestForm.module.scss';
import { getSvgByType } from '../../../../../../app/helpers/forms/GetSvgByType';
import Title from '../../../../../../components/atoms/title/Title';
import useForm from './hook';
import Input from '../../../../../../components/atoms/forms/input/input/Input';
import Button from '../../../../../../components/atoms/forms/button/Button';
import appConstants from '../../../../../../app/constant/constants/appConstants';
import TextArea from '../../../../../../components/atoms/forms/text-area/TextArea';
import _endpoints from '../../../../../../app/constant/endpoints/_endpoints';
import { useGetQuery } from '../../../../../../services/apiCall';
const requestApi = _endpoints.student.getRequestById;

const CancelRequestForm = ({ data, type, handleClose, onSuccess }) => {
  const { data: requestData } = useGetQuery(
    {
      api: requestApi,
      params: [data?.id],
    },
    { skip: !data?.id }
  );

  const { formik, isLoading } = useForm({
    requestData: requestData,
    data: data,
    type: type,
    onSuccess: onSuccess,
  });

  return (
    <div className={styles['cont']}>
      <div className={styles['warning-img-cont']}>
        <img src={getSvgByType('warning')} alt="Warning" />
        <Title text="Cancel Request" variant={'md'} />
      </div>
      <form
        className={styles['form']}
        autoComplete="off"
        onSubmit={formik.handleSubmit}
      >
        <div className={styles['inner-section']}>
          <Input
            label="Request Id"
            type='hashtag'
            placeholder="enter request id"
            name="requestId"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.requestId}
            error={formik.touched.requestId && formik.errors.requestId}
            touched={formik.touched.requestId}
            autoComplete="off"
            disabled
          />
          <Input
            label="Student Id"
            placeholder={appConstants.PLACEHOLDER.FIRST_NAME}
           type='hashtag'
            name="studentId"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.studentId}
            error={formik.touched.studentId && formik.errors.studentId}
            touched={formik.touched.studentId}
            // actionImg={actionImg}
            disabled
          />
        </div>
        <div className={styles['inner-section']}>
          <Input
            label="Student Name"
            type={appConstants.TYPE.PROFILE}
            placeholder="enter student name"
            name="studentName"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.studentName}
            error={formik.touched.studentName && formik.errors.studentName}
            touched={formik.touched.studentName}
            autoComplete="off"
            disabled
          />
          <Input
            label="Course Name"
            placeholder="enter course Name"
            type={appConstants.TYPE.PROFILE}
            name="courseName"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.courseName}
            error={formik.touched.courseName && formik.errors.courseName}
            touched={formik.touched.courseName}
            // actionImg={actionImg}
            disabled
          />
        </div>
        <TextArea
          label={appConstants.LABEL.COMMENT}
          placeholder={appConstants.PLACEHOLDER.ENTER_COMMENT}
          type={appConstants.TYPE.NOTE}
          name="comments"
          value={formik.values.comments}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.comments}
          touched={formik.touched.comments}
        />
        <div className="form-footer">
          <Button onClick={handleClose} label="No, Don’t Cancel It  " />
          <Button
            label="Yes, Cancel this request"
            variant={appConstants.VARIANT.DANGER}
            isLoading={isLoading}
            buttonType="submit"
          />
        </div>
      </form>
    </div>
  );
};

export default CancelRequestForm;
