import React, { useState } from 'react';
import _endpoints from '../../../../../../app/constant/endpoints/_endpoints';
import SlideMenuRoles from '../../../../../hoc/slide-menu-rtl/roles-settings/SlideMenuRoles';
import NewTable from '../../../../../molecules/new-table/NewTable';
import Modal from '../../../../../hoc/modal/Modal';
import RoleForm from '../factory/assign-role-form/RoleForm';
import useFetchPermissions from '../../../../../../app/helpers/permissions/getPermissionsCall';

const RolesTab = () => {
  useFetchPermissions();
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAssignFormOpen, setIsAssignFormOpen] = useState(false);
  const [rowData, setRowData] = useState(false);

  const [refetch, setRefetch] = useState();
  const [formType, setFormType] = useState('create');
  const [id, setId] = useState(null);

  const onSuccess = () => {
    setRefetch(!refetch);
    setId(null);
    setIsAssignFormOpen(false);
  };

  const handleFormType = (item, type) => {
    setIsOpen(true);
    setId(item.roleId);
    setFormType(type);
  };
  const handleAssignRoleForm = (item, type) => {
    setIsAssignFormOpen(true);
    setRowData(item);
    setFormType(type);
  };
  const handleSideModal = () => {
    setIsOpen(!isOpen);
    setId(null);
  };
  return (
    <>
      <NewTable
        actions={{
          hasEdit: {
            func: (item) => handleFormType(item, 'edit'),
          },
          hasRole: {
            func: (item) => handleAssignRoleForm(item, 'edit'),
          },
          hasDelete: {},
        }}
        rightContent={
          <SlideMenuRoles
            onSuccess={onSuccess}
            type={formType}
            id={id}
            isOpen={isOpen}
            setIsOpen={handleSideModal}
          />
        }
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
        refetch={refetch}
        deleteApi={_endpoints.roles.deleteRole}
        deleteMethod="del"
        api={_endpoints.roles.getRoles}
        rowKey="roleId"
      />

      <Modal
        title="assign role"
        onClose={() => setIsAssignFormOpen(false)}
        isOpen={isAssignFormOpen}
      >
        <RoleForm
          data={rowData}
          onSuccess={onSuccess}
          handleClose={() => setIsAssignFormOpen(false)}
        />
      </Modal>
    </>
  );
};

export default RolesTab;
