import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import _endpoints from '../../constant/endpoints/_endpoints';
import { setPermissions } from '../../globals/storeSlices/userSlice';
import { useGetQuery } from '../../../services/apiCall';

const permissionsApi = _endpoints.auth.getPermissions;

const useFetchPermissions = () => {
  const location = useLocation();
  const user = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const { data: permissionData, refetch: refetchPermissions } = useGetQuery(
    {
      api: permissionsApi,
    },
    {
      skip: user.userType === 'STUDENT' || !user.userType,
    }
  );

  useEffect(() => {
    if (user.userType === 'STAFF') refetchPermissions();
  }, [location]);

  useEffect(() => {
    if (permissionData) {
      dispatch(setPermissions(permissionData));
    }
  }, [permissionData, dispatch]);
};

export default useFetchPermissions;
