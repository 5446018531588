import React from 'react';
import styles from './ProfileHeader.module.scss';
import { getSvgByType } from '../../../../../../app/helpers/forms/GetSvgByType';
import _endpoints from '../../../../../../app/constant/endpoints/_endpoints';
import appConstants from '../../../../../../app/constant/constants/appConstants';
import Container from '../../../../container/Container';
import Tag from '../../../../../atoms/tag/Tag';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import Tooltip from '../../../../../molecules/tootltip/Tooltip';
import { formatDateTime } from '../../../../../../app/helpers/forms/formatDate';

const ProfileHeader = ({ data, fallbackState }) => {
  const navigate = useNavigate();
  const fallback = () => {
    if (fallbackState) {
      navigate(fallbackState?.to, {
        state: {
          activeApplication: fallbackState?.state,
          query: fallbackState.query,
        },
      });
    } else {
      navigate(-1);
    }
  };
  return (
    <Container>
      <div className={styles['profile']}>
        <div className={styles['profile-header']}>
          <div className={styles['header-info']}>
            <Tooltip content={'Back'}>
              <img
                src={getSvgByType(appConstants.TYPE.BACKBUTTON)}
                alt=""
                onClick={fallback}
              />
            </Tooltip>

            <p>{data?.applicationType}</p>
          </div>

          <div className={styles['text-wrapper']}>
            <p>
              Request ID: <span>{data?.requestId}</span>
            </p>
            <p>
              Date Created: <span>{formatDateTime(data?.dateCreated)}</span>
            </p>
            <p>
              Last Updated:<span>N/A</span>{' '}
            </p>
          </div>
        </div>
        <Tag label={data?.statusName} variant={data?.statusName} />
      </div>
    </Container>
  );
};

export default ProfileHeader;
