import React from 'react';
import { Quill } from 'react-quill';
import { useGetQuery } from '../../../../../services/apiCall';
import '../styles.css';
import _endpoints from '../../../../../app/constant/endpoints/_endpoints';

const Size = Quill.import('formats/size');
Size.whitelist = ['extra-small', 'small', 'medium', 'large'];
Quill.register(Size, true);

const Font = Quill.import('formats/font');
Font.whitelist = [
  'arial',
  'comic-sans',
  'courier-new',
  'georgia',
  'helvetica',
  'lucida',
];
Quill.register(Font, true);

function insertCustomTags(value) {
  const cursorPosition = this.quill.getSelection().index;
  this.quill.insertText(cursorPosition, value);
  this.quill.setSelection(cursorPosition + value.length);
}

export const modules = {
  toolbar: {
    container: '#toolbar',
    handlers: {
      insertCustomTags,
    },
  },
};

export const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'color',
  'background',
  'align',
];
const api = _endpoints.notification.getNotificationOptions;

export const ToolBar = ({ isCustom, setEditorContent, editorContent }) => {
  const { data } = useGetQuery({ api });

  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    if (selectedValue !== 'Add Variable') {
      const newContent = editorContent + selectedValue;
      setEditorContent(newContent);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        borderBottom: '1px solid #EEE ',
      }}
    >
      <div id="toolbar">
        <span className="ql-formats">
          <select className="ql-font" defaultValue="arial">
            <option value="arial">Arial</option>
            <option value="comic-sans">Comic Sans</option>
            <option value="courier-new">Courier New</option>
            <option value="georgia">Georgia</option>
            <option value="helvetica">Helvetica</option>
            <option value="lucida">Lucida</option>
          </select>
          <select className="ql-size" defaultValue="medium">
            <option value="extra-small">Small</option>
            <option value="small">Normal</option>
            <option value="medium">Medium</option>
            <option value="large">Large</option>
            <option value="huge">Huge</option>
          </select>
        </span>

        <span className="ql-formats">
          <button className="ql-bold" />
          <button className="ql-italic" />
          <button className="ql-underline" />
          <button className="ql-strike" />
        </span>
        <span className="ql-formats">
          <button className="ql-link" />
          <button className="ql-image" />
        </span>
        <span className="ql-formats">
          <button className="ql-list" value="ordered" />
          <button className="ql-list" value="bullet" />
          <button className="ql-indent" value="-1" />
          <button className="ql-indent" value="+1" />
        </span>
        <span className="ql-formats">
          <button className="ql-script" value="super" />
          <button className="ql-script" value="sub" />
        </span>
        <span className="ql-formats">
          <select className="ql-align" />
        </span>
      </div>
      {isCustom ? (
        <select
          className="ql-insertCustomTags"
          defaultValue="Add Variable"
          onChange={handleSelectChange}
          style={{ border: 'none', fontWeight: '500' }}
        >
          <option value="Add Variable">Add Variable</option>
          {data?.map((item) => (
            <option key={item.value} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
      ) : null}
    </div>
  );
};
