import React from 'react';
import styles from './Profile_TEMP.module.scss';
import UserProfileHeader from './components/profileHeader/UserProfileHeader';
import UserProfileDetails from './components/profileDetails/UserProfileDetails';
import { useSelector } from 'react-redux';

const Profile_TEMP = () => {
  const user = useSelector((state) => state.user);

  return (
    <div className={styles['profile_cont']}>
      <UserProfileHeader data={user} />
      <UserProfileDetails />
    </div>
  );
};

export default Profile_TEMP;
