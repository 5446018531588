import React, { useState, useRef } from 'react';
import styles from './Accordion.module.scss';
import appConstants from '../../../app/constant/constants/appConstants';
import { getSvgByType } from '../../../app/helpers/forms/GetSvgByType';
import Title from '../../atoms/title/Title';
import Tooltip from '../tootltip/Tooltip';

const Accordion = ({
  title,
  type,
  isExit,
  children,
  enableImage = true,
  className,
  date,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef(null);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`${styles.accordion} ${className || ''}`}>
      <Tooltip content="View More">
        <div
          onClick={toggleAccordion}
          className={`${styles.header} ${className || ''}`}
        >
          <div className={styles.title}>
            {!isExit ? (
              <img src={getSvgByType(type ?? appConstants.TYPE.redX)} alt="" />
            ) : null}
            {/* <p>{title}</p> */}
            <Title variant="sm-blue-bold" text={title} />
          </div>
          <div className={styles.datArrow}>
            {date && <Title variant="sm-blue" text={date} />}
            <span className={`${styles.icon} ${isOpen ? styles.openIcon : ''}`}>
              <img src={getSvgByType(appConstants.TYPE.THIN_ARROW)} alt="" />
            </span>
          </div>
        </div>
      </Tooltip>

      <div
        ref={contentRef}
        className={`${styles.content} ${isOpen ? styles.open : styles.closed}`}
        style={{
          maxHeight: isOpen ? `${contentRef.current.scrollHeight}px` : '0px',
        }}
      >
        <div className={`${styles.contentInner} ${className || ''}`}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Accordion;
