import { routes } from '../../../../../app/constant/routes/Routes';

const routesConfig = {
  1: {
    route: routes.STUDENTS.WAITING_LIST,
  },
  2: {
    route: routes.STUDENTS.COURSE_INCOMPLETE,
  },
  3: {
    route: routes.STUDENTS.COURSE_WITHDRAWAL,
  },
  4: {
    route: routes.STUDENTS.COURSE_OVERLOAD,
  },
  5: {
    route: routes.STUDENTS.GRADE_APPEAL,
  },
};

export default routesConfig;
