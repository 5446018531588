import React from 'react';
import styles from '../NewSetupTermForm.module.scss';
import DateSelect from '../../../../../../../atoms/forms/select/date/DateSelect';
import Select from '../../../../../../../atoms/forms/select/select/Select';
import * as Yup from 'yup';

export const form3ValidationSchema = Yup.object().shape({
  // termName: Yup.string().required('Term name is required'),
  graduate_Withdrawal_StartDate: Yup.date().required('Start date is required'),
  graduate_Withdrawal_EndDate: Yup.date().required('End date is required'),
  undergraduate_Withdrawal_StartDate: Yup.date().required(
    'Undergraduate withdrawal start date is required'
  ),
  undergraduate_Withdrawal_EndDate: Yup.date().required(
    'Undergraduate withdrawal end date is required'
  ),
});

export const form3InitialValues = {
  termName: '',
  graduate_Withdrawal_StartDate: '',
  graduate_Withdrawal_EndDate: '',
  undergraduate_Withdrawal_StartDate: '',
  undergraduate_Withdrawal_EndDate: '',
};
export const renderFieldsForId3Or4 = (formik) => {
  const handleDateChange = (name, value) => {
    formik.setFieldValue(name, value);
  };
  return (
    <div className={styles['form-grid']}>
      {/* <Select
        label="Course type"
        type="text"
        placeholder="Select course type"
        name="termName"
        onChange={handleDateChange}
        onBlur={formik.handleBlur}
        value={formik.values.termName}
        error={formik.touched.termName && formik.errors.termName}
        touched={formik.touched.termName}
      /> */}
      <DateSelect
        label="Undergraduate withdrawal start date"
        type="date"
        placeholder="Select date"
        name="undergraduate_Withdrawal_StartDate"
        onChange={handleDateChange}
        onBlur={formik.handleBlur}
        value={formik.values.undergraduate_Withdrawal_StartDate}
        error={
          formik.touched.undergraduate_Withdrawal_StartDate &&
          formik.errors.undergraduate_Withdrawal_StartDate
        }
        touched={formik.touched.undergraduate_Withdrawal_StartDate}
        classname={styles.date}
      />
      <DateSelect
        label="Undergraduate withdrawal end date"
        type="date"
        placeholder="Select date"
        name="undergraduate_Withdrawal_EndDate"
        onChange={handleDateChange}
        onBlur={formik.handleBlur}
        value={formik.values.undergraduate_Withdrawal_EndDate}
        error={
          formik.touched.undergraduate_Withdrawal_EndDate &&
          formik.errors.undergraduate_Withdrawal_EndDate
        }
        touched={formik.touched.undergraduate_Withdrawal_EndDate}
        classname={styles.date}
      />
      <DateSelect
        label="graduate Start date"
        type="date"
        placeholder="Select date"
        name="graduate_Withdrawal_StartDate"
        onChange={handleDateChange}
        onBlur={formik.handleBlur}
        value={formik.values.graduate_Withdrawal_StartDate}
        error={
          formik.touched.graduate_Withdrawal_StartDate &&
          formik.errors.graduate_Withdrawal_StartDate
        }
        touched={formik.touched.graduate_Withdrawal_StartDate}
        classname={styles.date}
      />
      <DateSelect
        label="graduate End date"
        type="date"
        placeholder="Select date"
        name="graduate_Withdrawal_EndDate"
        onChange={handleDateChange}
        onBlur={formik.handleBlur}
        value={formik.values.graduate_Withdrawal_EndDate}
        error={
          formik.touched.graduate_Withdrawal_EndDate &&
          formik.errors.graduate_Withdrawal_EndDate
        }
        touched={formik.touched.graduate_Withdrawal_EndDate}
        classname={styles.date}
      />
    </div>
  );
};
